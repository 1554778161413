import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as PersonIcon } from '../../../../assets/icons/person.svg';


import { BoxHeader, BoxLayout, ConfirmedAmount, IconContainer } from '../EventRoomStyles';
import { cleanGuests, loadAttending } from '../../../../store/reducers/guests/guests-reducer';
import { FlexRow } from '../../../../common/components/CommonStyledComponent';

const GuestBox = ({ eventId }) => {
    const dispatch = useDispatch();

    const { total, attending } = useSelector((state) => state.guests);

    useEffect(() => {
        dispatch(loadAttending(eventId));

        return () => {
            dispatch(cleanGuests());
        }
    }, []);

    return (
        <BoxLayout>
            <BoxHeader>GUESTS</BoxHeader>
            <FlexRow style={{ margin: 'auto' }}>
                <IconContainer style={{ marginRight: '20px' }} >
                    <PersonIcon />
                </IconContainer>
                <IconContainer>
                    <PersonIcon />
                </IconContainer>
            </FlexRow>
            <ConfirmedAmount>
                {attending} OF {total} CONFIRMED
            </ConfirmedAmount>
        </BoxLayout>
    )
}

export default GuestBox;