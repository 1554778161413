import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import "../../../styles/datepicker.css";
import { useHistory } from 'react-router-dom';
import { useCheckStep } from '../../../hooks/useCheckStep';
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';

import weddingImage from '../.././../assets/images/wedding.png';
import backIcon from '../../../assets/icons/back.svg';
import nextIconOrange from '../../../assets/icons/next-orange.svg';
import { ReactComponent as FireworksIcon } from '../../../assets/icons/fireworks.svg';

import { EventsGrid, ImageContainer, EventsContainer, PageHeader, PageText, ImageTextContainer, ImageText } from '../EventKind/EventKindStyles';
import { BackButton, ProgressBar, ProgressFill, SimpleText, FlexRowSpaced, FlexColumn } from '../../../common/components/CommonStyledComponent';
import { getFollowing12Months } from '../../../common/utils/utils';
import { MonthsGrid, EventDateText } from './EventDateStyles';
import { NextButtonOrange, NextContainer, NextText } from '../Where/WhereStyles';
import { completeStep } from '../../../store/reducers/steps/steps-reducer';
import Routes from '../../routes/index';

const followingMonths = getFollowing12Months();

const EventDate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const eventDate = useSelector(state => state.steps.date);
    const onNextPage = useCheckStep(eventDate);

    const [showDates, setShowDates] = useState(false);

    const isWindowForMobile = window.innerWidth <= 768;


    const toggleShowDates = () => {
        setShowDates((prevState) => {
            return !prevState;
        })
    }


    const [date, setDate] = useState({
        date: new Date(),
        dateOnlyMonth: false,
    });
    const [chosenMonth, setChosenMonth] = useState(null);

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, [])

    const onMonthClick = (monthAndDate) => {
        setChosenMonth(monthAndDate.month);
        setDate({ date: monthAndDate.date, dateOnlyMonth: true })
    }

    const onSingleDateClick = (date) => {
        setDate({ date: date, dateOnlyMonth: false });
        setChosenMonth(null);
    }

    const nextStep = () => {
        dispatch(completeStep({ stepName: 'date', data: date }))
        onNextPage();
    }

    return (<EventsGrid>
        {
            !isWindowForMobile ?
                <ImageContainer bg={weddingImage} >
                    <ImageTextContainer>
                        <FireworksIcon fill={'white'} width={123} height={123} style={{ marginRight: '10px' }} />
                        <FlexColumn>
                            <ImageText style={{ fontSize: '24px' }}>
                                RONI KARMI
                            </ImageText>
                            <ImageText>
                                ARE WE GOING FOR A WINTER WONDERLAND OR A SUMMER PARADISE?
                            </ImageText>
                        </FlexColumn>
                    </ImageTextContainer>
                </ImageContainer>
                : null
        }
        <EventsContainer>
            <FlexRowSpaced>
                <BackButton src={backIcon} alt="Back" onClick={() => { history.replace(Routes.Boarding.where); }} />
                <ProgressBar>
                    <ProgressFill fill={'60%'}></ProgressFill>
                </ProgressBar>
                <NextContainer onClick={() => { nextStep() }} >
                    <NextText>Next</NextText>
                    <NextButtonOrange src={nextIconOrange} alt="Next" />
                </NextContainer>
            </FlexRowSpaced>
            <PageHeader>When are we celebrating?</PageHeader>
            <PageText>Whether you are set on a date, or flexible - let us know and we’ll make proposals accordingly</PageText>
            <PageText style={{ paddingBottom: '30px',textDecoration:'underline' }}>Select a date:</PageText>
            <DatePicker
                className={chosenMonth !== null ? 'changeColor' : ''}
                formatWeekDay={nameOfDay => nameOfDay.substr(0, 3).toUpperCase()}
                selected={date.date}
                color={'red'}
                onChange={(date) => onSingleDateClick(date)}
                minDate={moment().toDate()}
                dateFormat="dd / MM / yyyy"
                popperPlacement="bottom"
                onFocus={e => e.target.blur()}
            />
            <SimpleText style={{textDecoration:'underline',cursor:'pointer'}}  onClick={() => { toggleShowDates() }}>Or select a month</SimpleText>
            {showDates&&<MonthsGrid>
                {
                    followingMonths.map((monthAndDate) => {
                        return <EventDateText
                            key={monthAndDate.month}
                            selected={monthAndDate.month === chosenMonth}
                            onClick={() => onMonthClick(monthAndDate)} >
                            {monthAndDate.month}
                        </EventDateText>
                    })
                }
            </MonthsGrid>}
        </EventsContainer>
    </EventsGrid>);
}

export default EventDate;