import React, { useState } from 'react';
import { CenterText, ImageContainer, LocationContainer, CityListGrid, CityItem, CloseIcon } from './LocationStyles';
import closeIcon from '../../../../assets/icons/close.svg';
import { capitalize } from '../../../../common/utils/utils';
import env from '../../../../config';

const Location = ({ name, image, cityList, onCitySelect, selectedCities, isCardOpen }) => {



    const onCityClick = (cityId) => {
        onCitySelect(cityId)
    }

    const isClicked = (cityId) => {
        return selectedCities.indexOf(cityId) > -1;
    }

    return (
        <LocationContainer>
            <ImageContainer bg={env.apiGateway.BUCKET_URL + image}>
                <CenterText>{capitalize(name)}</CenterText>
            </ImageContainer>
            
             
                <CityListGrid>
                    {
                        cityList.length === 0 ?
                            <CityItem>
                                <div>No Cities Available</div>
                            </CityItem>
                            :
                            cityList.map((city) => {
                                let isCityClicked = isClicked(city._id);
                                return (
                                    <CityItem onClick={() => onCityClick(city._id)} key={city._id} clicked={isCityClicked}>
                                        <div>{city.name}</div>
                                        <CloseIcon clicked={isCityClicked} src={closeIcon} alt="close" />
                                    </CityItem>)
                            })
                    }
                </CityListGrid> 
              
        </LocationContainer>
    )
}

export default Location;
