import { createSlice } from '@reduxjs/toolkit';
import teamsApi from '../../api/teams-api';

const initialState = {
    team: [],
    total: 0,
    error: null,
    loading: false,
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _loadTeamSuccess = (state, action) => {
    const { data, metadata } = action.payload;
    state.loading = false;
    state.team = data;
    state.total = metadata.total;
};

const _deleteMemberSuccess = (state, action) => {
    state.loading = false;
};

const _addMemberSuccess = (state, action) => {
    state.loading = false;
};

const _cleanTeam = (state) => {
    return initialState;
};

const team = createSlice({
    name: 'team',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        loadTeamSuccess: _loadTeamSuccess,
        deleteMemberSuccess: _deleteMemberSuccess,
        addMemberSuccess: _addMemberSuccess,
        cleanTeam: _cleanTeam,
    },
});

const { actions, reducer } = team;

export const { loadStart, loadFailed, loadTeamSuccess, deleteMemberSuccess, addMemberSuccess, cleanTeam } = actions;

export default reducer;

export const loadTeam = (userEventId) => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await teamsApi.fetchTeam(userEventId);
        if (status === 200) {
            return dispatch(loadTeamSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};

export const deleteMember = (userEventId, proId) => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await teamsApi.deleteMember(userEventId, proId);
        if (status === 200) {
            return dispatch(deleteMemberSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};

export const addMember = (userEventId, proId) => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await teamsApi.addMember(userEventId, proId);
        if (status === 200) {
            return dispatch(addMemberSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};
