import styled from 'styled-components';

export const GuestsBox = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid rgba(0,0,0,0.1);
    font-family: 'Poppins';
    border-radius: 50px;
    margin-bottom: 80px;
    width: 90%;
    align-self: center;
    color: var(--black);
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;

    @media (max-width: 768px) {
        width: 80%;
        margin-bottom: 50%;
        padding:10px;
        overflow:'hidden';
    }  
`;

export const GuestsNumber = styled.div`
    font-size: 22px;
    margin: 0px; 
    cursor: pointer;
    margin: 10px auto;
    padding:  8px 14px;
    border-radius: 50px;
    font-weight: 500;
    color: ${props => props.selected ? 'var(--red)' : 'var(--black)'};
    border: ${props => props.selected ? '1px solid var(--light-red)' : '1px solid rgba(0,0,0,0)'};
    background: ${props => props.selected ? 'var(--pink)' : 'none'};
    width: fit-content;
    justify-self: center;

    &:hover{
        padding:  8px 14px;
        border-radius: 50px;
        background: var(--pink);
        box-shadow: 0px 2px 0px rgba(148, 148, 148, 0.25);
    }

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

export const RangeInput = styled.input`
    width: 50% !important;
    margin: 10px auto !important;
`;