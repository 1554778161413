import React from 'react';

import eventroLogo from '../../../assets/icons/eventro.svg'

import { PopupButton } from '../../../common/components/CommonStyledComponent';
import { EventroLogo, NewUserBoldText, NewUserContainer, NewUserText } from '../DashboardStyles';
import { UlContainer } from './SettingsStyles';

const EventroFamilyPopup = ({ onCloseClick }) => {
    const isWindowForMobile = window.innerWidth <= 768;

    return (
        <NewUserContainer style={{ padding: isWindowForMobile ? '10px' : ' 0px 20px' }}>
            <EventroLogo src={eventroLogo} alt="/" />
            <NewUserText>
                We are honoured to introduce our team of professionals that have been working with us to make celebraions happen.
            </NewUserText>
            <NewUserBoldText style={{ margin: '6px 0px' }}>
                Thank you fam!
            </NewUserBoldText>
            <UlContainer mobile={isWindowForMobile}>
                <ul style={{ listStyleType: 'none', margin: '0px auto', paddingInline: '0px' }}>
                    <li>647 Bar Experience</li>
                    <li>Al Hayam Wedding Venue</li>
                    <li>Alpha Music</li>
                    <li>Armadilo Audio Visual Solutions</li>
                    <li>Bait Al Hayam Wedding Venue</li>
                    <li>Bulldog AV Services</li>
                    <li>DNA Design</li>
                    <li>Elad Sasi Photography</li>
                    <li>Erez & Hanan Catering</li>
                    <li>Etz Hasadeh Catering</li>
                    <li>Food Club Catering</li>
                    <li>Erlboim Catering</li>
                    <li>Hagiva Wedding Venue</li>
                    <li>Hagiva Local Bar</li>
                    <li>High& Wedding Venue</li>
                    <li>KO Bar</li>
                    <li>Luminar Bar</li>
                    <li>Maasia Catering</li>
                    <li>Mazal Taleh Catering</li>
                    <li>Noam Zellner Music</li>
                    <li>Perry Easy Photography</li>
                    <li>Ran Bergman - Creative Photography</li>
                    <li>Ronit Farm Wedding Venue</li>
                    <li>Toda She Bar Services</li>
                    <li>Yado&Aylona Design</li>
                    <li>Yahav Green Design</li>
                    <li>ZagoniGoldz Design</li>
                    <li>Zemel Design</li>
                </ul>
            </UlContainer>
            <PopupButton onClick={onCloseClick}>Close</PopupButton>
        </NewUserContainer >
    )

}

export default EventroFamilyPopup;