import React, { useState, useCallback, useEffect } from 'react';

import { FlexColumn } from '../../../../common/components/CommonStyledComponent';
import { ChatHeader } from '../MessagesStyles';
import ConversationBubble from './ConversationBubble';

const ChatConversations = ({ chats, participants, onSelectChat, onSelectParticipants, clearSelectedTrigger }) => {
    const [selectedChat, setSelectedChat] = useState(null);

    const onSelect = useCallback((chatSid, partici) => {
        setSelectedChat(chatSid);
        onSelectChat(chatSid);
        onSelectParticipants(partici);
    }, []);

    useEffect(() => {
        if (clearSelectedTrigger) {
            setSelectedChat(null);
        }
    }, [clearSelectedTrigger])


    return (
        <FlexColumn style={{ gridArea: 'messages', overflow: 'auto' }}>
            <ChatHeader>Messages</ChatHeader>
            {
                chats ?
                    Object.entries(chats).map(([sid, chat], index) => {
                        let matchingParticipats = participants.filter((parti) => {
                            return parti._id === sid;
                        })[0];
                        return <ConversationBubble
                            key={index}
                            chat={chat}
                            participants={matchingParticipats}
                            selected={selectedChat === sid}
                            click={onSelect}
                            unreadMessages={selectedChat === sid ? 0 : chat.unreadMessagesCount} />
                    })
                    : null
            }
        </FlexColumn>
    )
}

export default React.memo(ChatConversations);