import authReducer from './auth/auth-reducer';
import stepsReducer from './steps/steps-reducer';
import locationsReducer from './locations/locations-reducer';
import eventTypesReducer from './event-types/event-types-reducer';
import eventsReducer from './events/events-reducer';
import userEventReducer from './user-event/user-event-reducer';
import guestsReducer from './guests/guests-reducer';
import budgetReducer from './budget/budget-reducer';
import teamReducer from './teams/team-reducer';
import proReducer from './teams/pro-reducer';
import prosReducer from './teams/pros-reducer';
import conversationsReducer from './conversations/conversations-reducer';
import chatReducer from './chat/chat-reducer';
import shareEventReducer from './share-event/share-event-reducer';

export const reducers = {
    auth: authReducer,
    steps: stepsReducer,
    locations: locationsReducer,
    eventTypes: eventTypesReducer,
    events: eventsReducer,
    userEvent: userEventReducer,
    guests: guestsReducer,
    budget: budgetReducer,
    team: teamReducer,
    pro: proReducer,
    pros: prosReducer,
    conversations: conversationsReducer,
    chat: chatReducer,
    shareEvent: shareEventReducer
};
