import React from 'react';
import { Card, CardLogo, CardText } from './CardStyles';

const HomePageCard = ({ bgColor, image, text }) => {

    return (
        <Card bg={bgColor}>
            <CardLogo src={image} alt="/" />
            <CardText>{text}</CardText>
        </Card>
    )
}

export default HomePageCard;