import { createSlice } from '@reduxjs/toolkit';
import guestsApi from '../../api/guests-api';
import { updateInvitationLink } from '../user-event/user-event-reducer';

const initialState = {
    guests: [],
    total: 0,
    attending: 0,
    error: null,
    loading: false
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _loadGuestsSuccess = (state, action) => {
    const { data } = action.payload;
    state.loading = false;
    state.guests = data;
};

const _loadAttendingSuccess = (state, action) => {
    const { total, attending } = action.payload;
    state.loading = false;
    state.total = total;
    state.attending = attending;
};

const _sendLinkSuccess = (state, action) => {
    state.loading = false;
    state.error = null;
};

const _cleanGuests = (state) => {
    return initialState;
};

const guests = createSlice({
    name: 'guests',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        loadGuestsSuccess: _loadGuestsSuccess,
        loadAttendingSuccess: _loadAttendingSuccess,
        cleanGuests: _cleanGuests,
        sendLinkSuccess: _sendLinkSuccess
    },
});

const { actions, reducer } = guests;

export const { loadStart, loadFailed, loadGuestsSuccess, loadAttendingSuccess, cleanGuests, sendLinkSuccess } = actions;

export default reducer;

export const loadGuests = (userEventId, search) => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await guestsApi.fetchGuests(userEventId, search);
        if (status === 200) {
            return dispatch(loadGuestsSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};

export const loadAttending = (userEventId) => {
    return async (dispatch) => {
        const { status, data, error } = await guestsApi.fetchAttending(userEventId);
        if (status === 200) {
            return dispatch(loadAttendingSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};

export const sendInvitationLink = (link, eventId) => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, error } = await guestsApi.sendLink(link, eventId);
        if (status === 200) {
            dispatch(updateInvitationLink(link));
            return dispatch(sendLinkSuccess());
        } else {
            return dispatch(loadFailed(error));
        }
    };
}