import React from 'react';

import eventroLogo from '../../../../assets/icons/eventro.svg'

import { PopupButton } from '../../../../common/components/CommonStyledComponent';
import { EventroLogo, NewUserBoldText, NewUserContainer, NewUserText } from '../../DashboardStyles';

const NewUserPopup = ({ onCloseClick }) => {

    return (
        <NewUserContainer>
            <NewUserText>
                From here you can manage your entire event from start to finish.  Your vendors, or A Team as we like to call them, are already here -
                shall we meet them?
            </NewUserText>
            <NewUserBoldText>
                Welcome to your very own Event Room!
            </NewUserBoldText>
            <EventroLogo src={eventroLogo} alt="/" />
            <PopupButton onClick={onCloseClick}>Got It</PopupButton>
        </NewUserContainer>
    )

}

export default NewUserPopup;