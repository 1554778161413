import { string, object, array } from 'yup';
import { metadataSchema } from './common-schemas';

const participantSchema = object().shape({
    _id: string().required(),
    participantId: string().required(),
    conversationId: string().required(),
    account: object().shape({
        _id: string().required(),
        role: string().required(),
        email: string().nullable(true),
        phone: string().nullable(true),
    }),
    pro: object().shape({
        _id: string().required(),
        occupation: string(),
        occupationTitle: string(),
        avatar: string().nullable(true),
        businessName: string()
    }).nullable(true),
});

const participantsSchema = object().shape({
    _id: string().required(),
    participants: array().of(participantSchema).default([])
});

export const participantsArraySchema = object().shape({
    data: array().of(participantsSchema).default([]),
    metadata: metadataSchema
});