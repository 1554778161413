import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { SettingsLayout, SettingsText, SettingsTextButton, SettingsTitle } from './SettingsStyles';
import routes from '../../routes/index';
import { authUserLogout } from '../../../store/reducers/auth/auth-reducer';
import { cleanSteps } from '../../../store/reducers/steps/steps-reducer';
import AboutUsPopup from './AboutUsPopup';
import GeneralPopup from '../../../common/components/GeneralPopup';
import EventroFamilyPopup from './EventroFamilyPopup';
import { FlexRow } from '../../../common/components/CommonStyledComponent';

const Settings = () => {
    const history = useHistory();
    const { user } = useSelector(state => state.auth);
    const { userEvent } = useSelector(state => state.userEvent);
    const [showAboutUsPopup, setShowAboutUsPopup] = useState(false);
    const [showEventroFamPopup, setShowEventroFamPopup] = useState(false);

    const dispatch = useDispatch();

    const logout = () => {
        dispatch(authUserLogout());
    }

    const onStartExperience = () => {
        dispatch(cleanSteps());
        history.push(routes.Boarding.boarding);
    }


    return (
        <>
            <SettingsLayout>
                <SettingsTitle>Settings</SettingsTitle>
                <FlexRow>
                    <SettingsText style={{ marginRight: '10px' }}>Event Id: </SettingsText>
                    <SettingsText>{userEvent._id}</SettingsText>
                </FlexRow>
                <SettingsTextButton onClick={onStartExperience}>Start a new experience</SettingsTextButton>
                <SettingsTextButton onClick={(e) => { e.stopPropagation(); setShowAboutUsPopup(true) }}>About Us</SettingsTextButton>
                <SettingsTextButton onClick={(e) => { e.stopPropagation(); setShowEventroFamPopup(true) }}>Eventro Partners</SettingsTextButton>
                <SettingsTextButton onClick={() => { }}>Terms and conditions</SettingsTextButton>
                <SettingsTextButton onClick={logout}>Logout from <b>{user.fullName || user.email}</b></SettingsTextButton>
            </SettingsLayout>
            {
                showAboutUsPopup ?
                    <GeneralPopup popupStyle={{ maxWidth: 'none', width: '90%' }} negativeButtonAction={() => { setShowAboutUsPopup(false) }}>
                        <AboutUsPopup onCloseClick={() => { setShowAboutUsPopup(false) }} />
                    </GeneralPopup> :
                    null
            }
            {
                showEventroFamPopup ?
                    <GeneralPopup popupStyle={{ maxWidth: 'none', width: '90%' }} negativeButtonAction={() => { setShowEventroFamPopup(false) }}>
                        <EventroFamilyPopup onCloseClick={() => { setShowEventroFamPopup(false) }} />
                    </GeneralPopup> :
                    null
            }
        </>)
}

export default Settings;
