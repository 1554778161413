import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import LoaderGradient from '../../common/components/LoaderGradient';
import { cleanNewUser } from '../../store/reducers/auth/auth-reducer';
import { MiddleText } from '../Boarding/ChooseEvent/ChooseEventStyles';
import EventRoom from './EventRoom/EventRoom';
import Routes from '../routes';
import Messages from './Messages/Messages';
import Team from './Team/Team';
import Guests from './Guests/Guests';
import Budget from './Budget/Budget';
import Fantasies from './Fantasies/Fantasies';
import Settings from './Settings/Settings';
import { saveEvent } from '../../store/reducers/steps/steps-reducer';
import { loadUserEvent } from '../../store/reducers/user-event/user-event-reducer';
import { WhiteButton } from '../../common/components/CommonStyledComponent';
import { getTwilioToken } from '../../store/reducers/conversations/conversations-reducer';
import DashboardLayout from '../../hoc/DashboardLayout';
import Loader from '../../common/components/Loader';
import GeneralPopup from '../../common/components/GeneralPopup';
import NewUserPopup from './Team/components/NewUserPopup';

const Dashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.auth.user);
    const { complete } = useSelector((state) => state.steps.eventPick);
    const { userEvent, loading } = useSelector((state) => state.userEvent);
    const [isEventRequested, setIsEventRequested] = useState(false);

    const isWindowForMobile = window.innerWidth <= 768;

    useEffect(() => {
        if (complete) {
            dispatch(saveEvent()).then(() => {
                setIsEventRequested(true);
            });
        } else {
            dispatch(loadUserEvent()).then(() => {
                setIsEventRequested(true);
            });
        }
    }, []);

    useEffect(async () => {
        let twilioTok = await getTwilioToken();
        localStorage.setItem('twilio_token', twilioTok);
    }, []);

    let view = <Loader />;

    if (!userEvent && !loading && isEventRequested) {
        view = (
            <LoaderGradient styles={{ position: 'absolute', background: 'white', zIndex: 999 }}>
                <MiddleText style={{ marginBottom: '20px' }}>Make your dream come true</MiddleText>
                <MiddleText>
                    <WhiteButton
                        onClick={() => {
                            history.push(Routes.Boarding.boarding);
                        }}>
                        Start Wishing
                    </WhiteButton>
                </MiddleText>
            </LoaderGradient>
        );
    } else if (userEvent && isEventRequested) {
        view = (
            <DashboardLayout>
                <Switch>
                    <Route path={Routes.Dashboard.eventRoom} component={EventRoom} exact={true}></Route>
                    <Route path={Routes.Dashboard.messages} component={Messages} exact={true}></Route>
                    <Route path={Routes.Dashboard.team} component={Team} exact={true}></Route>
                    <Route path={Routes.Dashboard.guests} component={Guests} exact={true}></Route>
                    <Route path={Routes.Dashboard.budget} component={Budget} exact={true}></Route>
                    <Route path={Routes.Dashboard.fantasies} component={Fantasies} exact={true}></Route>
                    <Route path={Routes.Dashboard.settings} component={Settings} exact={true}></Route>
                    <Redirect to={Routes.Dashboard.eventRoom} />
                </Switch>
                {
                    user.isNewUser ?
                        <GeneralPopup
                            negativeButtonAction={() => { dispatch(cleanNewUser()) }}
                            popupStyle={{ maxWidth: 'none', width: isWindowForMobile ? '90%' : 'auto', padding: isWindowForMobile ? '20px' : '60px 100px', boxSizing: 'border-box' }}
                        >
                            <NewUserPopup onCloseClick={() => { dispatch(cleanNewUser()) }} />
                        </GeneralPopup>
                        : null
                }

            </DashboardLayout>
        );
    }

    return <>{view}</>;
};

export default Dashboard;
