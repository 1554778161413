import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import searchIcon from '../../../assets/icons/search.svg';
import { ReactComponent as XIcon } from '../../../assets/icons/x.svg';
import { FlexRowAligned, PrimaryButton } from '../../../common/components/CommonStyledComponent';
import GeneralPopup from '../../../common/components/GeneralPopup';
import Loader from '../../../common/components/Loader';
import { loadGuests, loadAttending, cleanGuests, sendInvitationLink } from '../../../store/reducers/guests/guests-reducer';
import GuestsPopup from './GuestsPopup';
import {
    SearchContainer,
    SearchImg,
    SearchInput,
    PageTitle,
    GuestsHeader,
    Text,
    GuestsLayout,
    ListContainer,
    ColorsWrapper,
    Circle,
    AttendingStyle,
    LinkContentInput,
    InputContainer,
    SubmitButtonWrapper,
    SearchAndColorsWrapper,
    GuestContainer,
    CloseContainer,
    MobileLinkContentButton,
} from './GuestsStyles';
import GuestTab from './GuestTab';

const Guests = () => {
    const dispatch = useDispatch();
    const { userEvent } = useSelector((state) => state.userEvent);
    const { guests, total, attending, loading } = useSelector((state) => state.guests);
    const [search, setSearch] = useState('');
    const [linkContent, setLinkContent] = useState(userEvent.whatsAppLink);
    const [showLinkContent, setShowLinkContent] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const isWindowForMobile = window.innerWidth <= 768;

    useEffect(() => {
        dispatch(loadAttending(userEvent._id)).then((response) => {
            if (response?.payload?.total === 0) {
                setShowPopup(true);
            }
        });
        return () => {
            dispatch(cleanGuests());
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(loadGuests(userEvent._id, search));
        }, 300);
        return () => {
            clearTimeout(timer);
        };
    }, [search]);

    const sendLink = () => {
        if (linkContent) {
            dispatch(sendInvitationLink(linkContent, userEvent._id));
        }
    }

    return (
        <>
            <GuestsLayout>
                {loading ? <Loader /> : null}
                <GuestContainer>
                    <GuestsHeader>
                        <PageTitle>Guests</PageTitle>
                        <Text>{attending + ' of ' + total + ' confirmed'}</Text>
                    </GuestsHeader>
                    <MobileLinkContentButton
                        showLinkContent={showLinkContent}
                        onClick={() => {
                            setShowLinkContent(true);
                        }}>
                        Send Whatsapp group link
                    </MobileLinkContentButton>
                    <InputContainer showLinkContent={showLinkContent}>
                        <LinkContentInput value={linkContent} onChange={(e) => setLinkContent(e.target.value)} placeholder='Type the content of your Whatsapp group link' />
                        <SubmitButtonWrapper>
                            <PrimaryButton onClick={sendLink}>Submit</PrimaryButton>
                        </SubmitButtonWrapper>
                        <CloseContainer
                            onClick={() => {
                                setShowLinkContent(false);
                            }}>
                            <XIcon width={'20'} height={'20'} fill={'#222222'} />
                        </CloseContainer>
                    </InputContainer>
                </GuestContainer>

                <SearchAndColorsWrapper>
                    <SearchContainer>
                        <SearchImg src={searchIcon} alt='Search' />
                        <SearchInput value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' />
                    </SearchContainer>
                    <ColorsWrapper>
                        <FlexRowAligned>
                            <Circle color={'var(--green)'} />
                            <AttendingStyle>Approved</AttendingStyle>
                        </FlexRowAligned>
                        <FlexRowAligned>
                            <Circle color={'var(--red)'} />
                            <AttendingStyle>Not Coming</AttendingStyle>
                        </FlexRowAligned>
                        <FlexRowAligned>
                            <Circle color={'var(--gray)'} />
                            <AttendingStyle>Maybe</AttendingStyle>
                        </FlexRowAligned>
                    </ColorsWrapper>
                </SearchAndColorsWrapper>

                <ListContainer>
                    {guests &&
                        guests.map((guest) => {
                            return <GuestTab key={guest._id} guest={guest} />;
                        })}
                </ListContainer>
            </GuestsLayout>
            {
                showPopup ?
                    <GeneralPopup
                        negativeButtonAction={() => { setShowPopup(false) }}
                        popupStyle={{ maxWidth: 'none', width: isWindowForMobile ? '90%' : 'auto', padding: isWindowForMobile ? '20px' : '60px 100px', boxSizing: 'border-box' }}
                    >
                        <GuestsPopup onCloseClick={() => { setShowPopup(false) }} />
                    </GeneralPopup>
                    : null
            }
        </>
    );
};

export default Guests;
