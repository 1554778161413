import { createSlice } from '@reduxjs/toolkit';
import locationsApi from '../../api/locations-api';

const initialState = {
    locations: [],
    metadata: { total: 0, page: 1 },
    error: null,
    loading: false
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
    state.locations = [];
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _loadLocationsSuccess = (state, action) => {
    const { data, metadata } = action.payload;
    state.metadata = metadata;
    state.loading = false;
    state.locations = data;
};


const _cleanLocations = (state) => {
    return initialState;
};

const locations = createSlice({
    name: 'locations',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        loadLocationsSuccess: _loadLocationsSuccess,
        cleanLocations: _cleanLocations,
    },
});

const { actions, reducer } = locations;

export const {
    loadStart,
    loadFailed,
    loadLocationsSuccess,
    cleanLocations
} = actions;

export default reducer;

export const locationsSelector = (state) => state.locations;

export const loadLocations = (page = 1, limit = 10, search = '') => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await locationsApi.fetchLocations(page, limit, search);
        if (status === 200) {
            return dispatch(loadLocationsSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};