import styled from 'styled-components';

export const LocationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

export const ImageContainer = styled.div`
    position: relative;
    text-align: center;
    color: white;
    background: ${props => props.bg ? `linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)), url(${props.bg})` : 'none'};
    border-radius: 16px;
    height: 255px;
    cursor: pointer;
    background-size: cover;

    @media (max-width: 768px) {
        height: 200px;
    }
`;

export const CenterText = styled.div`
    position: absolute;
    font-family: 'Poppins';
    pointer-events: none;
    font-weight: 700;
    user-select: none;
    font-size: 32px;
    line-height: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const CityListGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 24px 40px;
    grid-gap: 18px;
    font-family: 'Poppins';
    color: var(--black);
    font-weight: 400;
    font-size: 18px;
    line-height: 46px;

    @media (max-width: 768px) {
        font-size: 16px;
        grid-template-columns: 1fr;
    }
`;

export const CityItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    border: ${props => props.clicked ? '1px solid var(--light-red)' : '1px solid rgba(0,0,0,0)'};
    background: ${props => props.clicked ? 'var(--pink)' : 'none'};
    color: ${props => props.clicked ? 'var(--red)' : 'var(--black)'};
    padding:  8px 14px;
    border-radius: 75px;
    cursor: pointer;
    -webkit-tap-highlight-color:transparent;
    
    &:hover{
        background: var(--pink);
        box-shadow: 0px 2px 0px rgba(148, 148, 148, 0.25);
    }

    @media (max-width: 768px) {
        padding:  8px 14px;
    }

    @media (hover: none) {
        &:hover {
            border: ${props => props.clicked ? '1px solid var(--light-red)' : '1px solid rgba(0,0,0,0)'};
            background: ${props => props.clicked ? 'var(--pink)' : 'none'};
            box-shadow: none;
        }
    }
`;


export const CloseIcon = styled.img`
    display: ${props => props.clicked ? 'iherit' : 'none'};
    width: 18px;
    height: 18px;
    margin-left: 12px;
`;