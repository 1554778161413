
import React from 'react';

import { FlexColumn, FlexRow } from './CommonStyledComponent';
import Modal, { PopupWindowLayout } from './ui/PopupWindowLayout';

const GeneralPopup = ({ negativeButtonAction, children, popupStyle }) => {
    return (
        <Modal
            popupStyle={popupStyle}
            negativeButtonAction={negativeButtonAction}
            body={
                <>
                    <PopupWindowLayout.ColumnContainer>
                        <FlexRow style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <FlexColumn style={{ margin: '0 10px', justifyContent: 'center', alignItems: 'center' }}>
                                {children}
                            </FlexColumn>
                        </FlexRow>
                    </PopupWindowLayout.ColumnContainer>
                </>
            }></Modal>
    );
};


export default GeneralPopup;