import { createSlice } from '@reduxjs/toolkit';
import Routes from '../../../components/routes';
import { getId } from '../../../common/utils/utils'
import answersApi from '../../api/answers-api';
import cardsApi from '../../api/cards-api';
import eventsApi from '../../api/events-api';
import { loadUserEventSuccess } from '../user-event/user-event-reducer';

const initialState = {
    eventKind: {
        type: null,
        nextStep: Routes.Boarding.where,
        complete: false
    },
    where: {
        cities: [],
        nextStep: Routes.Boarding.date,
        complete: false
    },
    date: {
        date: null,
        dateOnlyMonth: false,
        nextStep: Routes.Boarding.guests,
        complete: false
    },
    guests: {
        minNumberOfGuest: null,
        maxNumberOfGuest: null,
        nextStep: Routes.Boarding.swipes,
        complete: false
    },
    swipes: {
        cards: [],
        liked: [],
        disliked: [],
        seen: [],
        hasMore: true,
        complete: false,
        nextStep: Routes.Boarding.chooseEvent
    },
    eventPick: {
        event: null,
        complete: false,
        nextStep: Routes.Common.signup
    },
    loading: false,
    error: null,
    currentStep: Routes.Boarding.kind,
    uuid: getId()
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _saveAnswersSuccess = (state, action) => {
    state.loading = false;
    state.error = null;
}

const _nextPage = (state, action) => {
    const { nextStep } = action.payload;
    state.currentStep = nextStep;
}

const _completeStep = (state, action) => {
    const { stepName, data } = action.payload;
    state[stepName].complete = true;
    state[stepName] = { ...state[stepName], ...data };
}

const _loadCardsSuccess = (state, action) => {
    const { hasMore, nextQuestion } = action.payload;
    state.swipes.cards = [...nextQuestion, ...state.swipes.cards];
    state.swipes.hasMore = hasMore;
    state.swipes.seen = [...state.swipes.seen, ...nextQuestion.map((question) => question._id)];
    state.loading = false;
    state.error = null;
}

const _addToLikeList = (state, action) => {
    const id = action.payload;
    state.swipes.liked = [...state.swipes.liked, id];
}

const _addToDislikeList = (state, action) => {
    const id = action.payload;
    state.swipes.disliked = [...state.swipes.disliked, id];
}

const _removeCard = (state, action) => {
    state.swipes.cards = state.swipes.cards.slice(0, -1);
}

const _pickEventSucess = (state, action) => {
    state.loading = false;
    state.error = null;
}

const _changeUUID = (state, action) => {
    state.uuid = action.payload;
}

const _saveEventSuccess = (state, action) => {
    state.loading = false;
    state.error = null;
}

const _cleanCards = (state) => {
    state.swipes = {
        cards: [],
        liked: [],
        disliked: [],
        seen: [],
        hasMore: true,
        complete: false,
        nextStep: Routes.Boarding.chooseEvent
    };
}

const _cleanSteps = (state) => {
    state.eventKind = {
        type: null,
        nextStep: Routes.Boarding.where,
        complete: false
    };
    state.where = {
        cities: [],
        nextStep: Routes.Boarding.date,
        complete: false
    };
    state.date = {
        date: null,
        dateOnlyMonth: false,
        nextStep: Routes.Boarding.guests,
        complete: false
    };
    state.guests = {
        minNumberOfGuest: null,
        maxNumberOfGuest: null,
        nextStep: Routes.Boarding.swipes,
        complete: false
    };
    state.swipes = {
        cards: [],
        liked: [],
        disliked: [],
        seen: [],
        hasMore: true,
        complete: false,
        nextStep: Routes.Boarding.chooseEvent
    };
    state.eventPick = {
        event: null,
        complete: false,
        nextStep: Routes.Common.signup
    };
    state.loading = false;
    state.error = null;
    state.currentStep = Routes.Boarding.kind;
    state.uuid = getId();
};

const steps = createSlice({
    name: 'steps',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        saveAnswersSuccess: _saveAnswersSuccess,
        nextPage: _nextPage,
        completeStep: _completeStep,
        loadCardsSuccess: _loadCardsSuccess,
        addToLikeList: _addToLikeList,
        addToDislikeList: _addToDislikeList,
        removeCard: _removeCard,
        pickEventSucess: _pickEventSucess,
        saveEventSuccess: _saveEventSuccess,
        cleanSteps: _cleanSteps,
        changeUUID: _changeUUID,
        cleanCards: _cleanCards
    },
});

const { actions, reducer } = steps;

export const {
    loadStart,
    loadFailed,
    nextPage,
    saveAnswersSuccess,
    loadNextCard,
    completeStep,
    loadCardsSuccess,
    addToLikeList,
    addToDislikeList,
    removeCard,
    pickEventSucess,
    saveEventSuccess,
    cleanSteps,
    changeUUID,
    cleanCards
} = actions;

export default reducer;

export const saveAnswers = () => {
    return async (dispatch, getState) => {
        dispatch(loadStart());
        let currentState = getState().steps;
        let newUuid = getId();
        dispatch(changeUUID(newUuid));
        const answersData = {
            uuid: newUuid,
            minNumberOfGuests: currentState.guests.minNumberOfGuest,
            maxNumberOfGuests: currentState.guests.maxNumberOfGuest,
            eventType: currentState.eventKind.type,
            locationIds: currentState.where.cities,
            date: currentState.date.date,
            dateOnlyMonth: currentState.date.dateOnlyMonth
        }
        const { data, status, error } = await answersApi.saveAnswers(answersData);
        if (status === 200 && data) {
            return dispatch(saveAnswersSuccess());
        } else {
            return dispatch(loadFailed(error));
        }
    }
};

export const loadMoreCards = (quantity) => {
    return async (dispatch, getState) => {
        let currentState = getState().steps;
        if (!currentState.swipes.hasMore) {
            return;
        }
        dispatch(loadStart());
        const cardsData = {
            uuid: currentState.uuid,
            minNumberOfGuests: currentState.guests.minNumberOfGuest,
            maxNumberOfGuests: currentState.guests.maxNumberOfGuest,
            eventType: currentState.eventKind.type,
            locationIds: currentState.where.cities,
            date: currentState.date.date,
            dateOnlyMonth: currentState.date.dateOnlyMonth,
            liked: currentState.swipes.liked,
            disliked: currentState.swipes.disliked,
            seen: currentState.swipes.seen,
            quantity: quantity
        }
        const { status, data, error } = await cardsApi.fetchMoreCards(cardsData);
        if (status === 200) {
            return dispatch(loadCardsSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};

export const pickEvent = () => {
    return async (dispatch, getState) => {
        let currentState = getState().steps;
        dispatch(loadStart());
        const eventData = {
            uuid: currentState.uuid,
            event: currentState.eventPick.event._id
        }
        const { status, data, error } = await eventsApi.pickEvent(eventData);
        if (status === 200) {
            return dispatch(pickEventSucess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
}

export const saveEvent = () => {
    return async (dispatch, getState) => {
        let currentState = getState().steps;
        dispatch(loadStart());
        const eventData = {
            uuid: currentState.uuid,
        }
        const { status, data, error } = await eventsApi.saveEvent(eventData);
        if (status === 200) {
            dispatch(saveEventSuccess());
            dispatch(cleanSteps());
            return dispatch(loadUserEventSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
}