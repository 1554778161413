import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { nextPage } from '../store/reducers/steps/steps-reducer';

export const useCheckStep = (step) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const steps = useSelector(state => state.steps);

    useEffect(() => {
        if (!step.complete && steps.currentStep !== location.pathname) {
            history.push(steps.currentStep)
        }
    }, [])


    const onNextPage = () => {
        dispatch(nextPage({ nextStep: step.nextStep }));
        history.push(step.nextStep);
    }

    return onNextPage;
}