import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Routes from '../routes';

import Where from './Where/Where';
import EventKind from './EventKind/EventKind';
import EventDate from './EventDate/EventDate';
import EventGuests from './EventGuests/EventGuests';
import Swipes from './Swipes/Swipes';
import ChooseEvent from './ChooseEvent/ChooseEvent';

const Boarding = () => {
    return (
        <Switch>
            <Route path={Routes.Boarding.kind} component={EventKind} exact={true}></Route>
            <Route path={Routes.Boarding.where} component={Where} exact={true}></Route>
            <Route path={Routes.Boarding.date} component={EventDate} exact={true}></Route>
            <Route path={Routes.Boarding.guests} component={EventGuests} exact={true}></Route>
            <Route path={Routes.Boarding.swipes} component={Swipes} exact={true}></Route>
            <Route path={Routes.Boarding.chooseEvent} component={ChooseEvent} exact={true}></Route>
            <Redirect to={Routes.Boarding.kind} />
        </Switch>
    );
}

export default Boarding;