import { Client } from '@twilio/conversations';
import { getTwilioToken } from './store/reducers/conversations/conversations-reducer';
import jwt_decode from "jwt-decode";

class TwilioClient {

    constructor() {
        this.client = null;
    }

    onTokenUpdate = async () => {
        try {
            let newToken = await getTwilioToken();
            localStorage.setItem('twilio_token', newToken);
            await this.client.updateToken(newToken);
        } catch (err) {
            console.log(err.message);
        }
    }

    async resetToken() {
        try {
            let twilioToken = await getTwilioToken();
            this.client = await Client.create(twilioToken);

            localStorage.setItem('twilio_token', twilioToken);
        } catch (err) {
            console.log(err.message);
        }
    }

    async disconnect() {
        await this.client.shutdown();
        this.client = null;
    }

    async initializeTwilio() {
        let twilioToken = localStorage.getItem('twilio_token');
        if (twilioToken) {
            let decoded = jwt_decode(twilioToken);
            if (Date.now() >= decoded.exp * 1000) { // token is expired
                return await this.resetToken();
            }

            this.client = await Client.create(localStorage.getItem('twilio_token'));

        } else {
            await this.resetToken();
        }

        this.client.on('tokenAboutToExpire', this.onTokenUpdate);

        this.client.on('tokenExpired', this.onTokenUpdate);

    }

}

const twilioClient = new TwilioClient();

export default twilioClient;