import styled from 'styled-components';
import { ReactComponent as Share } from '../../../assets/icons/share.svg';

export const MiddleText = styled.div`
    z-index: 100;
    font-weight: 600;
    font-size: 43px;
    line-height: 73px;
    color: white;
    text-align: center;
    max-width: 700px;

    @media (max-width: 768px) {
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        padding: 0px 20px;
    }
`;

export const FlexLayout = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const EventImage = styled.img`
    width: 50%;
    height: 100%;

    @media (max-width: 768px) {
        width: 100%;
        height: 40%;
    }
`;

export const FireworksIcon = styled.img`
    position: absolute;
    top: 30px;
    right: 30px;
    width: 120px;
    height: 120px;

    @media (max-width: 768px) {
        top: 20px;
        left: 20px;
        width: 60px;
        height: 60px;
    }
`;

export const EventTitle = styled.div`
    font-family: 'HeroicCondensed';
    font-size: 100px;
    color: white;
    line-height: 100px;
    font-weight: 600;
    text-transform: capitalize;
    font-style: italic;
    margin-top: 20px;

    @media (max-width: 768px) {
        font-size: 76px;
        line-height: 76px;
        text-align: center;
    }
`;

export const EventContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    justify-content: center;
    padding: 40px;
    background: ${props => props.bgColor ? props.bgColor : '#FFFFFF'};
    box-sizing: border-box;

    @media (max-width: 768px) {
        align-items: center;
        width: 100%;
        padding: 30px;
    }
`;

export const EventDescription = styled.div`
    font-size: 21px;
    line-height: 34px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 27px;
        text-align: center;
    }
`;

export const ShareIcon = styled(Share)`
    width: 27px;
    height: 27px;
    margin: 0px 20px;
    cursor: pointer;

    @media (max-width: 768px) {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 20px;
        right: 20px;
        margin: 0px;
    }
`;

export const EventVideo = styled.div`
    width: 50%;
    /* background: ${(props) => `url(${props.bg})`}; */

    @media (max-width: 768px) {
        height: 40%;
        width: 100%;
    }
`;