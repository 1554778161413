import React from 'react';

import { ReactComponent as ReviewsLogo } from '../../../../assets/icons/reviews.svg';

import { DetailsText, DetailsContainer, DetailsSpanText } from '../styles/ProTabStyles';
import { FlexColumn } from '../../../../common/components/CommonStyledComponent';
import { currencyChar } from '../../../../common/utils/utils';

// const getRange = (range) => {
//     switch (range) {
//         case 1:
//             return 'Low';
//         case 2:
//             return 'Average';
//         case 3:
//             return 'High';

//         default:
//     }
// };

const ProTabDetails = ({ pro }) => {
    return (
        <DetailsContainer>
            <FlexColumn>
                <DetailsText>
                    {'+' + pro.experience + 'y. '}
                    <DetailsSpanText>Experience</DetailsSpanText>
                </DetailsText>
                <DetailsText>
                    {'Area '}
                    <DetailsSpanText>{pro.activityArea}</DetailsSpanText>
                </DetailsText>
            </FlexColumn>

            <FlexColumn>
                <DetailsText>
                    {'Typical Job cost '}
                    <DetailsSpanText>{
                        pro.isPricePerHead ? currencyChar[pro.currency] + pro.price.toFixed(0) + ' per person'
                            : currencyChar[pro.currency] + pro.price.toFixed(0) + ' overall event cost'}
                    </DetailsSpanText>
                </DetailsText>
                <DetailsText>
                    {'Feedback reviews '}
                    <DetailsSpanText>
                        <ReviewsLogo />
                    </DetailsSpanText>
                </DetailsText>
            </FlexColumn>

            {/* <FlexColumn>
                <DetailsText>
                    {currencyChar[pro.currency] + ' '}
                    <DetailsSpanText>{getRange(pro.priceRange)}</DetailsSpanText>
                </DetailsText>

                <DetailsText>
                    {'Languages '}
                    <DetailsSpanText>{pro.language}</DetailsSpanText>
                </DetailsText>
            </FlexColumn> */}

        </DetailsContainer>
    );
};

export default ProTabDetails;
