import axiosInstance from '../../axios';
import ServerRoutes from './routes/index';
import { errorSchema } from './schemas/common-schemas';
import { saveAnswersSchema } from './schemas/answers-schemas';
import { validate } from './schemas/validator';

const saveAnswers = async (answersData) => {
    try {
        let url = ServerRoutes.saveAnswers;

        const { status, data, error } = await axiosInstance.post(url, answersData);
        const { result, e, s } = await validate(saveAnswersSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    }
    catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Could not save answers';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
}

const answersApi = {
    saveAnswers: saveAnswers
}

export default answersApi;