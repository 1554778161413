import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const Nav = styled.nav`
    display: flex;
    flex-direction: column;
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    height: 100%;
    position: relative;

    @media (max-width: 768px) {
        width: 100%;
        max-width: none;
        min-width: initial;
        overflow: ${props => props.isOpen ? 'hidden' : 'auto'}
    }
`;

const NavLogo = styled.div`
    height: 130px;
    width: 130px;
    padding: 10px 90px;

    @media only screen and (max-width: 768px) {
        height: 40px;
        width: 40px;
        padding: 0px 30px 0px 0px;
        margin: auto;
    }
`;

const fadeOut = keyframes`
   0% {opacity: 0;}
   100% {opacity: 1;}
`;

const NavBarContainer = styled.div`
    display: ${props => props.show ? 'flex' : 'none'};
    animation: ${fadeOut} 0.7s;
    overflow: hidden;
    flex-direction: column;
    flex-basis: auto;
    justify-content: space-between;
    align-items: center;
    height: ${props => props.isClicked ? 'calc(100% - 60px)' : '100%'};
    width: 100%;
    background: white;
    position: ${props => props.isClicked ? 'fixed' : 'relative'};;
    top: ${props => props.isClicked ? '60px' : '0'};
    z-index: 999;
`;

const NavBar = styled.ul`
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    list-style: none;
    width: 100%;

    @media (max-width: 768px) {
        align-items: flex-start;
        justify-content: center;
        margin-left: 40px;
    }
`;

const NavItem = styled.li`
    margin-bottom: 7px;
    width: 250px;
    padding: 0;
    align-items: center;
    display: flex;
    a {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0;
        text-decoration: none;
        color: var(--neutral-40p);
        font-size: 26px;
        line-height: 50px;
        font-family: Poppins;
    }
    &:hover > a {
        color: #ec5c57;
    }

    @media (max-width: 768px) {
        a {
            font-size: 24px;
            line-height: 60px;;
        }
    }
`;

const activeClassName = 'active-nav';

const NavStyledNavLink = styled(NavLink).attrs({
    activeClassName: activeClassName,
})`
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 32px;
    line-height: 72px;
    align-items: center;

    &.${activeClassName} {
        color: #ec5c57;
        fill: #ec5c57;
    }
    
`;

const NavControlsContainer = styled.div`
    position: absolute;
    bottom: 30px;
    left: 30px;

    @media (max-width: 768px) {
        position: relative;
        bottom: 0;
        left: 0;
        padding: 20px;
        align-self: center;
        align-items: center;
        justify-content: center;
    }
`;

const NavControlsList = styled.ul`
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    align-items: baseline;
`;

const NavControlsListItem = styled(NavLink)`
    display: flex;
    justify-content: flex-start;
    list-style: none;
    height: 100%;
    align-items: flex-start;
    background: transparent;
    position: relative;
    display: flex;
    padding: 0;
    font-weight: 600;
    opacity: ${props => props.selected ? '1' : '0.4'};
    color: ${props => props.selected ? '#EC5C57 !important' : '#222222 !important'};
    text-decoration: none;
    color: var(--neutral-50p);
    font-size: 18px;
    line-height: 40px;
    font-family: Poppins;

    &:hover {
        color: #ec5c57;
    }
`;

const NavMobileTop = styled.div`
    height: 52px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1000;
    align-items: center;
`;

const NavMobileBottom = styled.div`
    margin-top: ${(props) => {
        return props.clicked ? '50px' : 'none';
    }};
    z-index: 999;
    overflow: auto;
    padding-bottom: 30px;
    background: white;
`;

const NavMobileWrapper = styled.div`
    position: fixed;
    overflow: auto;
    min-width: 100%;
    max-height: 100%;
    min-height: 52px;
    height: ${(props) => {
        return props.clicked ? '100%' : 'auto';
    }};
    z-index: 1001;
`;

const NavButtonsCombine = styled.div`
    display: flex;
    z-index: 1000;
    align-items: center;
`;

const TopNav = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    
    @media (max-width: 768px) {
        height: 61px;
        position: fixed;
        width: 100%;
        background: ${props => props.bg ? props.bg : 'white'};
        z-index: 999;
        top: 0;
    }
`;

const HeaderTitle = styled.div`
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 0px;
        margin-top: 20px;
    }
`;

const navigation = {
    Nav,
    NavLogo,
    NavBar,
    NavBarContainer,
    NavControlsContainer,
    NavItem,
    NavStyledNavLink,
    NavControlsList,
    NavControlsListItem,
    NavMobileTop,
    NavMobileBottom,
    NavButtonsCombine,
    NavMobileWrapper,
    TopNav,
    HeaderTitle
};

export default navigation;
