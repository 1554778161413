import React from 'react';
import { OptionsContainer, OptionText, OptionContainer } from '../styles/ProTabStyles';
import env from '../../../../config';

import { ReactComponent as SketchesIcon } from '../../../../assets/icons/sketches.svg';
import { ReactComponent as QuotaIcon } from '../../../../assets/icons/quota.svg';
import { ReactComponent as DocumentsIcon } from '../../../../assets/icons/documents.svg';
import styled from 'styled-components';

const StyledSketchesIcon = styled(SketchesIcon)`
    width: 19;
    height: 16;
    fill: var(--red);

    @media (max-width: 768px) {
        width: 30;
        height: 24;
    }
`;

const StyledQuotaIcon = styled(QuotaIcon)`
    width: 19;
    height: 16;
    fill: var(--red);

    @media (max-width: 768px) {
        width: 30;
        height: 24;
    }
`;

const StyledDocumentsIcon = styled(DocumentsIcon)`
    width: 19;
    height: 16;
    fill: var(--red);

    @media (max-width: 768px) {
        width: 30;
        height: 24;
    }
`;

const ProTabFiles = ({ pro, onDocClick }) => {
    return (
        <OptionsContainer>
            {pro && pro.skechtes && pro.skechtes.length > 0 ? (
                pro.skechtes[0].split('.')[1] === 'pdf' ? (
                    <OptionContainer
                        onClick={() => {
                            onDocClick(pro.skechtes);
                        }}>
                        <StyledSketchesIcon />
                        <OptionText>Skechtes</OptionText>
                    </OptionContainer>
                ) : (
                    <OptionContainer>
                        <StyledSketchesIcon />
                        <a href={env.apiGateway.PROS_BUCKET_URL + pro.skechtes[0]}>
                            <OptionText>Skechtes</OptionText>
                        </a>
                    </OptionContainer>
                )
            ) : null}
            {pro && pro.quota && pro.quota.length > 0 ? (
                pro.quota[0].split('.')[1] === 'pdf' ? (
                    <OptionContainer
                        onClick={() => {
                            onDocClick(pro.quota);
                        }}>
                        <StyledQuotaIcon />
                        <OptionText>Quota</OptionText>
                    </OptionContainer>
                ) : (
                    <OptionContainer>
                        <StyledQuotaIcon />
                        <a href={env.apiGateway.PROS_BUCKET_URL + pro.quota[0]}>
                            <OptionText>Quota</OptionText>
                        </a>
                    </OptionContainer>
                )
            ) : null}
            {pro && pro.documents && pro.documents.length > 0 ? (
                pro.documents[0].split('.')[1] === 'pdf' ? (
                    <OptionContainer
                        onClick={() => {
                            onDocClick(pro.documents);
                        }}>
                        <StyledDocumentsIcon />
                        <OptionText>Documents</OptionText>
                    </OptionContainer>
                ) : (
                    <OptionContainer>
                        <StyledDocumentsIcon />
                        <a href={env.apiGateway.PROS_BUCKET_URL + pro.documents[0]}>
                            <OptionText>Documents</OptionText>
                        </a>
                    </OptionContainer>
                )
            ) : null}
        </OptionsContainer>
    );
};

export default ProTabFiles;
