import styled from 'styled-components';

export const SwipesContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    padding: 60px;
    box-sizing: border-box;

    @media (max-width: 768px){
        padding: 30px;
    }
`;


export const SwipesGrid = styled.div`
    display: grid;
    border: 4px solid #F2F2F2;
    height: 100%;
    width: 100%;
    border-radius: 16px;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:  "swipe question" 
                          "swipe buttons";
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 0fr;
        border: none;
        grid-template-areas: "swipe" 
                             "buttons";
    }
`;

export const CardContainer = styled.div`
    grid-area: swipe;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 550px;
`;

export const TutorialContainer = styled.div`
    grid-area: swipe;
    position: relative;
    width: 100%;
    height: 100%;
`;

export const QuestionText = styled.div`
    grid-area: question;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const SmallText = styled.div`
    font-family: 'Poppins';
    font-weight: 400;
    color: #222222;
    font-size: 18px;
    margin-top: 40px;
`;

export const TutorialText = styled.div`
    font-weight: 700;
    font-size: 42px;
    line-height: 56px;
    color: #222222;
    text-align: center;

    @media (max-width: 768px){
        font-size: 24px;
        line-height: 36px;
    }
`;

export const Explanation = styled.div`
    display: none;
    flex-direction: row;
    align-items: center;
    grid-area: buttons;
    /* align-self: flex-end; */

    @media (max-width: 768px) {
        display: flex;
    }
`;

export const CardQuestion = styled.div`
    grid-area: question;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 42px;
    color: #222222;
    text-align: center;
    align-self: flex-end;
    
    @media (max-width: 768px) {
        font-size: 32px;
        display: none;
    }
`;

export const Card = styled.div`
    box-sizing: border-box;
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 16px;
    overflow: hidden;
    color: black;
    /* height: 700px;
    max-width: 900px; */
    /* filter: drop-shadow(0px 2px 0px rgba(111, 110, 157, 0.25)); */

    /* @media (max-width: 768px) {
        filter: drop-shadow(0px 2px 20px rgba(111, 110, 157, 0.25));
        height: 600px;
    } */
`;

export const CardImage = styled.div`
    background: ${props => props.bg ? `url(${props.bg})` : 'white'};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;

    @media (max-width: 768px) {
        height: 90%;
    }
`;

export const CardText = styled.div`
    display: none;
    padding: 10px;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
    padding: 30px;
    margin: auto;

    @media (max-width: 768px) {
        display: block;
        margin-top: 0px;
        padding: 14px;
    }
`;

export const LikeButton = styled.img`
    width: 90px;
    height: 90px;
    cursor: pointer;
    border-radius: 100%;
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.05);
`;

export const ButtonsContainer = styled.div`
    grid-area: buttons;
    display: flex;
    justify-content: center;
    align-self: flex-end;
    margin-bottom: 100px;
    @media (max-width: 768px) {
        margin-bottom: 0px;
        margin-top: 20px;
    }
`;