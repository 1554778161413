import styled from 'styled-components';

export const BoxesGrid = styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    padding: 0px 60px 0px 30px;
    box-sizing: border-box;
    position: relative;
    width: 100%;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 24px;
        grid-gap: 16px;
    }
`;

export const Box = styled.div`
    position: relative;
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    background: white;
    border: 4px solid #f2f2f2;
    box-sizing: border-box;
    width: 50vh;
    height: 40vh;
    overflow: hidden;
    cursor: ${(props) => (props.point ? 'pointer' : 'auto')};

    @media (max-width: 768px) {
        height: 160px;
        width: 100%;
    }
`;

export const ImgDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: ${(props) => (props.bg ? `linear-gradient(358.56deg, rgba(97, 66, 62, 0.4) 1.64%, rgba(97, 66, 62, 0) 101.27%), url(${props.bg})` : '')};
    background-position: center;
    background-size: cover;
    position: relative;
    cursor: pointer;
`;

export const BoxTitle = styled.div`
    font-family: Poppins;
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #ffffff;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
    padding: 20px;
    text-align: center;
    letter-spacing: 1.2px;
    text-transform: capitalize;

    @media (max-width: 768px) {
        font-size: 32px;
        line-height: 32px;
        font-weight: bold;
    }
`;

export const ShareText = styled.div`
    font-family: Poppins;
    font-weight: 600;
    color: #ffffff;
    font-size: 24px;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

export const BoxHeader = styled.div`
    font-family: Poppins;
    font-style: normal;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    color: #222222;

    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0px;
    }
`;

export const PBspent = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 60px;
    text-align: center;
    color: #222222;

    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 20px;
    }
`;

export const PBtotal = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #222222;

    @media (max-width: 768px) {
        font-size: 10px;
    }
`;

export const BoxLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    box-sizing: border-box;
    height: 100%;

    @media (max-width: 768px) {
        padding: 15px 30px !important;
        flex-direction: ${(props) => (props.rowMobile ? 'row' : 'column')};
        justify-content: space-between;
    }
`;

export const ProgressBarContainer = styled.div`
    width: 75%;
    height: 75%;

    @media (max-width: 768px) {
        width: 120px;
        height: 120px;
    }
`;

export const IconContainer = styled.div`
    width: 32px;
    height: 73px;

    @media (max-width: 768px) {
        width: 26px;
        height: 55px;
    }
`;

export const ConfirmedAmount = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    word-wrap: break-word;
    padding: 0 50%;
    margin-top: auto;

    @media (max-width: 768px) {
        font-size: 12px;
        padding: 0px;
    }
`;

export const RoundImagesContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

export const RoundCount = styled.div`
    width: 84px;
    height: 84px;
    margin: 4px 8px;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 45px;
        height: 45px;
    }
`;

export const EventRoomLayout = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow-y: hidden;
    background: ${(props) => props.bg ? `url(${props.bg})` : ''};
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 60px;
        background-position: top;
    }
`;

export const VerticalProgressLayout = styled.div`
    display: flex;
    flex-direction: column;
    padding: 60px 30px 60px 0px;
    box-sizing: border-box;
    height: 100%;
    width: 450px;
    min-width: 450px;
`;

export const HorizontalProgressLayout = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 200px;
    width: 100%;
    justify-content: space-between;
`;

export const ProgressCircle = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${(props) => (props.checked ? 'var(--red)' : '#F5F5F5')};
`;

export const ProgressLine = styled.div`
    width: 6px;
    height: 100%;
    margin-left: 5px;
    background: ${(props) => (props.checked ? 'var(--red)' : '#F5F5F5')};
`;

export const HorizontalProgressContainer = styled.div`
    background: #F5F5F5;
    position: relative;
    width: 96%;
    height: 4px;
`;

export const HorizontalProgressCircle = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    background: #EC5C57;
    bottom: -4px;
    left: ${props => props.complete ? (props.complete - 1) + '%' : '0'};
`;

export const HorizontalProgressFill = styled.div`
    background: #EC5C57;
    height: 4px;
    width: ${props => props.width ? props.width + '%' : 0};
`;

export const ChecklistContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;

    @media (max-width: 768px){
        height: 200px;
    }
`;

export const ChecklistTitle = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #222222;
    opacity: 0.8;
`;

export const ChecklistDescription = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #222222;
    opacity: 0.8;
    margin: 20px 10% 20px 0px;
`;

export const ChecklistButton = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #ec5c57;
    cursor: pointer;
    opacity: 0.8;
`;

export const ChecklistCover = styled.div`
    height: 100%;
    overflow: scroll;
    padding: 20px 0px;
    box-sizing: border-box;
    position: relative;
`;

export const TopGradient = styled.div`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    height: 200px;
    width: 100%;
    top: 0;
    z-index: 100;
    position: fixed;
    pointer-events: none;
`;

export const BottomGradient = styled.div`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    height: 200px;
    width: 100%;
    bottom: 0;
    z-index: 100;
    position: fixed;
    pointer-events: none;
`;

export const InfoLogo = styled.img`
    width: 29px;
    height: 29px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 11;
    /* pointer-events: none; */
`

export const InfoHeader = styled.div`
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: #222222;
`;

export const InfoText = styled.div`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #000000;
    text-align: center;
    padding: 10px 0px;
`;

export const EventDetailsContainer = styled.div`
    padding: 15px;
    padding-bottom:0px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px){
        padding: 10px;
    }
`;

export const EventDetailsTitle = styled.div`
    font-family: Poppins;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    color: #222222;
    margin-bottom: 30px;

    @media (max-width: 768px){
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
    }
`;

export const EventDetailsHeader = styled.div`
    font-family: Poppins;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    color: #222222;
    

    @media (max-width: 768px){
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 6px;
    }
`;

export const EventDetailsText = styled.div`
    font-family: Poppins;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #222222;
    margin-bottom: 20px;

    @media (max-width: 768px){
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 10px;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 768px){
        margin: 0px 8px;
    }
`;

export const EventHeadersContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-around;

    @media (max-width: 768px){
        flex-direction: row;
        justify-content: space-around;

    }
`;