import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Routes from '../routes';
import Login from '../Login/Login';
import Home from '../Home/Home';
import Boarding from '../Boarding/Boarding';
import SignUp from '../Boarding/SignUp/SignUp';
import ShareEvent from '../ShareEvent/ShareEvent';

const MainNavigation = () => {
    return (
        <Switch>
            <Route path={Routes.Common.shareEvent} component={ShareEvent}></Route>
            <Route path={Routes.Common.home} component={Home} exact={true}></Route>
            <Route path={Routes.Common.login} component={Login} exact={true}></Route>
            <Route path={Routes.Common.signup} component={SignUp} exact={true}></Route>
            <Route path={Routes.Boarding.boarding} component={Boarding} ></Route>
            <Redirect from="/" to={Routes.Common.home} />
        </Switch>
    );
};

export default MainNavigation;
