import { createSlice } from '@reduxjs/toolkit';
import eventTypesApi from '../../api/event-types-api';

const initialState = {
    eventTypes: [],
    metadata: { total: 0, page: 1 },
    error: null,
    loading: false
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
    state.eventTypes = [];
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _loadEventTypesSuccess = (state, action) => {
    const { data, metadata } = action.payload;
    state.metadata = metadata;
    state.loading = false;
    state.eventTypes = data;
};


const _cleanEventTypes = (state) => {
    return initialState;
};

const eventTypes = createSlice({
    name: 'eventTypes',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        loadEventTypesSuccess: _loadEventTypesSuccess,
        cleanEventTypes: _cleanEventTypes,
    },
});

const { actions, reducer } = eventTypes;

export const {
    loadStart,
    loadFailed,
    loadEventTypesSuccess,
    cleanEventTypes,
    filterLocations
} = actions;

export default reducer;

export const loadEventTypes = (page = 1, limit = 10) => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await eventTypesApi.fetchEventTypes(page, limit);
        if (status === 200) {
            return dispatch(loadEventTypesSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};