import React from 'react';
import { ImageNameWrapper, ImageContainer, NameWrapper, NameText, TypeWrapper, TypeText, ShareText, PlusContainer } from '../styles/ProTabStyles';
import { FlexRowAligned } from '../../../../common/components/CommonStyledComponent';
import env from '../../../../config';

import { ReactComponent as ShareIcon } from '../../../../assets/icons/share.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { ReactComponent as SuccessStar } from '../../../../assets/icons/success-star.svg';

const ProTabHeader = ({ pro, team, onAddClick }) => {
    const notInTeam = () => {
        if (pro && team) {
            const check = team.findIndex((t) => t._id === pro._id);
            if (check < 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    return (
        <ImageNameWrapper>
            <ImageContainer>
                <img style={{ borderRadius: '15px' }} src={env.apiGateway.PROS_BUCKET_URL + pro.avatar} alt='' width={'100%'} height={'100%'} />
                {pro.occupation === 'success_manager' ? <SuccessStar width={29} height={29} style={{ position: 'absolute', top: '5px', right: '5px' }} /> : null}
            </ImageContainer>
            <NameWrapper>
                <NameText>{pro.fullName}</NameText>
                <TypeWrapper>
                    <TypeText>
                        {pro.occupationTitle + ' '} <span style={{ fontWeight: 'normal' }}>{' ' + pro.businessName}</span>
                    </TypeText>
                    <FlexRowAligned>
                        {notInTeam() ? (
                            <FlexRowAligned
                                style={{ marginRight: '40px', cursor: 'pointer' }}
                                onClick={() => {
                                    onAddClick(pro);
                                }}>
                                <PlusContainer>
                                    <PlusIcon width={'12'} height={'12'} fill={'#fff'} />
                                </PlusContainer>
                                <ShareText>Add to Event</ShareText>
                            </FlexRowAligned>
                        ) : null}
                        <FlexRowAligned style={{ cursor: 'pointer' }}>
                            <ShareIcon width={'14'} height={'16'} fill={'#EC5C57'} />
                            <ShareText>Share</ShareText>
                        </FlexRowAligned>
                    </FlexRowAligned>
                </TypeWrapper>
            </NameWrapper>
        </ImageNameWrapper>
    );
};

export default ProTabHeader;
