import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCheckStep } from '../../../hooks/useCheckStep';
import { useSelector } from 'react-redux';
import ReactSlider from 'react-slider';
import '../../../styles/slider.css';

import discoImage from '../.././../assets/images/disco.png';
import backIcon from '../../../assets/icons/back.svg';
import nextIconOrange from '../../../assets/icons/next-orange.svg';
import { ReactComponent as FireworksIcon } from '../../../assets/icons/fireworks.svg';

import { EventsGrid, ImageContainer, EventsContainer, PageHeader, ImageTextContainer, ImageText, PageText } from '../EventKind/EventKindStyles';
import { BackButton, ProgressBar, ProgressFill, FlexRowSpaced, FlexColumn, PopupText, PopupButton } from '../../../common/components/CommonStyledComponent';
import { NextButton, NextButtonOrange, NextContainer, NextText } from '../Where/WhereStyles';
import { completeStep, loadMoreCards, saveAnswers } from '../../../store/reducers/steps/steps-reducer';
import { GuestsBox } from './EventGuestsStyles';
import GeneralPopup from '../../../common/components/GeneralPopup';
import Routes from '../../routes/index';

const EventGuests = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { error } = useSelector(state => state.steps)
    const guests = useSelector(state => state.steps.guests);
    const onNextPage = useCheckStep(guests);

    const isWindowForMobile = window.innerWidth <= 768;

    const [chosenNumOfGuests, setChosenNumOfGuests] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (error) {
            alert(error);
        }
    }, [error]);

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, []);

    const nextStep = (e) => {
        e.stopPropagation();
        if (!chosenNumOfGuests || chosenNumOfGuests.value.minNumberOfGuest === 0) {
            setShowPopup(true);
            return;
        }
        dispatch(completeStep({ stepName: 'guests', data: chosenNumOfGuests.value }));
        dispatch(saveAnswers()).then((response) => {
            if (!response.payload) {
                dispatch(loadMoreCards(2));
            }
        });
        onNextPage();
    }

    const onSliderChange = (value) => {
        setChosenNumOfGuests({ label: "Custom", value: { minNumberOfGuest: parseInt(value), maxNumberOfGuest: parseInt(value) } })
    }

    return (
        <>
            <EventsGrid>
                {
                    !isWindowForMobile ?
                        <ImageContainer bg={discoImage} >
                            <ImageTextContainer>
                                <FireworksIcon fill={'white'} width={123} height={123} style={{ marginRight: '10px' }} />
                                <ImageText>
                                    WE ESTIMATE 40% COST REDUCTION WHEN USING OUR PLATFORM FOR ANY EVENT
                                </ImageText>
                            </ImageTextContainer>
                        </ImageContainer>
                        : null
                }
                <EventsContainer style={{ justifyContent: 'space-between' }}>
                    <FlexColumn>
                        <FlexRowSpaced>
                            <BackButton src={backIcon} alt="Back" onClick={() => { history.replace(Routes.Boarding.date) }} />
                            <ProgressBar>
                                <ProgressFill fill={'80%'}></ProgressFill>
                            </ProgressBar>
                            {
                                !chosenNumOfGuests || chosenNumOfGuests.value.minNumberOfGuest === 0 ?
                                    <NextButton disabled={true} src={backIcon} alt="Next" onClick={(e) => { nextStep(e) }} />
                                    :
                                    <NextContainer onClick={(e) => { nextStep(e) }} >
                                        <NextText>Next</NextText>
                                        <NextButtonOrange src={nextIconOrange} alt="Next" />
                                    </NextContainer>

                            }
                        </FlexRowSpaced>
                        <PageHeader>How many guests?</PageHeader>
                        <PageText>Choose your estimated number, it may change throughout the process and that’s alright</PageText>
                        <PageHeader style={{ margin: 'auto', fontSize: '72px', marginTop: '30px' }}>
                            {chosenNumOfGuests ? chosenNumOfGuests.value.minNumberOfGuest : 0}
                        </PageHeader>
                    </FlexColumn>
                    <GuestsBox style={{overflow:'auto', maxWidth:'80%',maxHeight:'4.5rem', justifyContent: 'center',alignItems:'center'}} >
                        <ReactSlider
                            className="react-slider"
                            min={50}
                            max={1000}
                            step={10}
                            onChange={onSliderChange}
                            thumbClassName="slider-thumb"
                            trackClassName="slider-track"
                            renderThumb={(props, state) => <div {...props} style={{maxWidth:'2rem',maxHeight:'1.7rem',top:'-1.2rem',...props.style}}>{state.valueNow}</div>}
                            
                        />
                    </GuestsBox>
                </EventsContainer>
            </EventsGrid>
            {showPopup ? <GeneralPopup negativeButtonAction={() => { setShowPopup(false) }} >
                <PopupText>Please choose number of guests</PopupText>
                <PopupButton onClick={() => setShowPopup(false)}>Ok</PopupButton>
            </GeneralPopup>
                : null}
        </>);
}

export default EventGuests;