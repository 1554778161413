import React from 'react';
import { FlexRowAligned, FlexRowSpaced, RoundProfile } from '../../../../common/components/CommonStyledComponent';
import { SearchContainer, SearchImg, SearchInput, AddProText, NameText, CategoryText } from '../styles/TeamStyles';
import env from '../../../../config';
import searchIcon from '../../../../assets/icons/search.svg';
import { ReactComponent as SuccessStar } from '../../../../assets/icons/success-star.svg';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

const AddProTab = ({ onProClick, pros, search, setSearch, pro }) => {
    const { width } = useWindowDimensions();

    return (
        <>
            <FlexRowSpaced style={{ marginBottom: '50px' }}>
                <AddProText>Add a Pro</AddProText>
                <SearchContainer>
                    <SearchImg src={searchIcon} alt='Search' />
                    <SearchInput value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' />
                </SearchContainer>
            </FlexRowSpaced>
            {pros &&
                pros.map((member) => {
                    return (
                        <FlexRowSpaced
                            style={{ padding: width > 768 ? '15px' : '15px 0', cursor: 'pointer', borderRadius: '63px', backgroundColor: pro && pro._id === member._id ? '#F3F9FE' : '#fff' }}
                            key={member._id}
                            onClick={() => {
                                onProClick(member);
                            }}>
                            <FlexRowAligned>
                                <div style={{ position: 'relative' }}>
                                    <RoundProfile src={env.apiGateway.PROS_BUCKET_URL + member.avatar} alt='' width={'52px'} height={'52px'} />
                                    {member.occupation === 'success_manager' ? <SuccessStar width={20} height={20} style={{ position: 'absolute', top: '2px', right: '5px' }} /> : null}
                                </div>
                                <NameText clicked={pro && pro._id === member._id ? true : false}>{member.fullName}</NameText>
                                <CategoryText clicked={pro && pro._id === member._id ? true : false}>{member.occupationTitle}</CategoryText>
                            </FlexRowAligned>
                        </FlexRowSpaced>
                    );
                })}
        </>
    );
};

export default AddProTab;
