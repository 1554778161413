import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { ReactComponent as TutorialImage } from '../../../assets/icons/swipetutorial.svg';

import likeIcon from '../../../assets/icons/like.svg';
import dislikeIcon from '../../../assets/icons/dislike.svg';
import { useCheckStep } from '../../../hooks/useCheckStep';
import {
    SwipesGrid, CardContainer, CardQuestion,
    LikeButton, ButtonsContainer, TutorialText, SwipesContainer
} from './SwipesStyles';
import { loadMoreCards, addToLikeList, addToDislikeList, removeCard, completeStep } from '../../../store/reducers/steps/steps-reducer';
import { PopupButton, PopupText } from '../../../common/components/CommonStyledComponent';
import SwipeCard from './components/SwipeCard';
import GeneralPopup from '../../../common/components/GeneralPopup';


const Swipes = () => {
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const swipes = useSelector(state => state.steps.swipes);
    const { cards, hasMore } = useSelector(state => state.steps.swipes);
    const [showPopup, setShowPopup] = useState(true);

    const onNextPage = useCheckStep(swipes);

    useEffect(() => {
        if (!hasMore && cards.length === 0) {
            dispatch(completeStep({ stepName: 'swipes', data: { swipes: swipes } }))
            onNextPage();
        }
    }, [hasMore, cards]);

    const onSwipe = (direction, cardId) => {
        if (direction === 'left') {
            dispatch(addToDislikeList(cardId));
            addToast(<div style={{ textAlign: 'center', width: '100%', fontSize: '30px' }}>👎</div>, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 1000 })
        } else {
            dispatch(addToLikeList(cardId));
            addToast(<div style={{ textAlign: 'center', width: '100%', fontSize: '30px' }}>👍</div>, { appearance: 'success', autoDismiss: true, autoDismissTimeout: 1000 })
        }
    }

    const onCardLeftScreen = (direction, cardId) => {
        onSwipe(direction, cardId);
        dispatch(removeCard())
        dispatch(loadMoreCards(1));
    }

    return (
        <SwipesContainer>
            <SwipesGrid >
                <CardContainer>
                    {
                        cards.map((card, index) => {
                            return <SwipeCard key={index} image={card.image} text={card.text} cardId={card._id} onSwipeCard={onCardLeftScreen} />
                        })
                    }
                </CardContainer>
                <CardQuestion>{cards.length > 0 ? cards[cards.length - 1].text : ''}</CardQuestion>
                <ButtonsContainer>
                    <LikeButton style={{ marginRight: '16px' }} src={dislikeIcon} onClick={() => cards.length > 0 ? onCardLeftScreen('left', cards[cards.length - 1]._id) : null} />
                    <LikeButton src={likeIcon} onClick={() => cards.length > 0 ? onCardLeftScreen('right', cards[cards.length - 1]._id) : null} />
                </ButtonsContainer>
            </SwipesGrid>
            {
                showPopup ?
                    <GeneralPopup negativeButtonAction={() => setShowPopup(false)}>
                        <TutorialImage width={134} height={169} />
                        <TutorialText style={{ marginTop: '10px' }}>Let’s get inspired
                            it’s time to swipe!</TutorialText>
                        <PopupText style={{ margin: '30px 0px' }}>Here comes the exciting part - explore the concepts, choose what you love, and instantly get your personal vision in a short promo video!
                            So are you ready?
                        </PopupText>
                        <PopupButton style={{ marginTop: '0px' }} onClick={() => setShowPopup(false)}>Continue</PopupButton>
                    </GeneralPopup>
                    : null
            }
        </SwipesContainer>
    )
}

export default Swipes;