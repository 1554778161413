import React from 'react'
import { Card, CardImage, CardText } from '../SwipesStyles';
import { Frame, useMotionValue, useTransform, useAnimation } from 'framer';
import env from '../../../../config';

const SwipeCard = ({ image, text, cardId, onSwipeCard, isTutorial }) => {
    // To move the card as the user drags the cursor
    const motionValue = useMotionValue(0);

    // To rotate the card as the card moves on drag
    const rotateValue = useTransform(motionValue, [-200, 200], [-50, 50]);

    // To decrease opacity of the card when swipped
    // on dragging card to left(-200) or right(200)
    // opacity gradually changes to 0
    // and when the card is in center opacity = 1
    const opacityValue = useTransform(
        motionValue,
        [-200, -150, 0, 150, 200],
        [0, 1, 1, 1, 0]
    );

    // Framer animation hook
    const animControls = useAnimation();

    const style = {
        backgroundColor: 'white',
        boxShadow: '3px 4px 8px #888888',
        borderRadius: 20,
        height: '100%',
        width: '100%',
        color: 'white'
    };

    const onDragEnd = (event, info) => {
        let direction = info.offset.x < 0 ? 'left' : 'right';
        // If the card is dragged only upto 150 on x-axis
        // bring it back to initial position
        // console.log(info.point.x);
        if (Math.abs(info.offset.x) <= 150) {
            animControls.start({ x: 0 });
        } else {
            animControls.start({ x: info.offset.x < 0 ? -200 : 200 });
            onSwipeCard(direction, cardId);
        }
    }

    return (
        <Frame
            center
            // Card can be drag only on x-axis
            drag='x'
            x={motionValue}
            rotate={rotateValue}
            opacity={opacityValue}
            animate={animControls}
            dragConstraints={{ left: -1000, right: 1000 }}
            style={style}
            onDragEnd={onDragEnd}>
            <Card >
                <CardImage bg={isTutorial ? image : env.apiGateway.BUCKET_URL + image} />
                <CardText>
                    {text}
                </CardText>
            </Card>
        </Frame>
    );
}

export default SwipeCard;