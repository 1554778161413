import React from 'react';

import eventroLogo from '../../../assets/icons/eventro.svg'

import { PopupButton } from '../../../common/components/CommonStyledComponent';
import { EventroLogo, NewUserBoldText, NewUserContainer, NewUserText } from '../DashboardStyles';

const GuestsPopup = ({ onCloseClick }) => {

    return (
        <NewUserContainer>
            <EventroLogo src={eventroLogo} alt="/" />
            <NewUserBoldText>
                Welcome to the Guests page!
            </NewUserBoldText>
            <NewUserText>
                Once you are ready to send out RSVPs, we will provide a custom made online invitation just for you to share with your friends & family.
                This page will collect all your guests' info after they fill up the RSVP form in the invite.
            </NewUserText>
            <PopupButton onClick={onCloseClick}>Got It</PopupButton>
        </NewUserContainer>
    )

}

export default GuestsPopup;