import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import Routes from '../routes';
import Nav from './NavigationStyles';

import burgerIcon from '../../assets/icons/hamburger.svg';
import xIcon from '../../assets/icons/x.svg';

import { ReactComponent as Logo } from '../../assets/icons/fireworks-color.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/home-icon.svg';
import { ReactComponent as MessagesIcon } from '../../assets/icons/messages-icon.svg';
import { ReactComponent as TeamIcon } from '../../assets/icons/team-icon.svg';
import { ReactComponent as GuestsIcon } from '../../assets/icons/guests-icon.svg';
import { ReactComponent as BudgetIcon } from '../../assets/icons/budget-icon.svg';
import { ReactComponent as FantasiesIcon } from '../../assets/icons/fantasies-icon.svg';

import { BurgerButton, FlexRowCentered } from '../../common/components/CommonStyledComponent';

const DashboardNavigation = () => {
    const { pathname } = useLocation();
    const { user } = useSelector(state => state.auth);
    const [isBurgerClicked, setIsBurgerClicked] = useState(false);

    useEffect(() => {
        if (isBurgerClicked) {
            window.document.body.style.overflow = 'hidden';
        } else {
            window.document.body.style.overflow = 'auto';
        }
    }, [isBurgerClicked]);

    let iconsSize = {
        width: '29',
        height: '25',
    };

    const isWindowForMobile = window.innerWidth <= 768;
    if (isWindowForMobile) {
        iconsSize = {
            width: '20',
            height: '18',
        };
    }

    const closeNavigation = () => {
        setIsBurgerClicked(false);
    };

    return (
        <Nav.Nav isOpen={isBurgerClicked}>
            <Nav.TopNav bg={!isBurgerClicked && isWindowForMobile && pathname === '/dashboard/fantasies' ? 'transparent' : 'white'}>
                {isWindowForMobile ? (
                    isBurgerClicked ? (
                        <BurgerButton
                            clicked={isBurgerClicked}
                            onClick={() => {
                                setIsBurgerClicked(false);
                            }}
                            src={xIcon}
                        />
                    ) : (
                        <BurgerButton
                            clicked={isBurgerClicked}
                            onClick={() => {
                                setIsBurgerClicked(true);
                            }}
                            src={burgerIcon}
                        />
                    )
                ) : null}
                <Nav.NavLogo>
                    <Logo width={'100%'} height={'100%'} fill='#EC5C57' />
                </Nav.NavLogo>
            </Nav.TopNav>
            {

                <Nav.NavBarContainer show={!isWindowForMobile || (isBurgerClicked && isWindowForMobile)} isClicked={isBurgerClicked}>
                    {user.userId === '61c1936989f6ee34823d8874' ? <Nav.HeaderTitle>Libby's Bat Mitzvah</Nav.HeaderTitle> : ''}
                    {user.userId === '61af64dc2f2b4ff9aa51b95d' ? <Nav.HeaderTitle>Rony's Birthday</Nav.HeaderTitle> : ''}
                    <Nav.NavBar>
                        <Nav.NavItem onClick={closeNavigation}>
                            <Nav.NavStyledNavLink exact={true} to={Routes.Dashboard.eventRoom}>
                                <HomeIcon width={iconsSize.width} height={iconsSize.height} fill={pathname === Routes.Dashboard.eventRoom ? '#EC5C57' : '#c6c6c6'} style={{ marginRight: '20px' }} />
                                {'Event Room'}
                            </Nav.NavStyledNavLink>
                        </Nav.NavItem>

                        <Nav.NavItem onClick={closeNavigation}>
                            <Nav.NavStyledNavLink exact={true} to={Routes.Dashboard.team}>
                                <FlexRowCentered>
                                    <TeamIcon width={iconsSize.width} height={iconsSize.height} fill={pathname === Routes.Dashboard.team ? '#EC5C57' : '#c6c6c6'} style={{ marginRight: '20px' }} />
                                    {'A Team'}
                                </FlexRowCentered>
                            </Nav.NavStyledNavLink>
                        </Nav.NavItem>

                        <Nav.NavItem onClick={closeNavigation}>
                            <Nav.NavStyledNavLink exact={true} to={Routes.Dashboard.messages}>
                                <FlexRowCentered>
                                    <MessagesIcon
                                        width={iconsSize.width}
                                        height={iconsSize.height}
                                        fill={pathname === Routes.Dashboard.messages ? '#EC5C57' : '#c6c6c6'}
                                        style={{ marginRight: '20px' }}
                                    />
                                    {'Messages'}
                                </FlexRowCentered>
                            </Nav.NavStyledNavLink>
                        </Nav.NavItem>

                        <Nav.NavItem onClick={closeNavigation}>
                            <Nav.NavStyledNavLink exact={true} to={Routes.Dashboard.budget}>
                                <FlexRowCentered>
                                    <BudgetIcon width={iconsSize.width} height={iconsSize.height} fill={pathname === Routes.Dashboard.budget ? '#EC5C57' : '#c6c6c6'} style={{ marginRight: '20px' }} />
                                    {'Budget'}
                                </FlexRowCentered>
                            </Nav.NavStyledNavLink>
                        </Nav.NavItem>

                        <Nav.NavItem onClick={closeNavigation}>
                            <Nav.NavStyledNavLink exact={true} to={Routes.Dashboard.guests}>
                                <FlexRowCentered>
                                    <GuestsIcon width={iconsSize.width} height={iconsSize.height} fill={pathname === Routes.Dashboard.guests ? '#EC5C57' : '#c6c6c6'} style={{ marginRight: '20px' }} />
                                    {'Guests'}
                                </FlexRowCentered>
                            </Nav.NavStyledNavLink>
                        </Nav.NavItem>

                        <Nav.NavItem onClick={closeNavigation}>
                            <Nav.NavStyledNavLink exact={true} to={Routes.Dashboard.fantasies}>
                                <FlexRowCentered>
                                    <FantasiesIcon
                                        width={iconsSize.width}
                                        height={iconsSize.height}
                                        stroke={pathname === Routes.Dashboard.fantasies ? '#EC5C57' : '#c6c6c6'}
                                        fill={pathname === Routes.Dashboard.fantasies ? '#EC5C57' : '#c6c6c6'}
                                        style={{ marginRight: '20px' }}
                                    />
                                    {'Fantasies'}
                                </FlexRowCentered>
                            </Nav.NavStyledNavLink>
                        </Nav.NavItem>
                    </Nav.NavBar>

                    <Nav.NavControlsContainer>
                        <Nav.NavControlsListItem onClick={closeNavigation} selected={pathname === Routes.Dashboard.settings} exact={true} to={Routes.Dashboard.settings}>
                            {'Settings'}
                        </Nav.NavControlsListItem>
                    </Nav.NavControlsContainer>
                </Nav.NavBarContainer>
            }
        </Nav.Nav>
    );
};

export default withRouter(DashboardNavigation);
