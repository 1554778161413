import React from 'react';

import eventroLogo from '../../../assets/icons/eventro.svg'

import { PopupButton } from '../../../common/components/CommonStyledComponent';
import { EventroLogo, NewUserBoldText, NewUserContainer, NewUserText } from '../DashboardStyles';
import { PopupTextContainer } from './SettingsStyles';

const AboutUsPopup = ({ onCloseClick }) => {
    const isWindowForMobile = window.innerWidth <= 768;

    return (
        <NewUserContainer style={{ padding: isWindowForMobile ? '10px' : ' 0px 40px' }}>
            <EventroLogo src={eventroLogo} alt="/" />
            <NewUserBoldText>
                About us
            </NewUserBoldText>
            <PopupTextContainer mobile={isWindowForMobile}>
                <NewUserText>
                    Eventro was born out of the love and passion for the events industry - creating, planning and designing celebrations worldwide.
                </NewUserText>
                <NewUserText style={{ marginTop: '10px' }}>
                    We have a vision - pushing the boundaries of the event production world forward to today’s trends. We are making the process simpler from start to end with digital tools available on your mobile.
                    The platform is adjusted to the new generation of event dreamers - it is innovative technology that combines inspiration, fantasy and efficiency - blurring the boundaries between digital and physical space.
                </NewUserText>
                <NewUserText style={{ marginTop: '10px' }}>
                    We at Eventro can’t wait to see your creation of the upcoming event!
                    As people of events, who live and breath celebrations and their production, we want to make sure that the experience is unforgettable and the final result is a success with a spark!
                </NewUserText>
            </PopupTextContainer>
            <PopupButton onClick={onCloseClick}>Got It</PopupButton>
        </NewUserContainer>
    )

}

export default AboutUsPopup;