import { createSlice } from '@reduxjs/toolkit';
import eventsApi from '../../api/events-api';

const initialState = {
    userEvent: null,
    error: null,
    loading: false
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _loadUserEventSuccess = (state, action) => {
    const data = action.payload;
    state.loading = false;
    state.userEvent = data;
};


const _cleanUserEvent = (state) => {
    return initialState;
};

const _checkProgress = (state, action) => {
    const { progressId, checked } = action.payload;
    state.userEvent.progress = state.userEvent.progress.map((prog) => {
        if (prog._id === progressId) {
            return { ...prog, checked: checked }
        }
        return prog;
    });
}

const _updateInvitationLink = (state, action) => {
    const inviteLink = action.payload;
    state.userEvent.whatsAppLink = inviteLink;
}

const userEvent = createSlice({
    name: 'userEvent',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        loadUserEventSuccess: _loadUserEventSuccess,
        cleanUserEvent: _cleanUserEvent,
        checkProgress: _checkProgress,
        updateInvitationLink: _updateInvitationLink
    },
});

const { actions, reducer } = userEvent;

export const {
    loadStart,
    loadFailed,
    loadUserEventSuccess,
    cleanUserEvent,
    checkProgress,
    updateInvitationLink
} = actions;

export default reducer;

export const loadUserEvent = () => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await eventsApi.getCurrentEvent();
        if (status === 200) {
            return dispatch(loadUserEventSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};

export const markChecklist = (eventId, progressId, checked) => {
    return async (dispatch) => {
        const { status } = await eventsApi.updateProgress(eventId, progressId, checked);
        if (status === 200) {
            return dispatch(checkProgress({ progressId, checked }));
        }
    };
}