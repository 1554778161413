import React from 'react';
import { FlexRowSpaced } from '../../../../common/components/CommonStyledComponent';
import { PopupContainer, PageTitle } from '../styles/PicturesPopupStyles';
import env from '../../../../config';
import { Document, Page, pdfjs } from 'react-pdf';

import { ReactComponent as LeftArrowIcon } from '../../../../assets/icons/left-arrow.svg';
import Loader from '../../../../common/components/Loader';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentsPopup = ({ onBackClick, name, docs }) => {

    return (
        <PopupContainer>
            <FlexRowSpaced>
                <LeftArrowIcon
                    width={20}
                    height={18}
                    fill={'#22222'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        onBackClick();
                    }}
                />
                <PageTitle>{name + ' Document'}</PageTitle>
                <div></div>
            </FlexRowSpaced>
            <div style={{ width: '100%', height: '100%' }}>
                <Document loading={<Loader />} file={env.apiGateway.PROS_BUCKET_URL + docs[0]}>
                    <Page pageNumber={1} />
                </Document>
            </div>
        </PopupContainer>
    );
};

export default DocumentsPopup;
