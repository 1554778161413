import React, { useState } from "react";

import { ReactComponent as ShareIcon } from "../../../../assets/icons/share.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-x.svg";

import { formatDate } from "../../../../common/utils/date-utils";
import {
  FlexColumn,
  FlexRow,
  Tooltip,
  WhiteOrangeButton,
} from "../../../../common/components/CommonStyledComponent";
import {
  BoxTitle,
  ImgDiv,
  ShareText,
  EventDetailsContainer,
  EventDetailsTitle,
  EventDetailsHeader,
  EventHeadersContainer,
  HeaderContainer,
  EventDetailsText,
} from "../EventRoomStyles";
import { getEventNameByType } from "../../../../common/utils/utils";
import { Link } from "react-router-dom";

const ShareBox = ({ image, event, link,isMobile }) => {

  const [showTooltip, setShowTooltip] = useState(false);
  const [showEventDetails, setShowEventDetails] = useState(false);

  const onShareClick = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(
      window.origin.toString() + "/share-event?event=" + link
    );
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };



  return showEventDetails ? (
    <EventDetailsContainer>
      <CloseIcon
        onClick={() => {
          setShowEventDetails(false);
        }}
        style={{
          position: "absolute",
          top: "10",
          left: "10",
          cursor: "pointer",
        }}
      />
      <EventDetailsTitle>Event Details</EventDetailsTitle>
      <FlexColumn
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <EventHeadersContainer>
          <FlexColumn>
            <HeaderContainer>
              <EventDetailsHeader>Where</EventDetailsHeader>
              <EventDetailsText>{event.location.name}</EventDetailsText>
            </HeaderContainer>
            <HeaderContainer>
              <EventDetailsHeader>What</EventDetailsHeader>

              <EventDetailsText style={{ textTransform: "capitalize" }}>
                {getEventNameByType(event.eventType)}
              </EventDetailsText>
            </HeaderContainer>
          </FlexColumn>
          <FlexColumn>
            <HeaderContainer>
              <EventDetailsHeader>When</EventDetailsHeader>
              <EventDetailsText>{formatDate(event.date)}</EventDetailsText>
            </HeaderContainer>
            <HeaderContainer>
              <EventDetailsHeader>Guests</EventDetailsHeader>
              <EventDetailsText>{event.minNumberOfGuests}</EventDetailsText>
            </HeaderContainer>
          </FlexColumn>
        </EventHeadersContainer>
        {!isMobile && (
         <Link  style={{  textDecoration:'none'  }} to='/dashboard/fantasies'> <WhiteOrangeButton
            style={{
              width: "11vw",
              maxWidth: "200px",
              height: "6vh",
              maxHeight: "72px",
              bottom: "2px",
              direction:'none'
            }}
          >
              
              See Video
          </WhiteOrangeButton></Link>
        )}
      </FlexColumn>
    </EventDetailsContainer>
  ) : (
    <ImgDiv
      onClick={() => {
        setShowEventDetails(true);
      }}
      bg={image}
    >
      <BoxTitle>EVENT DETAILS</BoxTitle>
      <FlexRow
        onClick={(e) => {
          onShareClick(e);
        }}
        style={{
          cursor: "pointer",
          alignItems: "center",
          position: "absolute",
          bottom: 30,
        }}
      >
        <ShareIcon
          width={16}
          height={16}
          style={{ marginRight: "10px", fill: "white" }}
        />
        <ShareText>Share</ShareText>
        <Tooltip show={showTooltip}>Link Copied!</Tooltip>
      </FlexRow>
    </ImgDiv>
  );
};

export default ShareBox;
