import axiosInstance from '../../axios';
import ServerRoutes from './routes/index';
import { errorSchema, updateResponseSchema } from './schemas/common-schemas';
import { eventsSchema, sharedEventSchema, userEventSchema } from './schemas/events-schemas';
import { validate } from './schemas/validator';

const fetchEvents = async (eventsData) => {
    try {
        let url = ServerRoutes.prepareEvents;

        const { status, data, error } = await axiosInstance.post(url, eventsData);
        const { result, e, s } = await validate(eventsSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    }
    catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Events were not generated';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
}

const pickEvent = async (eventData) => {
    try {
        let url = ServerRoutes.pickEvent;

        const { status, data, error } = await axiosInstance.post(url, eventData);
        const { result, e, s } = await validate(updateResponseSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    }
    catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Failed to pick an event';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
}

const saveEvent = async (eventData) => {
    try {
        let url = ServerRoutes.saveEvent;

        const { status, data, error } = await axiosInstance.post(url, eventData);
        const { result, e, s } = await validate(userEventSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    }
    catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Failed to save the event';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
}

const getCurrentEvent = async (eventData) => {
    try {
        let url = ServerRoutes.currentEvent;

        const { status, data, error } = await axiosInstance.get(url);
        const { result, e, s } = await validate(userEventSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    }
    catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Failed to get current event';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
}

const updateProgress = async (eventId, progressId, checked) => {
    try {
        let url = ServerRoutes.updateProgress + `?eventId=${eventId}`;

        let progressData = {
            progressId,
            checked
        }

        const { status, data, error } = await axiosInstance.post(url, progressData);
        const { result, e, s } = await validate(updateResponseSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    }
    catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Failed to update event progress';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
}

const getShareEvent = async (eventUUID) => {
    try {
        let url = ServerRoutes.sharedEvent + `?uuid=${eventUUID}`;

        const { status, data, error } = await axiosInstance.get(url);
        const { result, e, s } = await validate(sharedEventSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    }
    catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Failed to get current event';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
}

const pollVideos = async (videoIds) => {
    try {
        let url = ServerRoutes.videoPolls + `?videoIds=${videoIds.join(',')}`;

        const { status, data, error } = await axiosInstance.get(url);
        const { result, e, s } = await validate(sharedEventSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    }
    catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Failed to poll event videos';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
}

const eventsApi = {
    fetchEvents: fetchEvents,
    pickEvent: pickEvent,
    saveEvent: saveEvent,
    getCurrentEvent: getCurrentEvent,
    updateProgress: updateProgress,
    getShareEvent: getShareEvent,
    pollVideos: pollVideos
}

export default eventsApi;