import axiosInstance from '../../axios';
import ServerRoutes from './routes/index';
import { errorSchema } from './schemas/common-schemas';
import { locationsArraySchema } from './schemas/locations-schemas';
import { validate } from './schemas/validator';

const fetchLocations = async (page, limit, search) => {
    try {
        const headers = {
            'f-page': page,
            'f-limit': limit,
        }

        let url = ServerRoutes.locations;

        if (search) {
            url = url + `&name=${search}`;
        }

        const { status, data, error } = await axiosInstance.get(url, { headers: headers });
        const { result, e, s } = await validate(locationsArraySchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    }
    catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Locations were not found';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
}

const locationsApi = {
    fetchLocations: fetchLocations,
}

export default locationsApi;