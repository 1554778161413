import axiosInstance from '../../axios';
import ServerRoutes from './routes/index';
import { errorSchema, updateResponseSchema } from './schemas/common-schemas';
import { teamSchema, proSchema, prosSchema } from './schemas/teams-schemas';
import { validate } from './schemas/validator';

const fetchTeam = async (userEventId) => {
    try {
        let url = ServerRoutes.getTeam.replace(':id', userEventId);
        const { status, data, error } = await axiosInstance.get(url);
        const { result, e, s } = await validate(teamSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Team were not generated';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status,
        };
    }
};

const fetchMember = async (proId) => {
    try {
        let url = ServerRoutes.getMember.replace(':id', proId);
        const { status, data, error } = await axiosInstance.get(url);
        const { result, e, s } = await validate(proSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Pro were not generated';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status,
        };
    }
};

const deleteMember = async (userEventId, proId) => {
    try {
        let url = ServerRoutes.deleteMember.replace(':id', userEventId).replace(':proId', proId);
        const { status, data, error } = await axiosInstance.delete(url);
        const { result, e, s } = await validate(updateResponseSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Member were not generated';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status,
        };
    }
};

const addMember = async (userEventId, proId) => {
    try {
        let url = ServerRoutes.addMember.replace(':id', userEventId).replace(':proId', proId);
        const { status, data, error } = await axiosInstance.get(url);
        const { result, e, s } = await validate(updateResponseSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Member were not generated';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status,
        };
    }
};

const fetchPros = async (search) => {
    try {
        let url = ServerRoutes.getPros;
        if (search && search !== '') {
            url = ServerRoutes.getPros + '?name=' + search;
        }
        const { status, data, error } = await axiosInstance.get(url);
        const { result, e, s } = await validate(prosSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Pros were not generated';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status,
        };
    }
};

const teamsApi = {
    fetchTeam: fetchTeam,
    fetchMember: fetchMember,
    deleteMember: deleteMember,
    addMember: addMember,
    fetchPros: fetchPros,
};

export default teamsApi;
