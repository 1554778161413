import { createSlice } from '@reduxjs/toolkit';
import budgetApi from '../../api/budget-api';

const initialState = {
    list: [],
    totalPrice: 0,
    error: null,
    loading: false,
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _loadBudgetSuccess = (state, action) => {
    const { list, totalPrice } = action.payload;
    state.loading = false;
    state.list = list;
    state.totalPrice = totalPrice;
};

const _cleanBudget = (state) => {
    return initialState;
};

const budget = createSlice({
    name: 'budget',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        loadBudgetSuccess: _loadBudgetSuccess,
        cleanBudget: _cleanBudget,
    },
});

const { actions, reducer } = budget;

export const { loadStart, loadFailed, loadBudgetSuccess, cleanBudget } = actions;

export default reducer;

export const loadBudget = (userEventId) => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await budgetApi.fetchBudget(userEventId);
        if (status === 200) {
            return dispatch(loadBudgetSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};
