import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as _ from 'lodash';
import { FlexRowCentered } from './CommonStyledComponent';

const InfinityScroll = ({ resetScrollId, maxHeight, children, threshold, loading, hasMore, loadMore, onMessage, loadedMoreHash }) => {
	const elemntRef = useRef();
	const timeoutRef = useRef();
	const timeoutRef2 = useRef();

	const [firstTime, setFirstTime] = useState(true);
	const [lastPosition, setLastPosition] = useState(null);

	useEffect(() => {
		if (!loading && elemntRef.current) {
			if (firstTime && hasMore && elemntRef.current.scrollTop === 0) {
				timeoutRef.current = setTimeout(() => {
					elemntRef.current.scrollTop = elemntRef.current.scrollHeight;
				}, 1000)
				setFirstTime(false);
			}
			elemntRef.current.onscroll = _.debounce(handleScroll, 200);
		}
		return () => {
			if (elemntRef.current) {
				elemntRef.current.onscroll = null;
			}
		}
	}, [loading, elemntRef.current]);

	useEffect(() => {
		if (onMessage) {
			if (elemntRef.current.scrollHeight - Math.round(elemntRef.current.scrollTop) < elemntRef.current.clientHeight + 400) {
				timeoutRef2.current = setTimeout(() => {
					elemntRef.current.scrollTop = elemntRef.current.scrollHeight;
				}, 1000)
			}
		}

	}, [onMessage]);

	useEffect(() => {
		if (loadedMoreHash) {
			elemntRef.current.scrollTop = elemntRef.current.scrollHeight - lastPosition;
		}
	}, [loadedMoreHash]);

	useEffect(() => {
		setTimeout(() => {
			elemntRef.current.scrollTop = elemntRef.current.scrollHeight;
		}, 1000);
	}, [resetScrollId])

	useEffect(() => {
		return () => {
			clearTimeout(timeoutRef.current);
			clearTimeout(timeoutRef2.current);
		}
	}, [])

	const handleScroll = (e) => {
		if (!hasMore || loading) return;
		const scrollTop = e.srcElement.scrollTop;
		if (scrollTop <= threshold) {
			loadMore();
			setLastPosition(elemntRef.current.scrollHeight - Math.round(elemntRef.current.scrollTop));
		}
	}

	return (
		<InfinityScrollContainer height={maxHeight} ref={elemntRef}>
			{loading ?
				<FlexRowCentered style={{ padding: '25px 0' }}>
					<div style={{ padding: '0 10px', color: '#222222' }}>Loading...</div>
				</FlexRowCentered> : null}
			{children}
		</InfinityScrollContainer>
	)
}

export default InfinityScroll;

InfinityScroll.propTypes = {
	maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
	threshold: PropTypes.number,
	loading: PropTypes.bool,
	hasMore: PropTypes.bool,
	loadMore: PropTypes.func
}

InfinityScroll.defaultProps = {
	threshold: 20,
	hasMore: false,
	loadMore: () => { }
}

const InfinityScrollContainer = styled.div`
	height: ${props => props.height ? props.height : '100%'};
	overflow: auto;
	display: flex;
	flex-direction: column;
`;