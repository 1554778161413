import { string, object, array, number, boolean } from 'yup';

export const saveAnswersSchema = object().shape({
    _id: string(),
    locationIds: array().of(string()),
    liked: array().of(string()),
    disliked: array().of(string()),
    uuid: string(),
    minNumberOfGuests: number(),
    maxNumberOfGuest: number(),
    eventType: string(),
    date: string(),
    dateOnlyMonth: boolean(),
});
