import axiosInstance from '../../axios';
import ServerRoutes from './routes/index';
import { errorSchema } from './schemas/common-schemas';
import { questionsSchema } from './schemas/events-schemas';
import { validate } from './schemas/validator';

const fetchMoreCards = async (cardsData) => {
    try {
        let url = ServerRoutes.getNextQuestion;

        const { status, data, error } = await axiosInstance.post(url, cardsData);
        const { result, e, s } = await validate(questionsSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    }
    catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'cards were not found';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
}

const cardsApi = {
    fetchMoreCards: fetchMoreCards,
}

export default cardsApi;