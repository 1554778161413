import styled from 'styled-components';

export const GuestsHeader = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PageTitle = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 43px;
    line-height: 70px;

    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 24px;
        margin-top: 10px;
    }
`;

export const Text = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;
`;

export const SearchImg = styled.img`
    width: 22px;
    height: 22px;
    margin-right: 10px;

    @media (max-width: 768px) {
        font-size: 24px;
        align-self: center;
    }
`;

export const SearchInput = styled.input`
    color: var(--black);
    font-size: 32px;
    font-family: 'Poppins';
    font-weight: 400;
    padding-left: 16px;
    height: 42px;
    border: none;
    outline: none;

    &::placeholder {
        color: var(--black);
        opacity: 0.3;
    }

    @media (max-width: 768px) {
        font-size: 24px;
        width: 60%;
        height: auto;
    }
`;

export const AttendingStyle = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    margin-left: 6px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

export const NameStyle = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin: 0 20px;
`;

export const NoteStyle = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: var(--black);
    width: 20%;

    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 15px 0 42px;
        box-sizing: border-box;
    }
`;

export const EmailStyle = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: var(--black);
    width: 22%;

    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 15px 0 42px;
        box-sizing: border-box;
    }
`;

export const GuestContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const ListContainer = styled.div`
    height: calc(100% - 189px);
    box-sizing: border-box;
    padding-bottom: 20px;
    overflow: auto;

    @media (max-width: 768px) {
        height: 100%;
        padding-bottom: 0;
    }
`;

export const TabContainer = styled.div`
    margin: 30px 0;
    width: 100%;
    box-sizing: border-box;
`;

export const GuestsLayout = styled.div`
    width: 100%;
    height: 100%;
    padding: 60px 60px 30px 30px;
    box-sizing: border-box;
    overflow: hidden;

    @media (max-width: 768px) {
        padding: 0px 20px 20px 20px;
        overflow: auto;
        margin-top: 60px;
        box-sizing: border-box;
    }
`;

export const ColorsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 380px;
    
    @media (max-width: 768px) {
        margin-top: 20px;
        width: 100%;
        min-width: auto;
    }
`;

export const Circle = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 11px;
    background-color: ${(props) => (props.color ? props.color : 'var(--gray)')};
`;

export const ColorNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30%;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 30px;
    width: 60%;
    height: 105px;
    border: 2px solid #f2f2f2;
    box-sizing: border-box;
    border-radius: 16px;

    @media (max-width: 768px) {
        display: ${(props) => (props.showLinkContent ? 'flex' : 'none')};
        width: 100%;
        padding: 15px;
        height: 150px;
        flex-direction: column;
    }
`;

export const SubmitButtonWrapper = styled.div`
    width: 155px;
    height: 50px;

    @media (max-width: 768px) {
        width: 110px;
        height: 40px;
    }
`;

export const CloseContainer = styled.div`
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        cursor: pointer;
    }

    @media (max-width: 768px) {
        display: flex;
    }
`;

export const MobileLinkContentButton = styled.div`
    display: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: var(--black);
    margin-top: 20px;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        cursor: pointer;
    }

    @media (max-width: 768px) {
        display: ${(props) => (props.showLinkContent ? 'none' : 'block')};
    }
`;

export const LinkContentInput = styled.input`
    color: var(--black);
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    border: none;
    outline: none;
    width: 70%;

    &::placeholder {
        color: var(--black);
        opacity: 0.4;
    }

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        margin-top: 15px;
    }
`;

export const SearchAndColorsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`;
