import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import ProTab from './components/ProTab';
import TeamTab from './components/TeamTab';
import AddProTab from './components/AddProTab';
import { TeamContainer, TeamLayout, Seperator, PageTitle, StyleHelper } from './styles/TeamStyles';
import { loadTeam, cleanTeam, deleteMember, addMember } from '../../../store/reducers/teams/team-reducer';
import { cleanPro, loadMember } from '../../../store/reducers/teams/pro-reducer';
import { cleanPros, loadPros } from '../../../store/reducers/teams/pros-reducer';
import PicturesPopup from './components/PicturesPopup';
import Loader from '../../../common/components/Loader';
import DocumentsPopup from './components/DocumentsPopup';

const Team = () => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { userEvent } = useSelector((state) => state.userEvent);
    const { team, loading } = useSelector((state) => state.team);
    const { pro } = useSelector((state) => state.pro);
    const { pros } = useSelector((state) => state.pros);
    const [search, setSearch] = useState('');
    const [docs, setDocs] = useState();
    const [showPicturesPopup, setShowPicturesPopup] = useState(false);
    const [showDocumentsPopup, setShowDocumentsPopup] = useState(false);

    useEffect(() => {
        dispatch(loadTeam(userEvent._id));

        return () => {
            dispatch(cleanTeam());
            dispatch(cleanPro());
            dispatch(cleanPros());
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(loadPros(search));
        }, 300);
        return () => {
            clearTimeout(timer);
        };
    }, [search]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const onProClick = (member) => {
        scrollToTop();
        dispatch(loadMember(member._id));
    };

    const onDeleteClick = (member) => {
        if (pro && member._id === pro._id) {
            dispatch(cleanPro());
        }
        dispatch(deleteMember(userEvent._id, member._id)).then((data) => {
            if (data) {
                dispatch(loadTeam(userEvent._id));
                addToast('Pro removed successfully', { appearance: 'success', autoDismiss: true })
            }
        });
    };

    const onAddClick = (member) => {
        dispatch(addMember(userEvent._id, member._id)).then((data) => {
            if (data) {
                addToast('Pro added successfully', { appearance: 'success', autoDismiss: true })
                dispatch(loadTeam(userEvent._id));
            }
        });
    };

    const onPicClick = () => {
        scrollToTop();
        setShowPicturesPopup(true);
    };

    const onDocClick = (docs) => {
        scrollToTop();
        setDocs(docs);
        setShowDocumentsPopup(true);
    };

    const onProBackClick = () => {
        dispatch(cleanPro());
    };

    const onPictureBackClick = () => {
        setShowPicturesPopup(false);
    };

    const onDocumentsBackClick = () => {
        setShowDocumentsPopup(false);
    };

    return (
        <TeamLayout>
            {loading ? <Loader /> : null}
            <TeamContainer hide={pro !== null}>
                <PageTitle>A Team</PageTitle>
                <StyleHelper>
                    <TeamTab onProClick={onProClick} onDeleteClick={onDeleteClick} team={team} pro={pro} />
                    <Seperator></Seperator>
                    <AddProTab onProClick={onProClick} search={search} setSearch={setSearch} pros={pros} pro={pro} />
                </StyleHelper>
            </TeamContainer>
            <ProTab pro={pro} onAddClick={onAddClick} onPicClick={onPicClick} onDocClick={onDocClick} onBackClick={onProBackClick} team={team} />
            {showPicturesPopup ? <PicturesPopup name={pro ? pro.fullName : ''} pictures={pro ? pro.images : []} onBackClick={onPictureBackClick} /> : null}
            {showDocumentsPopup ? <DocumentsPopup name={pro ? pro.fullName : ''} docs={docs ? docs : []} onBackClick={onDocumentsBackClick} /> : null}
        </TeamLayout>
    );
};

export default Team;
