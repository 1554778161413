import React, { useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import infoLogo from "../../../assets/icons/info.svg";
import smokeBackground from "../../../assets/images/smoke.png";
import candiesBackground from "../../../assets/images/libby.png";

import BudgetBox from "./components/BudgetBox";
import GuestBox from "./components/GuestBox";
import ShareBox from "./components/ShareBox";
import TeamBox from "./components/TeamBox";
import Routes from "../../routes/index";
import { Box, BoxesGrid, EventRoomLayout, InfoLogo } from "./EventRoomStyles";
import VerticalProgress from "./components/VerticalProgress";
import HorizontalProgress from "./components/HorizontalProgress";
import Loader from "../../../common/components/Loader";
import InfoContent from "./components/InfoContent";
import GeneralPopup from "../../../common/components/GeneralPopup";
import {
  WhiteOrangeButton,
  FlexRow,
} from "../../../common/components/CommonStyledComponent";
import { Link } from "react-router-dom";

const informations = {
  share: {
    label: "SHARE",
    text: "let’s not forget that video you just put together with your own personal vision! View it at any time - and easily share it!",
  },
  budget: {
    label: "BUDGET",
    text: "Get an initial quote of the predicted budget according to the results of your personal vision now",
  },
  guests: {
    label: "GUESTS",
    text: "Access this extremely user friendly tool for managing your guestlist and invitations.",
  },
  team: {
    label: "A TEAM",
    text: "Over here you can review all your vendors that fit your needs for the event, you can add, remove or change them.",
  },
  checklist: {
    label: "CHECKLIST",
    text: "Have a look at what we’ve prepared for you - your to do list! You can go ahead and check the first box",
  },
};

const EventRoom = () => {
  const history = useHistory();
  const { userEvent, loading } = useSelector((state) => state.userEvent);
  const { user } = useSelector((state) => state.auth);
  const [infoText, setInfoText] = useState(null);

  const isWindowForMobile = window.innerWidth <= 768;

  // TOTO Hard coded bakground images - remove in future
  let eventBg = "";
  if (user.userId === "61af64dc2f2b4ff9aa51b95d") {
    eventBg = smokeBackground;
  } else if (user.userId === "61c1936989f6ee34823d8874") {
    eventBg = candiesBackground;
  }

  let infoContent = null;
  if (infoText) {
    infoContent = (
      <InfoContent
        onButtonClick={() => {
          setInfoText(null);
        }}
        label={infoText.label}
        text={infoText.text}
      />
    );
  }

  const openInfoPopup = (e, infoText) => {
    e.stopPropagation();
    setInfoText(infoText);
  };

  return (
    <EventRoomLayout bg={eventBg}>
      {loading ? <Loader /> : null}
      {isWindowForMobile ? (
        <HorizontalProgress
          eventId={userEvent._id}
          progress={userEvent.progress}
        />
      ) : null}
      <BoxesGrid>
        <Box>
          <InfoLogo
            src={infoLogo}
            alt="/"
            onClick={(e) => openInfoPopup(e, informations.share)}
          />
          <ShareBox
            image={userEvent?.event.imageLink}
            event={userEvent}
            link={userEvent?.uuid}
            isMobile={isWindowForMobile}
          />
        </Box>
        <Box point onClick={() => history.push(Routes.Dashboard.budget)}>
          <InfoLogo
            src={infoLogo}
            alt="/"
            onClick={(e) => openInfoPopup(e, informations.budget)}
          />
          <BudgetBox eventId={userEvent._id} />
        </Box>
        <Box point onClick={() => history.push(Routes.Dashboard.guests)}>
          <InfoLogo
            src={infoLogo}
            alt="/"
            onClick={(e) => openInfoPopup(e, informations.guests)}
          />
          <GuestBox eventId={userEvent._id} />
        </Box>
        <Box point onClick={() => history.push(Routes.Dashboard.team)}>
          <InfoLogo
            src={infoLogo}
            alt="/"
            onClick={(e) => openInfoPopup(e, informations.team)}
          />
          <TeamBox team={userEvent.team} />
        </Box>
      </BoxesGrid>
      {isWindowForMobile && (
        <FlexRow
          style={{
            marginTop: "2rem",
            flexGrow: 2,
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Link style={{ textDecoration: "none" }} to="/dashboard/fantasies">
            <WhiteOrangeButton>See Video </WhiteOrangeButton>
          </Link>
        </FlexRow>
      )}
      {infoText ? (
        <GeneralPopup
          negativeButtonAction={() => {
            setInfoText(null);
          }}
        >
          {infoContent}
        </GeneralPopup>
      ) : null}
      {!isWindowForMobile ? (
        <VerticalProgress
          onInfoClick={(e) => openInfoPopup(e, informations.checklist)}
          eventId={userEvent._id}
          progress={userEvent.progress}
        />
      ) : null}
    </EventRoomLayout>
  );
};

export default EventRoom;
