import axiosInstance from '../../axios';
import ServerRoutes from './routes/index';
import { errorSchema, updateResponseSchema } from './schemas/common-schemas';
import { guestsSchema, attendingSchema } from './schemas/guests-schemas';
import { validate } from './schemas/validator';

const fetchGuests = async (userEventId, search) => {
    try {
        let url = ServerRoutes.getGuests.replace(':id', userEventId);
        if (search && search !== '') {
            url = ServerRoutes.getGuests.replace(':id', userEventId) + '?name=' + search;
        }
        const { status, data, error } = await axiosInstance.get(url);
        const { result, e, s } = await validate(guestsSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
        // return { data: myData, status: 200, error: null };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Guests were not generated';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status,
        };
    }
};

const fetchAttending = async (userEventId) => {
    try {
        let url = ServerRoutes.getAttending.replace(':id', userEventId);
        const { status, data, error } = await axiosInstance.get(url);
        const { result, e, s } = await validate(attendingSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Attending were not generated';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status,
        };
    }
};

const sendLink = async (link, eventId) => {
    try {

        let body = {
            whatsAppLink: link
        }

        let url = ServerRoutes.sendLink + `?eventId=${eventId}`;
        const { status, data, error } = await axiosInstance.post(url, body);
        const { result, e, s } = await validate(updateResponseSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Could not send link';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status,
        };
    }
};

const guestsApi = {
    fetchGuests: fetchGuests,
    fetchAttending: fetchAttending,
    sendLink: sendLink
};

export default guestsApi;
