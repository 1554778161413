import { createSlice } from '@reduxjs/toolkit';
import teamsApi from '../../api/teams-api';

const initialState = {
    pros: [],
    total: 0,
    error: null,
    loading: false,
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _loadProsSuccess = (state, action) => {
    const { data, metadata } = action.payload;
    state.loading = false;
    state.pros = data;
    state.total = metadata.total;
};

const _cleanPros = (state) => {
    return initialState;
};

const pros = createSlice({
    name: 'pros',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        loadProsSuccess: _loadProsSuccess,
        cleanPros: _cleanPros,
    },
});

const { actions, reducer } = pros;

export const { loadStart, loadFailed, loadProsSuccess, cleanPros } = actions;

export default reducer;

export const loadPros = (search) => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await teamsApi.fetchPros(search);
        if (status === 200) {
            return dispatch(loadProsSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};
