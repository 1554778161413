import styled from 'styled-components';

export const ToastContainer = styled.div`
    z-index: 9000;
    box-sizing: border-box;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 8px;
    pointer-events: all;
    position: fixed;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
`;
