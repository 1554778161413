import { createSlice } from '@reduxjs/toolkit';
import eventsApi from '../../api/events-api';

const initialState = {
    event: null,
    error: null,
    loading: false
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _loadShareEventSuccess = (state, action) => {
    const data = action.payload;
    state.loading = false;
    state.event = data;
};


const _cleanShareEvent = (state) => {
    return initialState;
};

const shareEvent = createSlice({
    name: 'shareEvent',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        loadShareEventSuccess: _loadShareEventSuccess,
        cleanShareEvent: _cleanShareEvent,
    },
});

const { actions, reducer } = shareEvent;

export const {
    loadStart,
    loadFailed,
    loadShareEventSuccess,
    cleanShareEvent,
} = actions;

export default reducer;

export const loadShareEvent = (eventUUID) => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await eventsApi.getShareEvent(eventUUID);
        if (status === 200) {
            return dispatch(loadShareEventSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};