import React from 'react';
import { FlexRowAligned, RoundProfile } from '../../../../common/components/CommonStyledComponent';
import { TeamTabContainer, NameText, CategoryText, DeleteContainer } from '../styles/TeamStyles';
import env from '../../../../config';

import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as SuccessStar } from '../../../../assets/icons/success-star.svg';

const TeamTab = ({ onProClick, onDeleteClick, team, pro }) => {
    return (
        team &&
        team.map((member) => {
            return (
                <TeamTabContainer clicked={pro && pro._id === member._id ? true : false} key={member._id}>
                    <FlexRowAligned
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            onProClick(member);
                        }}>
                        <div style={{ position: 'relative' }}>
                            <RoundProfile src={env.apiGateway.PROS_BUCKET_URL + member.avatar} alt='' width={'52px'} height={'52px'} />
                            {member.occupation === 'success_manager' ? <SuccessStar width={20} height={20} style={{ position: 'absolute', top: '2px', right: '5px' }} /> : null}
                        </div>
                        <NameText clicked={pro && pro._id === member._id ? true : false}>{member.fullName}</NameText>
                        <CategoryText clicked={pro && pro._id === member._id ? true : false}>{member.occupationTitle}</CategoryText>
                    </FlexRowAligned>
                    <DeleteContainer
                        onClick={() => {
                            onDeleteClick(member);
                        }}>
                        <DeleteIcon width={'16'} height={'16'} fill={'#222222'} />
                    </DeleteContainer>
                </TeamTabContainer>
            );
        })
    );
};

export default TeamTab;
