import { string, object, array, boolean, number } from 'yup';
import { metadataSchema } from './common-schemas';

export const eventTypeSchema = object().shape({
    _id: string().required(),
    name: string(),
    type: string(),
    updatedAt: string(),
    createdAt: string(),

});

export const eventTypesArraySchema = object().shape({
    data: array().of(eventTypeSchema).default([]),
    metadata: metadataSchema
});

const questionSchema = object().shape({
    _id: string(),
    text: string(),
    image: string()
})

export const questionsSchema = object().shape({
    hasMore: boolean(),
    nextQuestion: array().of(questionSchema).default([])
})


const teamSchema = object().shape({
    avatar: string().nullable(true),
    _id: string(),
    fullName: string()
});

const progressSchema = object().shape({
    _id: string(),
    description: string(),
    title: string(),
    task: string(),
    checked: boolean()
})

const themeSchema = object().shape({
    backgroundColor: string(),
});

const videoDetailsSchema = object().shape({
    videoUrl: string().nullable(true),
    videoState: string(),
    videoId: string(),
});

const eventSchema = object().shape({
    createdAt: string(),
    description: string(),
    name: string(),
    theme: themeSchema,
    uuid: string(),
    venue: string(),
    videoDetails: videoDetailsSchema,
    _id: string(),

    // videoLink: string(),
    // imageLink: string(),
    // templateId: string(),
    team: array().of(string()),
    progress: array().of(progressSchema),
    updatedAt: string()
})

export const eventsSchema = object().shape({
    events: array().of(eventSchema).default([])
});

const eventLocationSchema = object().shape({
    country: string().nullable(true),
    name: string().nullable(true),
    region: string().nullable(true),
    state: string().nullable(true),
})

export const userEventSchema = object().shape({
    team: array().of(teamSchema),
    active: boolean(),
    date: string().nullable(true),
    dateOnlyMonth: boolean().nullable(true),
    _id: string(),
    progress: array().of(progressSchema),
    location: eventLocationSchema.nullable(true),
    event: eventSchema,
    minNumberOfGuests: number(),
    maxNumberOfGuests: number(),
    user: string(),
    whatsAppLink: string(),
    uuid: string(),
    createdAt: string(),
    updatedAt: string(),
});

export const sharedEventSchema = object().shape({
    date: string().nullable(true),
    dateOnlyMonth: boolean().nullable(true),
    event: eventSchema,
    eventType: string().nullable(true),
    location: eventLocationSchema.nullable(true),
    minNumberOfGuests: number(),
    maxNumberOfGuests: number(),
    uuid: string(),

    // videoLink: string(),
    // imageLink: string(),
    // name: string(),
    // description: string(),
})