import styled from 'styled-components';

export const EventroLogo = styled.img`
    width: 206px;
    height: 145px;
    margin: 10px 0px;

    @media (max-width: 768px){
        width: 160px;
        height: 120px;
    }
`;

export const NewUserText = styled.div`
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 21px;
    line-height: 34px;
    text-align: center;

    @media (max-width: 768px){
        font-size: 16px;
        line-height: 24px;
    }
`;

export const NewUserBoldText = styled.div`
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    margin: 30px 0px;
    letter-spacing: -0.022em;

    @media (max-width: 768px){
        font-size: 18px;
        line-height: 28px;
        margin: 20px 0px;
    }
`;

export const NewUserContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
`;