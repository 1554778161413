import styled from 'styled-components';

export const PageTitle = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 43px;
    line-height: 70px;
    padding: 60px 0 50px 0;
    background-color: #fff;
    width: 100%;

    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 24px;
        padding: 10px 0px;
    }
`;

export const TeamLayout = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 60px;
    }
`;

export const TeamContainer = styled.div`
    flex: 2;
    box-sizing: border-box;
    padding: 0 0 20px 20px;
    overflow: hidden;
    min-width: 420px;

    @media (max-width: 768px) {
        padding: 0px 20px 20px 20px;
        display: ${props => props.hide ? 'none' : 'block'};
        min-width: auto;
    }
`;

export const StyleHelper = styled.div`
    overflow: auto;
    height: calc(100% - 180px);
    padding-right: 30px;

    @media (max-width: 768px) {
        padding-right: 20px;
    }
`;

export const AddProText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 53px;
    color: var(--black);
    margin-left: 10px;
`;

export const NameText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${(props) => (props.clicked ? '#106CC1' : 'var(--black)')};
    margin-right: 8px;
    margin-left: 20px;

    @media (max-width: 768px) {
        margin-left: 15px;
        word-break: break-word;
    }
`;

export const CategoryText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: ${(props) => (props.clicked ? '#106CC1' : 'var(--black)')};
    margin-right: 8px;
    margin-left: 0;
    word-break: break-word;

    @media (max-width: 768px) {
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        word-break: break-word;
    }
`;

export const DeleteContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        cursor: pointer;
    }
`;

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
`;

export const SearchImg = styled.img`
    width: 18px;
    height: 18px;

    @media (max-width: 768px) {
        align-self: center;
    }
`;

export const SearchInput = styled.input`
    color: var(--black);
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 44px;
    padding-left: 7px;
    height: 42px;
    border: none;
    outline: none;
    width: 50%;

    &::placeholder {
        color: var(--black);
        opacity: 0.3;
    }

    &:focus {
        width: 100%;
    }

    @media (max-width: 768px) {
        font-size: 24px;
        height: auto;
    }
`;

export const Seperator = styled.div`
    width: 100%;
    border: 1px solid var(--red);
    margin: 50px 0;
`;

export const PopupContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #fff;
    padding: 50px;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

export const TeamTabContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 63px;
    background-color: ${(props) => (props.clicked ? '#F3F9FE' : '#fff')};

    @media (max-width: 768px) {
        padding: 15px 0;
    }
`;
