import { string, object, array } from 'yup';
import { metadataSchema } from './common-schemas';

export const locationSchema = object().shape({
    _id: string(),
    image: string(),
    cities: array().of(object().shape({
        _id: string().required(),
        name: string(),
        region: string(),
        state: string().nullable(true),
        country: string(),
        updatedAt: string(),
        createdAt: string(),
    })).default([])
});

export const locationsArraySchema = object().shape({
    data: array().of(locationSchema).default([]),
    metadata: metadataSchema
});
