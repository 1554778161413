import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as RightArrow } from '../../assets/icons/right-arrow.svg';
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow.svg';

const SliderContainer = styled.div`
    padding: 0px 10px 20px 10px;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    box-sizing: border-box;
`;


const HorizontalSlider = ({ data }) => {
    let [currentIndex, setCurrentIndex] = useState(0);

    const goBack = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevState) => prevState - 1);
        }
    }

    const goForward = () => {
        if (currentIndex < data.length - 1) {
            setCurrentIndex((prevState) => prevState + 1);
        }
    }

    return (
        <SliderContainer>
            <LeftArrow fill={currentIndex > 0 ? "#7F6561" : 'white'} onClick={goBack} width={20} height={20} style={{ marginRight: '10px' }} />
            {data[currentIndex]}
            <RightArrow fill={currentIndex < data.length - 1 ? "#7F6561" : 'white'} onClick={goForward} width={20} height={20} style={{ marginLeft: '10px' }} />
        </SliderContainer>
    );
}

export default HorizontalSlider;