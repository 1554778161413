import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { BoxHeader, BoxLayout, PBspent, PBtotal, ProgressBarContainer } from '../EventRoomStyles';
import { loadBudget, cleanBudget } from '../../../../store/reducers/budget/budget-reducer';
import { currencyChar } from '../../../../common/utils/utils';

const totalBudget = 100000;

const BudgetBox = ({ eventId }) => {
    const dispatch = useDispatch();
    const { list, totalPrice } = useSelector((state) => state.budget);

    useEffect(() => {
        dispatch(loadBudget(eventId));

        return () => {
            dispatch(cleanBudget());
        };
    }, []);

    return (
        <BoxLayout rowMobile={true}>
            <BoxHeader>BUDGET</BoxHeader>
            {
                <ProgressBarContainer>
                    <CircularProgressbarWithChildren
                        value={/*(totalPrice / totalBudget) * 100*/ 0}
                        styles={buildStyles({
                            strokeLinecap: 'butt',
                            pathColor: '#EC5C57',
                            trailColor: '#f2f2f2',
                            backgroundColor: '#EC5C57',
                            pathTransitionDuration: 1
                        })}
                        strokeWidth={4}>
                        <PBspent>{list.length > 0 ? currencyChar[list[0].currency] + ' ' + Math.trunc(totalPrice).toLocaleString() : null}</PBspent>
                        <PBtotal>ESTIMATED COST</PBtotal>
                    </CircularProgressbarWithChildren>
                </ProgressBarContainer>
            }
        </BoxLayout>
    )
}

export default BudgetBox;