import React from 'react'
import { FlexColumn, PopupButton } from '../../../../common/components/CommonStyledComponent';
import { InfoHeader, InfoText } from '../EventRoomStyles';

const InfoContent = ({ label, text, onButtonClick }) => {
    return (
        <FlexColumn style={{ alignItems: 'center', justifyContent: 'center' }}>
            <InfoHeader>{label}</InfoHeader>
            <InfoText>{text}</InfoText>
            <PopupButton style={{ marginTop: '10px' }} onClick={onButtonClick}>close</PopupButton>
        </FlexColumn>
    )
}

export default InfoContent;