import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import GoogleButton from 'react-google-button';
import FacebookLogin from 'react-facebook-login';
import validator from 'validator';

import bgImage from '../../assets/images/bg.png';
import fireworks from '../../assets/icons/fireworks-color.svg';

import { LoginGrid, VideoContainer, FormContainer, LoginHeader, StyledInput, Line, BoldText, RegularText, ButtonContainer, TextContainer, LogLogo } from '../Login/LoginStyles';
import { FlexRowSpaced, PrimaryButton, FlexColumnCentered, googleButtonStyles, ImageText } from '../../common/components/CommonStyledComponent';
import { authUserLogin } from '../../store/reducers/auth/auth-reducer';

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onLogin = () => {
        if (!validator.isEmail(email)) {
            alert('Invalid email provided');
            return;
        }
        if (password === '') {
            alert('Password is required');
            return;
        }
        let data = {
            grant_type: 'password',
            email: email,
            password: password,
        };
        onLoginAttempt(data);
    };

    const responseGoogle = (response) => {
        let data = {
            grant_type: 'google',
            google_token: response.tokenId,
        };
        onLoginAttempt(data);
    };

    const responseFacebook = (response) => {
        let data = {
            grant_type: 'facebook',
            facebook_token: response.accessToken,
        };
        onLoginAttempt(data);
    };

    const onLoginAttempt = (data) => {
        dispatch(authUserLogin(data));
    };

    return (
        <LoginGrid>
            <VideoContainer style={{ width: '100%' }} bg={bgImage}>
                <ImageText>We're happy to be part of your celebration!
                    It's going to be fantastic</ImageText>
            </VideoContainer>
            <FormContainer>
                <FlexColumnCentered>
                    <LogLogo src={fireworks} alt="/" />
                    <LoginHeader>Log In</LoginHeader>
                    <FlexColumnCentered>
                        <GoogleLogin
                            clientId='266520894580-jdu2b1nb6q34s0ahro50arn19376uk43.apps.googleusercontent.com'
                            render={(renderProps) => (
                                <GoogleButton
                                    id='my-google-button'
                                    style={googleButtonStyles}
                                    onClick={renderProps.onClick}
                                    label={"Login with Google"}>
                                </GoogleButton>
                            )}
                            onSuccess={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />
                        <FacebookLogin appId='442320013402632' fields='name,email,picture' cssClass='my-facebook-button-class' callback={responseFacebook} icon='fa-facebook' />
                    </FlexColumnCentered>
                    <FlexRowSpaced style={{ width: '100px', height: '50px', alignSelf: 'center', margin: '30px 0px 20px 0px' }}>
                        <Line></Line>
                        <BoldText>OR</BoldText>
                        <Line></Line>
                    </FlexRowSpaced>
                    <FlexColumnCentered>
                        <StyledInput placeholder={'Email'} value={email} onChange={(e) => setEmail(e.target.value)} inputMode={'email'} />
                        <StyledInput placeholder={'Password'} value={password} onChange={(e) => setPassword(e.target.value)} type={'password'} />
                    </FlexColumnCentered>
                </FlexColumnCentered>
                <FlexColumnCentered>
                    <ButtonContainer marginTop={'30px'}>
                        <PrimaryButton onClick={onLogin}>Log In</PrimaryButton>
                    </ButtonContainer>
                    <TextContainer>
                        <RegularText>Don't have an account?</RegularText>
                        <RegularText style={{ marginLeft: '8px', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => history.push('/signup')}>
                            Sign Up
                        </RegularText>
                    </TextContainer>
                </FlexColumnCentered>
            </FormContainer>
        </LoginGrid>
    );
};

export default Login;
