import styled from "styled-components";

export const Card = styled.div`
    width: 350px;
    min-width: 220px;
    height: 480px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: ${props => props.bg ? props.bg : 'white'};
    color: #222222;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    margin: 12px;
    box-sizing: border-box;
    align-items: center;
    transition: 0.8s;

    &:hover {
        transform: scale(1.1,1.1);
    }

    @media (max-width: 768px){
        width: 300px;
        height: 400px;
    }
`;

export const CardLogo = styled.img`
    margin-bottom: 30px;
    height: 150px;
    /* width: 220px;
    height: 220px; */
`;

export const CardText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #222222;

    @media (max-width: 1230px){
        font-size: 18px;
        line-height: 22px;
    }
`;