import { createSlice } from '@reduxjs/toolkit';
import eventsApi from '../../api/events-api';

const initialState = {
    events: [],
    error: null,
    showLoader: true,
    loading: false
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _loadEventsSuccess = (state, action) => {
    const data = action.payload;
    state.loading = false;
    state.events = data.events;
};

const _connectVideosToEvents = (state, action) => {
    const finishedVideosPolling = action.payload;
    const eventsWithVideos = [...state.events];
    eventsWithVideos.forEach((event) => {
        let videoId = event.videoDetails?.videoId;
        if (videoId && finishedVideosPolling[videoId]) {
            event.videoDetails = finishedVideosPolling[videoId];
        }
    })
    state.showLoader = false;
    state.events = eventsWithVideos;
}

const _cleanEvents = (state) => {
    return initialState;
};

const events = createSlice({
    name: 'events',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        loadEventsSuccess: _loadEventsSuccess,
        cleanEvents: _cleanEvents,
        connectVideosToEvents: _connectVideosToEvents
    },
});

const { actions, reducer } = events;

export const {
    loadStart,
    loadFailed,
    loadEventsSuccess,
    cleanEvents,
    connectVideosToEvents
} = actions;

export default reducer;

export const loadEvents = () => {
    return async (dispatch, getState) => {
        let currentState = getState().steps;

        dispatch(loadStart());
        const eventsData = {
            uuid: currentState.uuid,
            minNumberOfGuests: currentState.guests.minNumberOfGuest,
            maxNumberOfGuests: currentState.guests.maxNumberOfGuest,
            eventType: currentState.eventKind.type,
            locationIds: currentState.where.cities,
            date: currentState.date.date,
            dateOnlyMonth: currentState.date.dateOnlyMonth,
            liked: currentState.swipes.liked,
            disliked: currentState.swipes.disliked,
        }
        const { status, data, error } = await eventsApi.fetchEvents(eventsData);
        if (status === 200) {
            return dispatch(loadEventsSuccess(data));
        } else {
            return dispatch(loadFailed(error));
        }
    };
};

export const startVideoPolling = () => {
    return async (dispatch, getState) => {
        let numberOfTries = 3;
        let timeInterval = 60000; //20 seconds
        const finishedVideosPolling = {};

        // get all videos ids
        let currentState = getState().events;
        let videoIds = currentState.events.map((event) => {
            return event.videoDetails.videoId;
        });

        if (!videoIds) {
            dispatch(connectVideosToEvents(finishedVideosPolling))
            return;
        }
        let intervalId = setInterval(async () => {
            let { data, status } = await eventsApi.pollVideos(videoIds);
            if (status === 200) {
                data.status.forEach((videoPoll) => {
                    if (videoPoll.videoState === "FINISHED") {
                        finishedVideosPolling[videoPoll.videoId] = videoPoll;
                        videoIds = videoIds.filter((vidId) => {
                            return vidId !== videoPoll.videoId;
                        })
                    }
                })
            }

            numberOfTries = numberOfTries - 1;
            timeInterval = timeInterval - 2000;
            if (numberOfTries === 0 || videoIds.length === 0) {
                clearInterval(intervalId);
                dispatch(connectVideosToEvents(finishedVideosPolling));
            }
        }, timeInterval);
    }
}