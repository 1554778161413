export const CommonRoutes = {
    home: '/home',
    logout: '/logout',
    login: '/login',
    eproLogin: 'https://epro.ivtech.co.il/login',
    signup: '/signup',
    dashboard: '/dashboard',
    shareEvent: '/share-event'
}

export const ErrorRoutes = {
    error403: '/403',
    error404: '/404'
}

export const BoardingRoutes = {
    boarding: '/boarding',
    where: '/boarding/where',
    kind: '/boarding/event-kind',
    date: '/boarding/date',
    guests: '/boarding/guests',
    swipes: '/boarding/swipes',
    chooseEvent: '/boarding/choose-event',
}

const DashboardRoutes = {
    eventRoom: '/dashboard/event-room',
    messages: '/dashboard/messages',
    team: '/dashboard/team',
    guests: '/dashboard/guests',
    budget: '/dashboard/budget',
    fantasies: '/dashboard/fantasies',
    settings: '/dashboard/settings',
}

const Routes = {
    Common: CommonRoutes,
    Errors: ErrorRoutes,
    Boarding: BoardingRoutes,
    Dashboard: DashboardRoutes
}

export default Routes;