import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import fireworks from '../../../assets/icons/fireworks-color.svg';
import searchIcon from '../../../assets/icons/search.svg';
import searchOrangeIcon from '../../../assets/icons/search-orange.svg';
import nextIcon from '../../../assets/icons/back.svg';
import nextIconOrange from './../../../assets/icons/next-orange.svg';

import { WhereContainer, WhereHeader, SearchContainer, SearchImg, SearchInput, NextButton, LocationsContainer, FireworksIcon, NextContainer, NextText, NextButtonOrange } from './WhereStyles';
import { BackButton, FlexRowWhere, PageHeader, PopupButton, PopupText, ProgressBar, ProgressFill, Text } from '../../../common/components/CommonStyledComponent';
import { useSelector, useDispatch } from 'react-redux';
import { useCheckStep } from '../../../hooks/useCheckStep';
import { completeStep } from '../../../store/reducers/steps/steps-reducer';
import Location from './Location/Location';
import { cleanLocations, loadLocations } from '../../../store/reducers/locations/locations-reducer';
import Loader from '../../../common/components/Loader';
import GeneralPopup from '../../../common/components/GeneralPopup';
import Routes from '../../routes/index';



const limit = 100;

const Where = () => {
    const dispatch = useDispatch();
    const whereStep = useSelector(state => state.steps.where);
    const { locations, loading } = useSelector(state => state.locations);
    const onNextPage = useCheckStep(whereStep);
    const history = useHistory();

    const [cities, setCities] = useState(whereStep.cities);
    const [search, setSearch] = useState('');
    const [showCities, setShowCities] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const advanceStep = (e) => {
        e.stopPropagation();
        if (cities.length > 0) {
            dispatch(completeStep({ stepName: 'where', data: { cities: cities } }))
            onNextPage();
        } else {
            setShowPopup(true);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(loadLocations(1, limit, search)).then(() => {
                if (search !== '') {
                    setShowCities(true);
                } else {
                    setShowCities(false);
                }
            });
        }, 350);
        return () => {
            clearTimeout(timer);
        };
    }, [search])

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        return () => {
            dispatch(cleanLocations());
        }
    }, []);

    const onCitySelect = (cityId) => {
        if (!cities.includes(cityId)) {
            setCities((prevState) => [...prevState, cityId]);
        } else {
            setCities((prevState) => {
                let newArr = [...prevState];
                const index = newArr.indexOf(cityId);
                if (index > -1) {
                    newArr.splice(index, 1);
                }
                return newArr;
            })
        }
    };

    return (
        <>
            <WhereContainer>
                <WhereHeader>
                    <PageHeader>Where are we celebrating?</PageHeader>
                    <Text>Select the relevant cities, you may choose multiple options in your preferred district</Text>
                </WhereHeader>
                <SearchContainer>
                    <SearchImg src={search !== '' ? searchOrangeIcon : searchIcon} alt="Search" />
                    <SearchInput value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search Locations" />
                </SearchContainer>

                <FlexRowWhere style={{ gridArea: 'next', width: '100%', justifyContent: 'space-between', height: '20px' }}>
                    <BackButton src={nextIcon} alt="Back" onClick={() => { history.replace(Routes.Boarding.kind); }} />
                    <ProgressBar style={{maxWidth:'90%' }} >
                        <ProgressFill fill={'40%'}></ProgressFill>
                    </ProgressBar>
                    {
                        cities.length === 0 ?
                            <NextButton disabled={true} src={nextIcon} alt="Next" onClick={(e) => { advanceStep(e) }} />
                            :
                            <NextContainer onClick={(e) => { advanceStep(e) }} >
                                <NextText>Next</NextText>
                                <NextButtonOrange src={nextIconOrange} alt="Next" />
                            </NextContainer>

                    }
                </FlexRowWhere>
                <LocationsContainer style={{ gridArea: "locations" }} >
                    {
                        loading ? <Loader /> :
                            locations.map((loc, index) => {
                                return <Location
                                    key={index}
                                    name={loc._id}
                                    image={loc.image}
                                    cityList={loc.cities}
                                    onCitySelect={onCitySelect}
                                    selectedCities={cities}
                                    isCardOpen={showCities}
                                />
                            })
                    }
                </LocationsContainer>
                <FireworksIcon src={fireworks} alt="/" />
            </WhereContainer>
            {
                showPopup ? <GeneralPopup negativeButtonAction={() => { setShowPopup(false) }} >
                    <PopupText>Please choose location first</PopupText>
                    <PopupButton onClick={() => setShowPopup(false)}>Ok</PopupButton>
                </GeneralPopup>
                    : null
            }
        </>
    )

}

export default Where;