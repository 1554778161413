import React from 'react';

import { BoxHeader, BoxLayout, RoundImagesContainer, RoundCount } from '../EventRoomStyles';
import env from '../../../../config';
import { FlexColumnCentered, FlexRow, RoundProfile } from '../../../../common/components/CommonStyledComponent';

const TeamBox = ({ team }) => {
    const length = team.length;

    return (
        <BoxLayout style={{ padding: '20px 0px' }} rowMobile={true}>
            <BoxHeader>A TEAM</BoxHeader>
            <RoundImagesContainer>
                {length < 3 ? (
                    team.map((member, index) => {
                        return <RoundProfile key={index} src={env.apiGateway.PROS_BUCKET_URL + member.avatar} />;
                    })
                ) : (
                    <FlexColumnCentered>
                        <FlexRow>
                            {team.slice(0, 3).map((member, index) => {
                                return <RoundProfile key={index} src={env.apiGateway.PROS_BUCKET_URL + member.avatar} />;
                            })}
                        </FlexRow>
                        <FlexRow>
                            {length > 5 ? <RoundProfile style={{ opacity: '0' }} key={'hidden'} /> : null}
                            {team.slice(3, 5).map((member, index) => {
                                return <RoundProfile key={index + 3} src={env.apiGateway.PROS_BUCKET_URL + member.avatar} />;
                            })}
                            {length > 5 ? <RoundCount>+{length - 5}</RoundCount> : null}
                        </FlexRow>
                    </FlexColumnCentered>
                )}
            </RoundImagesContainer>
        </BoxLayout>
    );
};

export default TeamBox;
