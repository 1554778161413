import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import infoLogo from '../../../../assets/icons/info.svg'

import { FlexColumn, FlexRowSpaced } from '../../../../common/components/CommonStyledComponent';
import { markChecklist } from '../../../../store/reducers/user-event/user-event-reducer';
import {
    ChecklistContainer, ChecklistTitle, ProgressCircle, ProgressLine,
    ChecklistDescription, ChecklistButton, VerticalProgressLayout, ChecklistCover, TopGradient, BottomGradient, InfoLogo, BoxHeader
} from '../EventRoomStyles';


const Checklist = ({ eventId, description, task, checked, title, _id }) => {

    const dispatch = useDispatch();

    const markProgress = (progressId, checked) => {
        dispatch(markChecklist(eventId, progressId, checked))
    }

    return (
        <ChecklistContainer>
            <FlexColumn>
                <ProgressCircle checked={checked} />
                <ProgressLine checked={checked} />
            </FlexColumn>
            <FlexColumn style={{ padding: '0px 60px 30px 30px' }}>
                <ChecklistTitle>{title}</ChecklistTitle>
                <ChecklistDescription>{description}</ChecklistDescription>
                <FlexRowSpaced>
                    <ChecklistButton style={{ marginRight: '30px' }}>{task}</ChecklistButton>
                    {!checked ? <ChecklistButton onClick={() => markProgress(_id, true)}>Mark it done</ChecklistButton> :
                        <ChecklistButton onClick={() => markProgress(_id, false)}>Undone</ChecklistButton>}
                </FlexRowSpaced>
            </FlexColumn>
        </ChecklistContainer>
    )
}

const VerticalProgress = ({ eventId, progress, onInfoClick }) => {

    let sortedProgress = useMemo(() => {
        let progressCopy = [...progress];
        progressCopy.sort((a, b) => (b.checked - a.checked) * (-1));
        return progressCopy;
    }, [progress]);

    return (
        <VerticalProgressLayout>
            <FlexRowSpaced style={{ zIndex: 101 }}>
                <BoxHeader style={{ marginBottom: '0px', fontWeight: 400 }}>CHECKLIST</BoxHeader>
                <InfoLogo style={{ position: 'inherit' }} src={infoLogo} alt='/' onClick={(e) => onInfoClick(e)} />
            </FlexRowSpaced>
            <ChecklistCover>
                <TopGradient />
                <div>
                    {
                        sortedProgress.map((task, index) => {
                            return <Checklist key={index} {...task} eventId={eventId} />
                        })
                    }
                </div>
                <BottomGradient />
            </ChecklistCover>
        </VerticalProgressLayout >

    )
}

export default VerticalProgress;