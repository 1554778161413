import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import env from '../../config';

import fireworkIcon from '../../assets/icons/fireworks.svg';

import { formatDate } from '../../common/utils/date-utils';
import Loader from '../../common/components/Loader';
import { cleanShareEvent, loadShareEvent } from '../../store/reducers/share-event/share-event-reducer';
import { EventDescription, EventTitle } from '../Boarding/ChooseEvent/ChooseEventStyles';
import { FantasiesLayout, FantasiesVideo, FantasyDetails, FantasyText, FantasyTitle, FireworksImage } from '../Dashboard/Fantasies/FantasiesStyles';
import { VideoContainer } from '../Login/LoginStyles';
import ReactPlayer from 'react-player';
import { getEventNameByType } from '../../common/utils/utils';
import { FlexRow, WhiteButton } from '../../common/components/CommonStyledComponent';
import { Link } from 'react-router-dom';

const ShareEvent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { event, loading } = useSelector((state) => state.shareEvent);
    const eventUUID = history.location.search ? history.location.search.split('=')[1] : null;
    const isWindowForMobile = window.innerWidth <= 768;

    useEffect(() => {
        if (eventUUID) {
            dispatch(loadShareEvent(eventUUID))
        }
        return () => {
            dispatch(cleanShareEvent());
        }
    }, [eventUUID]);

    return (
        loading || !event ? <Loader /> :
            <FantasiesLayout>
                {
                    event?.videoDetails?.videoUrl ?
                        <VideoContainer style={{ width: isWindowForMobile ? '100%' : '50%' }} bg={event.imageLink ? env.apiGateway.BUCKET_URL + event.imageLink : ''}>
                            <ReactPlayer
                                width={'100%'}
                                height={'100%'}
                                controls={true}
                                style={{ background: 'black' }}
                                url={event.videoDetails.videoUrl} />
                        </VideoContainer>
                        :
                        <FantasiesVideo src={env.apiGateway.BUCKET_URL + event.imageLink} alt="/" />
                }
                <FantasyDetails bgColor={event.event.theme.backgroundColor}>
                    <FireworksImage src={fireworkIcon} alt="/" />
                    <EventTitle>{event.event.name.toUpperCase()}</EventTitle>
                    <EventDescription>{event.event.description}</EventDescription>
                    <FantasyTitle>Where</FantasyTitle>
                    <FantasyText>{event.location?.name}</FantasyText>
                    <FantasyTitle>What</FantasyTitle>
                    <FantasyText style={{ textTransform: 'capitalize' }}>{getEventNameByType(event.eventType)}</FantasyText>
                    <FantasyTitle>When</FantasyTitle>
                    <FantasyText>{formatDate(event.date)}</FantasyText>
                    <FantasyTitle>Guests</FantasyTitle>
                    <FantasyText>{event.minNumberOfGuests}</FantasyText>
                    <FlexRow
                    style={{
                        alignItems: "center",
                        gap: isWindowForMobile ? 0 : 20,
                        flexDirection: isWindowForMobile ? "column" : "row",
                    }}
                    >
                        <a style={{ textDecoration: "none" }} href="https://eventroclub.wixsite.com/website-1" target="blank">
                            {" "}
                            <WhiteButton style={{ marginTop: "20px" }}>
                            Go to Eventro{" "}
                            </WhiteButton>
                        </a>
                    </FlexRow>
                </FantasyDetails>
            </FantasiesLayout>
    );

}

export default ShareEvent;