import styled from "styled-components";

export const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
`;

export const MainImage = styled.div`
    height: 840px;
    width: 100%;
    max-height: 840px;
    background: ${props => props.bg ? `url(${props.bg})` : 'black'};
    background-size: cover;
    display: flex;
    flex-direction: column;
`;

export const ColoredDiv = styled.div`
    width: 100%;
    /* flex: 1; */
    min-height: 240px;
    background: ${props => props.bgColor ? props.bgColor : 'rgba(0,0,0,0.2)'};
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    @media(max-width: 768px){
        min-height: 180px;
    }
`;

export const MainLogoWhite = styled.img`
    width: 160px;
    height: 205px;

    @media (max-width: 768px){
        width: 120px;
        height: 120px;
    }
`;

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    top: 30px;
    right: 30px;

    @media (max-width: 768px){
        top: 10px;
        right: 3px;
    }
`;

export const LoginText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 32px;
    color: white;
    letter-spacing: -0.02em;
    cursor: pointer;

    &:hover {
        color: black;
    }

    @media (max-width: 768px){
        font-size: 18px;
        line-height: 24px;
    }
`;

export const LoginButton = styled.div`
    width: 150px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;
    background: #FFFFFF;
    border-radius: 217px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    cursor: pointer;

    &:hover {
        background: black;
        color: white;
    }

    @media (max-width: 768px){
        width: 130px;
        height: 30px;
        font-size: 12px;
    }
`;

export const CreateEventButton = styled.div`
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
    width: 335px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;
    background: #FFFFFF;
    border-radius: 73px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    cursor: pointer;

    &:hover {
        background: black;
        color: white;
    }

    @media (max-width: 768px){
        width: 300px;
        height: 60px;
    }
`;

export const MainText = styled.div`
    font-family: 'HeroicCondensed';
    font-style: italic;
    font-weight: 600;
    font-size: 150px;
    line-height: 180px;
    text-transform: uppercase;
    color: #FFFBF2;

    @media (max-width: 768px){
        font-size: 44px;
        line-height: 90px;
        text-align: center;
    }
`;

export const MainDetails = styled.div`
    padding: 70px 160px 20px 160px;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    background: white;
    align-items: center;

    @media (max-width: 768px){
        padding: 20px;
    }
`;

export const MainLogoColor = styled.img`
    width: 420px;

    @media (max-width: 768px){
        width: 170px;
        height: 170px;
    }
`;

export const EventText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    margin: 60px 0px;
    padding: 0px 10%;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #0F192C;

    @media (max-width: 768px){
        font-size: 24px;
        margin: 30px 0px 80px 0px;
        line-height: 32px;
    }
`;

export const CardsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 60px;

    @media (max-width: 768px){
        flex-direction: column;
        align-items: center;
    }
`;

export const HorizontalLine = styled.div`
    height: 1px;
    width: 100%;
    background: #000000;
`;

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 30px 0px;
    align-items: center;

    @media (max-width: 768px){
        flex-direction: column;
        padding: 30px 0px 10px 0px;
    }
`;

export const HorizontalLogo = styled.img`
    @media (max-width: 768px){
        display: none;
        /* width: 180px;
        height: 90px;
        margin-bottom: 20px; */
    }
`;

export const SocialMediaIcon = styled.img`
    width: 35px;
    height: 35px;
    cursor: pointer;

    @media (max-width: 768px){
        width: 24px;
        height: 24px;
    }
`;

export const SimpleText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    margin: 5px 0px;

    @media (max-width: 768px){
        font-size: 12px;
    }
`;