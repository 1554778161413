import React from 'react';
import styled from 'styled-components';

const GradientDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    filter: blur(120px);
    background: conic-gradient(from 180deg at 50% 50%, #EC5C57 0deg, #FFFFFF 35.62deg, #F3D54F 127.5deg, #F2AA47 218.14deg, #F29243 270deg, #EC5C57 360deg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 376px);
    height: calc(100% + 316px);
    border-radius: 999px;
    z-index: 1;

    @media (max-width: 768px) {
        width: calc(100% + 800px);
        height: calc(100% + 1300px);
        left: 40%;
        background: conic-gradient(from 180deg at 50% 50%, #EC5C57 0deg, #FFFFFF 35.62deg, #F3D54F 127.5deg, #F2AA47 218.14deg, #F29243 270deg, #EC5C57 360deg);
    }
`;

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
`;


const LoaderGradient = ({ children, styles }) => {
    return (
        <PageContainer style={styles}>
            <GradientDiv>
            </GradientDiv>
            {children}
        </PageContainer>
    )
}

export default LoaderGradient;