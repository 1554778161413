import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { loadBudget, cleanBudget } from '../../../store/reducers/budget/budget-reducer';
import { FlexRowSpaced } from '../../../common/components/CommonStyledComponent';
import { PageTitle, Text, TotalPriceText, TotalBudgetText, ContentContainer, ProgressContainer, CircleSize, ListContainer, BudgetLayout } from './BudgetStyles';
import { currencyChar } from '../../../common/utils/utils';
import Loader from '../../../common/components/Loader';


const Budget = () => {
    const dispatch = useDispatch();
    const { userEvent } = useSelector((state) => state.userEvent);
    const { list, totalPrice, loading } = useSelector((state) => state.budget);
    useEffect(() => {
        dispatch(loadBudget(userEvent._id));

        return () => {
            dispatch(cleanBudget());
        };
    }, []);

    return (
        <BudgetLayout>
            {loading ? <Loader /> : null}
            <PageTitle>Budget</PageTitle>
            <ContentContainer>
                <ListContainer>
                    {list &&
                        list.map((item) => {
                            return (
                                <FlexRowSpaced key={item._id} style={{ width: '100%', margin: '40px 0'}} className={'cancel-position-absolute'}>
                                    <Text>
                                        {item.fullName} - {item.occupationTitle}
                                    </Text>
                                    <Text>{currencyChar[item.currency] + item.total.toLocaleString()}</Text>
                                </FlexRowSpaced>
                            );
                        })}
                </ListContainer>
                <ProgressContainer>
                    <CircleSize>
                        <CircularProgressbarWithChildren
                            value={/*(totalPrice / 100000) * 100*/ 0}
                            styles={buildStyles({
                                strokeLinecap: 'butt',
                                stroke: 'green',
                                pathColor: '#EC5C57',
                                trailColor: '#f2f2f2',
                                backgroundColor: '#EC5C57',
                                pathTransitionDuration: 1,
                            })}>
                            <TotalPriceText>{list.length > 0 ? currencyChar[list[0].currency] + ' ' + totalPrice.toLocaleString() : null}</TotalPriceText>
                            <TotalBudgetText>Average Price</TotalBudgetText>
                        </CircularProgressbarWithChildren>
                    </CircleSize>
                </ProgressContainer>
            </ContentContainer>
        </BudgetLayout>
    );
};

export default Budget;
