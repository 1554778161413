import { v4 as uuidv4 } from 'uuid';
import config from '../../config';
import * as jwt from 'jsonwebtoken';

export const initialsByName = (name) => {
    if (!name) {
        return '';
    }
    const names = name ? name.split(' ') : '';
    let initials = names.length === 1 ? names[0].substring(0, 2).toUpperCase() : names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const fullName = ({ firstName, lastName, email = '' }) => {
    if (!firstName && !lastName) {
        return email;
    }
    if (!firstName) {
        return lastName;
    }
    if (!lastName) {
        return firstName;
    }
    return firstName + ' ' + lastName;
};

export const getId = () => {
    return uuidv4();
};

export const getLimitByPageWidth = (defaultLimit) => {
    const limit = Math.floor(window.innerWidth / 450);
    if (limit === 0) {
        return defaultLimit;
    }
    return limit < 4 ? limit * 2 : defaultLimit;
};

export const colorName = (color) => {
    switch (color) {
        case '#F28A57':
            return 'orange';
        case '#1264A3':
            return 'blue';
        case '#6B27AA':
            return 'purple';
        case '#0CA60C':
            return 'green';
        case '#FFD700':
            return 'yellow';
        default:
            return '';
    }
};

export const colorByName = (color) => {
    switch (color) {
        case 'orange':
            return '#F28A57';
        case 'blue':
            return '#1264A3';
        case 'purple':
            return '#6B27AA';
        case 'green':
            return '#0CA60C';
        case 'yellow':
            return '#FFD700';
        case 'transparent':
            return '#ffffff00';
        default:
            return '';
    }
};

export const imagePath = (fileName) => {
    if (fileName && !fileName.includes('http')) {
        return config.apiGateway.IMAGE_STORAGE_URL + fileName;
    }
    return fileName;
};

export const projectStaticMapImagePath = (projectId) => {
    if (projectId && !projectId.includes('http')) {
        return config.apiGateway.PROJECT_STATIC_MAP_STORAGE_URL + projectId + '.png';
    }
    return projectId;
};

export const getFileExt = (filename) => {
    return filename.split('?')[0].split('#')[0].split('.').pop();
};

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const isEmpty = (obj) => {
    for (let item in obj) {
        return false;
    }
    return true;
};

export const createSortObject = (sort) => {
    const objKeys = Object.keys(sort);
    if (!isEmpty(sort)) {
        sort = {
            by: objKeys[0],
            order: sort[objKeys[0]],
        };
    }

    return sort;
};

export const getFollowing12Months = () => {
    var now = new Date();
    var current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    var month = current.getMonth();
    var year = current.getFullYear();

    var names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    var res = [];

    for (var i = 0; i < 12; ++i) {
        res.push({ month: names[month], date: new Date(year, month, 1) });
        if (++month === 12) {
            month = 0;
            ++year;
        }
    }
    return res;
};

export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

export const currencyChar = {
    ils: '₪',
    usd: '$',
    eur: '€',
};

export const getTokenRefreshTime = (token) => {
    const fiveMinutes = 300000;
    const decoded = jwt.decode(token);
    const expiration = decoded.exp;
    let tokenTimeLeft = (expiration * 1000 - new Date()) - fiveMinutes;
    if (tokenTimeLeft <= 0) {
        return 1;
    }
    return tokenTimeLeft;
}

export const getEventNameByType = (eventType) => {
    if (eventType === 'wedding') {
        return 'Wedding';
    }
    if (eventType === 'bar_mitzvah') {
        return 'Bar/Bat Mitzvah';
    }
    if (eventType === 'Circus') {
        return 'Purim Party'
    }
    return 'Party';
}