import React, { useState, useEffect } from 'react';

import { ReactComponent as FireworksIcon } from '../../../assets/icons/fireworks.svg';
import lightsImage from '../.././../assets/images/candies.png';
import backIcon from '../../../assets/icons/back.svg';
import nextIconOrange from '../../../assets/icons/next-orange.svg';

import { useSelector, useDispatch } from 'react-redux';
import { useCheckStep } from '../../../hooks/useCheckStep';
import { cleanEventTypes, loadEventTypes } from '../../../store/reducers/event-types/event-types-reducer';
import { EventsGrid, ImageContainer, ImageTextContainer, EventsContainer, EventName, PageText, ImageText } from './EventKindStyles';
import { completeStep } from '../../../store/reducers/steps/steps-reducer';
import { BackButton, FlexRowSpaced, PopupButton, PopupText, ProgressBar, ProgressFill } from '../../../common/components/CommonStyledComponent';
import { PageHeader, EventsBox } from './EventKindStyles';
import { useHistory } from 'react-router-dom';
import { NextButton, NextButtonOrange, NextContainer, NextText } from '../Where/WhereStyles';
import Loader from '../../../common/components/Loader';
import GeneralPopup from '../../../common/components/GeneralPopup';
import Routes from '../../routes/index';

const limit = 100;

const EventKind = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { eventTypes, loading } = useSelector(state => state.eventTypes);
    const eventKind = useSelector(state => state.steps.eventKind);
    const [chosenEvent, setChosenEvent] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const onNextPage = useCheckStep(eventKind);
    const [newList,setNewList] = useState(eventTypes)

    const isWindowForMobile = window.innerWidth <= 768;

  

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        dispatch(loadEventTypes(1, limit));
        return () => {
            dispatch(cleanEventTypes());
        }
    }, []);

    useEffect (()=>{
        const  list =[...eventTypes];
          const title = {name:'Coming soon...',type:'coming_soon'}
          const titleAdded=list.find((event)=>event.type==='coming_soon')
          if(!titleAdded){

              list.splice(1, 0, title)
          }

        setNewList(list)

    },[eventTypes])

    const nextStep = (e) => {
        e.stopPropagation();
        if (chosenEvent !== null) {
            dispatch(completeStep({ stepName: 'eventKind', data: { type: chosenEvent } }))
            onNextPage();
        } else {
            setShowPopup(true);
        }
    }

    const onEventClick = (eventName) => {
        setChosenEvent(eventName);
    }

    const onGoBack = () => {
        history.replace(Routes.Common.home);
    }

    return (
        <>
            <EventsGrid>
                {
                    !isWindowForMobile ?
                        <ImageContainer bg={lightsImage} >
                            <ImageTextContainer>
                                <FireworksIcon fill={'white'} width={123} height={123} style={{ marginRight: '10px' }} />
                                <ImageText>
                                    WE ESTIMATE 40% COST REDUCTION WHEN USING OUR PLATFORM FOR ANY EVENT
                                </ImageText>
                            </ImageTextContainer>
                        </ImageContainer>
                        : null
                }
                <EventsContainer>
                    <FlexRowSpaced>
                        <BackButton src={backIcon} alt="Back" onClick={() => onGoBack()} />
                        <ProgressBar>
                            <ProgressFill fill={'20%'}></ProgressFill>
                        </ProgressBar>
                        {
                            chosenEvent === null ?
                                <NextButton disabled={true} src={backIcon} alt="Next" onClick={(e) => { nextStep(e) }} />
                                :
                                <NextContainer onClick={(e) => { nextStep(e) }} >
                                    <NextText>Next</NextText>
                                    <NextButtonOrange src={nextIconOrange} alt="Next" />
                                </NextContainer>

                        }
                    </FlexRowSpaced>
                    <PageHeader>What are we celebrating?</PageHeader>
                    <PageText>At this point, we are only offering weddings, keep an eye out for more events to come soon</PageText>
                    {
                        loading ? <Loader /> :
                            eventTypes ? <EventsBox>
                                {/* <EventName
                                            selected={chosenEvent}
                                            onClick={() => { onEventClick(eventTypes[0].type) }} >{eventTypes[0].name}</EventName>
                                
                                <EventName style={{color:'#EC5C57'}}>Coming soon...</EventName> */}
                                {                                
                                    newList.map((eventType, index) => {

                                    
                                        return <EventName
                                            title={eventType.type === 'coming_soon'}
                                            type={eventType.type}
                                            key={index}
                                            disabled={eventType.type !== 'wedding'}
                                            selected={eventType.type === chosenEvent}
                                            onClick={eventType.type !== 'wedding' ? () => { } : () => { onEventClick(eventType.type) }} >{eventType.name}</EventName>}
                                    )
                                }

                            </EventsBox>
                                : null
                    }

                </EventsContainer>
            </EventsGrid>
            {showPopup ? <GeneralPopup negativeButtonAction={() => { setShowPopup(false) }} >
                <PopupText>Please choose event type</PopupText>
                <PopupButton onClick={() => setShowPopup(false)}>Ok</PopupButton>
            </GeneralPopup>
                : null}
        </>
    );
}

export default EventKind;