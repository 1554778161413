import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { FlexColumn, FlexRowSpaced } from '../../../../common/components/CommonStyledComponent';
import HorizontalSlider from '../../../../common/components/HorizontalSlider';
import { markChecklist } from '../../../../store/reducers/user-event/user-event-reducer';
import {
    ChecklistTitle, ChecklistDescription, ChecklistButton, HorizontalProgressFill,
    HorizontalProgressLayout, HorizontalProgressContainer, HorizontalProgressCircle
} from '../EventRoomStyles';


const CheckItem = ({ eventId, description, task, checked, title, _id }) => {
    const dispatch = useDispatch();

    const markProgress = (progressId, checked) => {
        dispatch(markChecklist(eventId, progressId, checked))
    }

    return (
        <FlexColumn style={{ width: '100%' }}>
            <ChecklistTitle>{title}</ChecklistTitle>
            <ChecklistDescription>{description}</ChecklistDescription>
            <FlexRowSpaced>
                <ChecklistButton>{task}</ChecklistButton>
                {!checked ? <ChecklistButton onClick={() => markProgress(_id, true)}>Mark it done</ChecklistButton> :
                    <ChecklistButton onClick={() => markProgress(_id, false)}>Undone</ChecklistButton>}
            </FlexRowSpaced>
        </FlexColumn>
    )
}
const HorizontalProgress = ({ eventId, progress }) => {

    let numOfChecked = useMemo(() => {
        let count = 0;
        for (let i = 0; i < progress.length; i++) {
            if (progress[i].checked) {
                count++;
            }
        }
        return count;
    }, [progress]);

    let sortedProgress = useMemo(() => {
        let progressCopy = [...progress];
        progressCopy.sort((a, b) => b.checked - a.checked);
        return progressCopy;
    }, [progress]);

    return (
        <HorizontalProgressLayout>
            <HorizontalSlider
                data={sortedProgress.map((task, index) => {
                    return <CheckItem key={index} {...task} eventId={eventId} />
                })}
            />
            <HorizontalProgressContainer>
                <HorizontalProgressFill width={(numOfChecked / progress.length) * 100} />
                <HorizontalProgressCircle complete={(numOfChecked / progress.length) * 100} />
            </HorizontalProgressContainer>
        </HorizontalProgressLayout >

    )
}

export default HorizontalProgress;