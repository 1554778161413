import React, { useEffect } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';

import { ToastContainer } from '../common/components/toast/ToastComponents';
import { authCheckState } from '../store/reducers/auth/auth-reducer';
import MainNavigation from '../components/Navigation/MainNavigation';
import Routes from '../components/routes';
import Dashboard from '../components/Dashboard/Dashboard';
import Boarding from '../components/Boarding/Boarding';
import twilioClient from '../twilio-client';
import { connectTwilio } from '../store/reducers/conversations/conversations-reducer';
import ShareEvent from '../components/ShareEvent/ShareEvent';
import Loader from '../common/components/Loader';


const App = () => {
  const auth = useSelector(state => state.auth);
  console.log(auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.user) {
      dispatch(authCheckState());
    }
  }, [auth.authenticated]);

  useEffect(() => {
    if (auth.user) {
      twilioClient.initializeTwilio().then(() => {
        dispatch(connectTwilio());
      });
    }
  }, [auth.authenticated])

  let view = <Loader />

  if (auth.authenticated && !auth.loading && auth.user) {
    view = (
      <Switch>
        <Route path={Routes.Common.shareEvent} component={ShareEvent}></Route>
        <Route path={Routes.Boarding.boarding} component={Boarding} ></Route>
        <Route path={Routes.Common.dashboard} component={Dashboard}></Route>
        <Redirect from="/" to={Routes.Dashboard.eventRoom} />
      </Switch>
    );
  }
  else if (!auth.authenticated && !auth.loading) {
    view = (
      <MainNavigation />
    );
  }

  return (
    <ToastProvider components={{ ToastContainer: ToastContainer }}
      placement="top-center">
      {view}
    </ToastProvider>
  );
}

export default App;
