import styled from 'styled-components';

export const LoginGrid = styled.div`
    display: grid;
    font-family: 'Poppins';
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;
    grid-template-columns: 3fr 2fr;
    background: black;
    grid-template-areas: 'video form';

    @media (max-width: 768px) {
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr;
        grid-template-areas:
            'video'
            'form';
        overflow: initial;
    }
`;

export const VideoContainer = styled.div`
    position: relative;
    text-align: center;
    color: #fff;
    background: ${(props) => `url(${props.bg})`};
    background-size: cover;
    box-sizing: border-box;
    overflow: hidden;
    grid-area: video;
    width: 50%;

    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        margin-bottom: -12px;
        height: 200px;
        width: 100%;
    }
`;

export const MobileText = styled.div`
    display: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 65px;
    align-items: center;
    text-align: center;
    color: #ffffff;
    opacity: 0.9;

    @media (max-width: 768px) {
        display: flex;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
    position: relative;
    text-align: center;
    color: white;
    background-size: cover;
    background: white;
    grid-area: form;
    overflow: auto;

    @media (max-width: 768px) {
        padding: 0px;
        height: 100%;
        z-index: 100;
        border-radius: 16px 16px 0 0;
        background: #fff;
        overflow: inherit;
    }
`;

export const LoginHeader = styled.div`
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 48px;
    padding: 0px 0px 60px 0px;
    color: var(--black);

    @media (max-width: 768px) {
        font-size: 32px;
        padding: 20px 0px 40px 0px;
    }
`;

export const FormBox = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-family: 'Poppins';
    height: 100%;
    border-radius: 50px 50px 0px 0px;
    overflow: scroll;
    width: 80%;
    align-self: center;
    color: var(--black);
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    border-bottom: none;

    @media (max-width: 768px) {
        width: 80%;
    }
`;

export const LoginButtonsContainer = styled.div`
    position: relative;
    background: ${(props) => `url(${props.bg})`};
    background-size: cover;
    height: 50px;
    width: 275px;
    margin: 10px 0;
`;

export const StyledInput = styled.input`
    color: var(--black);
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 18px;
    padding: 15px 0;
    /* margin: 8px auto; */
    width: 275px;
    border: 0;
    background-color: #fff;
    border-bottom: 2px solid var(--neutral-50p);

    &:disabled {
        border: 1px solid var(--neutral-50p);
        color: var(--neutral-50p);
    }

    &.readOnly:disabled {
        color: var(--neutral-80p);
    }

    &:focus {
        outline: none;
        color: var(--neutral-90p);
    }

    &::-webkit-input-placeholder {
        /* Edge */
        font-family: 'Poppins';
        font-size: 18px;
        line-height: 18px;
        color: var(--neutral-40p);
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-family: 'Poppins';
        font-size: 18px;
        line-height: 18px;
        color: var(--neutral-40p);
    }

    &::placeholder {
        font-family: 'Poppins';
        font-size: 18px;
        line-height: 18px;
        color: var(--neutral-40p);
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 30px 0;
    align-self: center;
`;

export const ButtonContainer = styled.div`
    width: 170px;
    height: 56px;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '0p')};
    align-self: center;
`;

export const Line = styled.div`
    width: 30%;
    border: 1px solid #a89e9a;
`;

export const BoldText = styled.div`
    color: #a89e9a;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
`;

export const RegularText = styled.div`
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    font-weight: 500;
`;

export const LogLogo = styled.img`
    width: 134px;
    height: 131px;

    @media (max-width: 768px){
        width: 100px;
        height: 100px;
        margin-top: 20px;
    }
`;