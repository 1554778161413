import styled from 'styled-components';

export const WhereContainer = styled.div`
    display: grid;
    position: relative;
    padding: 35px 80px 0px 80px;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-rows: 1fr 9fr;
    grid-gap: 32px;
    grid-template-areas:  "text search next" "text locations locations";
    overflow-x: auto;
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0fr;
        grid-template-areas: "next"
                            "text"
                            "search"
                            "locations";
        padding: 40px 20px 0px 20px;
        grid-gap: 0px;
        overflow: auto;
    }
`;

export const WhereHeader = styled.div`
    text-align: center;
    grid-area: text;
`;

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    grid-area: search;

    @media (max-width: 768px) {
        padding: 20px 0px 40px 0px;
        font-size: 16px;
        font-weight: 400;
        justify-content: center;
    }
`;

export const SearchImg = styled.img`
    width: 28px;
    height: 28px;
    margin-right: 10px;
    margin-top: 5px;

    @media (max-width: 768px) {
        font-size: 24px;
        width: 24px;
        height: 24px;
        margin-right: 0px;
        align-self: center;
    }
`;

export const SearchInput = styled.input`
    color: var(--black);
    font-size: 34px;
    line-height: 34px;
    font-family: 'Poppins';
    font-weight: 500;
    padding-left: 16px;
    height: 34px;
    border: none;
    outline: none;

    &::placeholder {
        color: var(--black);
        opacity: 0.3;
    }

    @media (max-width: 768px) {
        font-size: 20px;
        width: 60%;
        height: auto;
    }
`;

export const NextButton = styled.img`
    justify-self: end;
    transform: rotate(180deg);
    grid-area: next;
    cursor: pointer;
    width: 20px;
    height: 18px;
    opacity: ${props => props.disabled ? 0.2 : 1};
`;

export const NextButtonOrange = styled.img`
    justify-self: end;
    grid-area: next;
    width: 20px;
    height: 18px;
`;

export const NextText = styled.div`
    font-family: Poppins;
    font-weight: 600;
    font-size: 25px;
    color: #EE706C;
    margin-right: 8px;

    @media(max-width: 768px){
        font-size: 18px;
    }
`;

export const NextContainer = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
`;

export const FireworksIcon = styled.img`
    position: absolute;
    width: 134px;
    height: 131px;
    bottom: 50px;
    left: 70px;

    @media (max-width: 768px){
        display: none;
    }
`;

export const LocationsContainer = styled.div`
    grid-area: locations;
    overflow: auto;
    min-width: 1000px;

    @media (max-width: 768px){
        min-width: auto;
    }
`;