import styled from 'styled-components';

export const PageTitle = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 43px;
    line-height: 95px;

    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 30px;
    }
`;

export const Text = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: var(--black);

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
    }
`;

export const TotalPriceText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 90px;
    text-align: center;
    color: var(--black);

    @media (max-width: 768px) {
        line-height: 57px;
    }
`;

export const TotalBudgetText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: var(--black);

    @media (max-width: 768px) {
        font-weight: bold;
        line-height: 20px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;

export const ProgressContainer = styled.div`
    height: 50%;
    width: 450px;
    min-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        min-width: 100%;
        width: 100%;
    }
`;

export const CircleSize = styled.div`
    height: 330px;
    width: 330px;
    padding-top: 20px;

    @media (max-width: 768px) {
        height: 250px;
        width: 250px;
    }
`;

export const ListContainer = styled.div`
    width: 100%;
    padding: 10px 50px 30px 0;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 768px) {
        padding: 0;
        overflow: auto;
    }
`;

export const BudgetLayout = styled.div`
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 60px 30px 30px 30px;
    height: 100%;

    @media (max-width: 768px) {
        padding: 10px 22px 30px 22px;
        margin-top: 60px;
    }
`;
