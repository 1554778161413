import styled from 'styled-components';

export const MonthsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: var(--dark);
    padding: 30px;
    width: 90%;
    overflow-y: auto;
    height: 100%;
    border: 1px solid #E5E5E5;
    border-radius: 16px 16px 0px 0px;
    align-self: center;

    @media (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 20px;
        margin-bottom: 20px;
    }
`;

export const EventDateText = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin: 30px 0px; 
    cursor: pointer;
    font-weight: 400;
    padding:  8px 14px;
    border-radius: 50px;
    color: ${props => props.selected ? 'var(--red)' : 'var(--black)'};
    border: ${props => props.selected ? '1px solid var(--light-red)' : '1px solid rgba(0,0,0,0)'};
    background: ${props => props.selected ? 'var(--pink)' : 'none'};
    width: fit-content;
    justify-self: center;

    &:hover{
        padding:  8px 14px;
        border-radius: 50px;
        background: var(--pink);
        box-shadow: 0px 2px 0px rgba(148, 148, 148, 0.25);
    }

    @media (max-width: 768px) {
        font-size: 18px;
        margin: 12px 0px;
    }
`;