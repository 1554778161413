import React from 'react'

import mainImage from '../../assets/images/main-image.png';
import mainLogoColor from '../../assets/images/main-logo-color.png';
import mainLogoWhite from '../../assets/icons/main-logo.svg';
import userLogo from '../../assets/images/user.png';
import partyLogo from '../../assets/images/party.png';
import phonesLogo from '../../assets/images/phones.png';
import horizontalLogo from '../../assets/images/logo-horizontal.png';
import instagramLogo from '../../assets/icons/instagram.svg';
import facebookLogo from '../../assets/icons/facebook.svg';
import tiktokLogo from '../../assets/icons/tiktok.svg';

import { useHistory } from 'react-router-dom';
import Routes from '../routes';
import {
    CardsContainer, ColoredDiv, CreateEventButton, EventText, FooterContainer,
    HomeContainer, HorizontalLine, HorizontalLogo, LoginButton, LoginContainer,
    MainDetails, MainImage, MainLogoColor, MainLogoWhite, MainText, SocialMediaIcon,
    SimpleText
} from './HomeStyles';
import HomePageCard from './components/HomePageCard';
import { FlexColumn, FlexRow } from '../../common/components/CommonStyledComponent';

const Home = () => {
    const history = useHistory();
    const isWindowForMobile = window.innerWidth <= 768;

    return (
        <HomeContainer>
            <MainImage bg={mainImage}>
                <ColoredDiv style={{ minHeight: isWindowForMobile ? '220px' : '240px' }} bgColor={'linear-gradient(90deg, rgba(219, 82, 67, 0.9), rgba(220, 85, 68, 0.9), rgba(222, 95, 70, 0.9), rgba(226, 112, 74, 0.9), rgba(231, 136, 80, 0.9), rgba(238, 167, 88, 0.9))'}>
                    <MainLogoWhite src={mainLogoWhite} atl="/" />
                    <LoginContainer>
                        <LoginButton onClick={() => { window.location.href = Routes.Common.eproLogin }}>Become a pro</LoginButton>
                        <LoginButton onClick={() => { history.push(Routes.Common.login) }}>Login</LoginButton>
                    </LoginContainer>
                </ColoredDiv>
                <ColoredDiv style={{ minHeight: isWindowForMobile ? '120px' : '240px' }} bgColor={'linear-gradient(90deg, rgba(129, 49, 89, 0.9), rgba(201, 140, 186, 0.9))'}>
                    <MainText>celebration starts here</MainText>
                </ColoredDiv>
                <ColoredDiv style={{ minHeight: isWindowForMobile ? '150px' : '240px' }} bgColor={'linear-gradient(90deg, rgba(21, 64, 91, 0.9), rgba(23, 67, 94, 0.9),rgba(29, 76, 104, 0.9), rgba(40, 92, 121, 0.9), rgba(55, 114, 145, 0.9), rgba(75, 142, 176, 0.9), rgba(99, 176, 213, 0.9), rgba(111, 194, 232, 0.9))'}>
                    <CreateEventButton onClick={() => { history.push(Routes.Boarding.boarding) }}>Create your event</CreateEventButton>
                </ColoredDiv>
            </MainImage>
            <MainDetails>
                <MainLogoColor src={mainLogoColor} alt="/" />
                <EventText>Eventro is here to help inspire you in creating
                    your vision & get all the necessary tools
                    to make your dream into reality</EventText>
                <CardsContainer>
                    <HomePageCard
                        image={userLogo}
                        bgColor={'rgba(92, 170, 174, 0.3)'}
                        text={'We take your vision and turn in into visual - when you see it everything will come into place. Share it with the world to hype up your upcoming event!'}
                    />
                    <HomePageCard
                        image={phonesLogo}
                        bgColor={'rgba(179, 21, 90, 0.3)'}
                        text={'What you see in the teaser is what you get - we have all the participating suppliers on board and they are waiting to start working with you'}
                    />
                    <HomePageCard
                        image={partyLogo}
                        bgColor={'rgba(255, 89, 0, 0.3)'}
                        text={'Our event room isn’t just a virtual folder with all your notes and lists - we progress with you and keep the process alive, fun & useful'}
                    />
                </CardsContainer>
                <HorizontalLine />
                <FooterContainer>
                    <HorizontalLogo src={horizontalLogo} alt="/" />
                    <FlexColumn>
                        <FlexRow style={{ marginBottom: '10px' }}>
                            <SocialMediaIcon src={instagramLogo} alt="/" />
                            <SocialMediaIcon style={{ margin: '0px 10px' }} src={facebookLogo} alt="/" />
                            <SocialMediaIcon src={tiktokLogo} alt="/" />
                        </FlexRow>
                        <SimpleText>Contact us: info@eventroclub.com</SimpleText>
                        <SimpleText>Privacy Policy | Terms of Service</SimpleText>
                    </FlexColumn>
                </FooterContainer>
            </MainDetails>
        </HomeContainer>)
}

export default Home;