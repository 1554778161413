const ServerRoutes = {
    userLogin: '/api/v1/auth/token',
    userLogout: '/api/v1/auth/logout',

    userInfo: '/api/v1/accounts/userInfo',
    userProfile: '/api/v1/accounts/profile',
    userAvatar: '/api/v1/accounts/profile/avatar',
    resetPassword: '/api/v1/accounts/changePassword',

    filestoragePresignedUrl: '/api/v1/filestorage/images/signUrl',
    filestorageDeleteImage: '/api/v1/filestorage/images/delete',

    locations: '/api/v1/cities?country=israel&groupby=region',
    eventTypes: '/api/v1/events/types',
    saveAnswers: '/api/v1/events/answers',
    getNextQuestion: '/api/v1/events/questions/next',
    prepareEvents: '/api/v1/events/prepare',
    pickEvent: '/api/v1/events/pick',
    saveEvent: '/api/v1/events/save',
    currentEvent: '/api/v1/events/current',
    updateProgress: '/api/v1/events/progress',

    getGuests: '/api/v1/events/:id/guests',
    getAttending: '/api/v1/events/:id/guests/attending',
    sendLink: '/api/v1/events/link',

    getBudget: '/api/v1/events/:id/budget',
    getTwilioToken: '/api/v1/chat/token',
    chatParticipants: '/api/v1/chat/participants',

    getTeam: '/api/v1/teams/:id',
    getMember: 'api/v1/teams/member/:id',
    deleteMember: 'api/v1/teams/:id/member/:proId',
    addMember: 'api/v1/teams/:id/member/:proId',
    getPros: 'api/v1/teams/pros',

    sharedEvent: '/api/v1/events/by',
    videoPolls: '/api/v1/events/video/status'


};

export default ServerRoutes;
