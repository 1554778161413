import React, { useEffect } from 'react';
import styled from 'styled-components';

import OutsideClick from '../hoc/OutsideClick';

const Backdrop = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 2000;
    background: ${props => props.backgroundColor ? props.backgroundColor : 'rgba(0,0,0,0.6)'};
    overflow: auto;
    html {
        overflow: none;
    }
    animation: bfade 300ms ease-in ;
    @keyframes bfade {
        from {
            opacity: 0.01;
        }
        to {
            opacity: 1;
        }
    }
`;

const PopupContainer = styled.div`
    position: absolute;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--neutral-90p);
    padding: 20px;
    width: ${props => props.width ? props.width : '476px'};
    max-width: 860px;
    box-sizing: border-box;
    border-radius: 16px;
    -webkit-box-shadow: 0px 2px 20px rgba(111, 110, 157, 0.25);
    -moz-box-shadow: 0px 2px 20px rgba(111, 110, 157, 0.25);
    box-shadow: 0px 2px 20px rgba(111, 110, 157, 0.25);
    animation: fade 300ms ease-in ;
    @keyframes fade {
        from {
            opacity: 0.5;
            top: 60%;
        }
        to {
            opacity: 1;
            top:50%;
        }
    }

    @media (max-width: 768px) {
        width: 90%;
    }
`;
const PageTitle = styled.div`
	font-family: InterUI-SemiBold;
	font-size: 20px;
	color: var(--neutral-80p);
	line-height: 42px;
`;

const PopupSection = styled.div`
	display: flex;
    flex-direction: column;
    padding: 20px 0px;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: none;
`;
const Footer = styled.div`
	margin: auto;
	width: 100%;
	display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    & > * {
        margin: 0 0.5rem;
    }
`;

const Modal = (props) => {
    // Disable scroll on modal present
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        }
    }, []);

    return (
        <Backdrop style={{ ...props.backdropStyle }}>
            <OutsideClick callback={props.negativeButtonAction}>
                <PopupContainer style={{ ...props.popupStyle }}>
                    <PopupSection style={props.bodyStyle}>
                        {props.body}
                    </PopupSection>
                </PopupContainer>
            </OutsideClick>
        </Backdrop >
    )
}

Modal.defaultProps = {
    positiveButtonDisabled: false,
    hasNegativeButton: true,
}

export default Modal;

export const PopupWindowLayout = {
    Backdrop,
    PopupContainer,
    PageTitle,
    PopupSection,
    RowContainer,
    ColumnContainer,
    Footer,
    ButtonsContainer
};
