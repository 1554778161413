import React, { useState } from "react";
import { useSelector } from "react-redux";

import { formatDate } from "../../../common/utils/date-utils";
import fireworkIcon from "../../../assets/icons/fireworks.svg";
import {
  FantasiesLayout,
  FantasiesVideo,
  FantasyDetails,
  FantasyText,
  FantasyTitle,
  FireworksImage,
  ShareBtn,
} from "./FantasiesStyles";
import {
  EventDescription,
  EventTitle,
  ShareIcon,
} from "../../Boarding/ChooseEvent/ChooseEventStyles";
import {
  FlexRow,
  Tooltip,
  WhiteButton,
} from "../../../common/components/CommonStyledComponent";
import { VideoContainer } from "../../Login/LoginStyles";
import ReactPlayer from "react-player";
import Loader from "../../../common/components/Loader";
import { getEventNameByType } from "../../../common/utils/utils";
import { Link } from "react-router-dom";

const Fantasies = () => {
  const { userEvent, loading } = useSelector((state) => state.userEvent);
  const [showTooltip, setShowTooltip] = useState(false);

  const isWindowForMobile = window.innerWidth <= 768;

  const onShareClick = () => {
    navigator.clipboard.writeText(
      window.origin.toString() + "/share-event?event=" + userEvent?.uuid
    );
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  return (
    <FantasiesLayout>
      {loading ? <Loader /> : null}
      {userEvent?.videoDetails?.videoUrl ? (
        <VideoContainer
          bg={userEvent?.event?.imageLink ? userEvent.event.imageLink : ""}
        >
          <ReactPlayer
            width={"100%"}
            height={"100%"}
            controls={true}
            style={{ background: "black" }}
            url={userEvent.videoDetails.videoUrl}
          />
        </VideoContainer>
      ) : (
        <FantasiesVideo src={userEvent.event.imageLink} alt="/" />
      )}
      <FantasyDetails bgColor={userEvent?.event.theme.backgroundColor}>
        <FireworksImage src={fireworkIcon} alt="/" />
        <EventTitle>{userEvent?.event.name.toUpperCase()}</EventTitle>
        <EventDescription>{userEvent?.event.description}</EventDescription>
        <FantasyTitle>Where</FantasyTitle>
        <FantasyText>{userEvent?.location?.name}</FantasyText>
        <FantasyTitle>What</FantasyTitle>
        <FantasyText style={{ textTransform: "capitalize" }}>
          {userEvent?.eventType
            ? getEventNameByType(userEvent.eventType)
            : "Party"}
        </FantasyText>
        <FantasyTitle>When</FantasyTitle>
        <FantasyText>{formatDate(userEvent.date)}</FantasyText>
        <FantasyTitle>Guests</FantasyTitle>
        <FantasyText>{userEvent.minNumberOfGuests}</FantasyText>
        <FlexRow
          style={{
            alignItems: "center",
            gap: isWindowForMobile ? 0 : 20,
            flexDirection: isWindowForMobile ? "column" : "row",
          }}
        >
          <ShareBtn onClick={onShareClick}>
            <ShareIcon
              style={{
                width: "18px",
                height: "18px",
                margin: "0px 10px 0px 0px",
                position: "initial",
              }}
              fill={"white"}
              alt="share"
            />
            <div>Share</div>
            <Tooltip show={showTooltip}>Link Copied!</Tooltip>
          </ShareBtn>

          <Link style={{ textDecoration: "none" }} to="/dashboard/event-room">
            {" "}
            <WhiteButton style={{ marginTop: "20px" }}>
              Go to Eventro{" "}
            </WhiteButton>
          </Link>
        </FlexRow>
      </FantasyDetails>
    </FantasiesLayout>
  );
};

export default Fantasies;
