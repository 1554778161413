import { string, object, array, number } from 'yup';
import { metadataSchema } from './common-schemas';

const memberSchema = object().shape({
    _id: string(),
    fullName: string(),
    phone: string(),
    occupation: string(),
    occupationTitle: string(),
    priceRange: number(),
    experience: number(),
    location: object().shape({
        region: string(),
        country: string(),
    }),
    avatar: string().nullable(true),
    title: string(),
    description: string(),
    facebookLink: string().nullable(true),
    tiktokLink: string().nullable(true),
    instagramLink: string().nullable(true),
    twitterLink: string().nullable(true),
    websiteLink: string().nullable(true),
    displayEmail: string(),
    address: string(),
    businessId: string().nullable(true),
    media: array().of(string()).default([]),
});

export const teamSchema = object().shape({
    data: array().of(memberSchema).default([]),
    metadata: metadataSchema,
});

export const proSchema = object().shape({
    _id: string(),
    fullName: string(), //?
    phone: string().nullable(true),
    occupation: string(),
    occupationTitle: string(),
    priceRange: number(),
    experience: number(),
    avatar: string().nullable(true),
    businessName: string().nullable(true),
    bio: string().nullable(true), //?
    facebook: string().nullable(true),
    tiktok: string().nullable(true),
    instagram: string().nullable(true),
    twitter: string().nullable(true),
    office: string().nullable(true),
    website: string().nullable(true),
    displayEmail: string(),
    address: string(),
    zip: string().nullable(true),
    language: string().nullable(true),
    businessId: string().nullable(true),
    currency: string(),
    images: array().of(string()).default([]),
    videos: array().of(string()).default([]),
    quota: array().of(string()).default([]),
    skechtes: array().of(string()).default([]),
    documents: array().of(string()).default([]),
});

export const prosSchema = object().shape({
    data: array()
        .of(
            object().shape({
                _id: string(),
                occupation: string(),
                occupationTitle: string(),
                avatar: string().nullable(true),
                fullName: string(),
            })
        )
        .default([]),
    metadata: metadataSchema,
});
