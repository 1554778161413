import React, { useRef, useEffect, useCallback } from 'react';

const OutsideClick = (props) => {
    const wrappedRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {

            document.removeEventListener("click", handleClickOutside);

        };
    }, []);

    const handleClickOutside = useCallback((event) => {
        if (wrappedRef.current && !wrappedRef.current.contains(event.target)) {
            props.callback();
        }
    }, [props.callback])

    return (
        <div ref={wrappedRef}>{props.children}</div>
    );
}

export default OutsideClick;