import React, { useMemo, useState } from 'react'
import { FlexRow} from '../../../common/components/CommonStyledComponent';
import styled from 'styled-components';
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const Circle = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    background: ${props => props.isSelected ? '#FFFFFF' : 'transparent'};
    cursor: pointer;
    margin: 6px 0px;

    @media (max-width: 768px) {
        width: 12px;
        height: 12px;
    }
`;

const EventSwitch = ({ numOfEvents, onEventSwitch }) => {
    const { height, width } = useWindowDimensions();

    const [selectedCircle, setSelectedCircle] = useState(0);

    const onCircleClick = (i) => {
        setSelectedCircle(i);
        onEventSwitch(i);
    }

    let circleArray = [];

    circleArray = useMemo(() => {
        let circleElements = [];
        for (let i = 0; i < numOfEvents; i++) {
            circleElements.push(<Circle key={i} onClick={() => onCircleClick(i)} isSelected={selectedCircle === i}></Circle>)
        }
        return circleElements;
    }, [numOfEvents, selectedCircle]);

    return (<FlexRow style={ { position: 'absolute',bottom :width>768?'-150%':'-30px', left:width>768?'300px':'50%',gap:15}}>
        {circleArray}
    </FlexRow>);
}

export default React.memo(EventSwitch);