import styled from 'styled-components';

export const FantasiesLayout = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    @media(max-width: 768px){
        flex-direction: column;
        position: fixed;
    }
`;

export const FantasiesVideo = styled.img`
    width: 50%;
    height: 100%;

    @media (max-width: 768px) {
        width: 100%;
        height: 40%;
    }
`;

export const FantasyDetails = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    height: 100%;
    padding: 40px;
    overflow: auto;
    background: ${props => props.bgColor ? props.bgColor : '#FFFFFF'};
    box-sizing: border-box;

    @media (max-width: 768px) {
        align-items: center;
        width: 100%;
        margin-top: -1px;
        overflow: auto;
    }
`;

export const FireworksImage = styled.img`
    width: 127px;
    height: 127px;

    @media (max-width: 768px) {
        width: 81px;
        height: 81px;
    }
`;

export const FantasyTitle = styled.div`
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 21px;
    line-height: 34px;
    color: white;

    @media (max-width: 768px){
        font-size: 18px;
    }
`;

export const FantasyText = styled.div`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 21px;
    line-height: 34px;
    color: white;
    margin-bottom: 12px;

    @media (max-width: 768px){
        font-size: 18px;
    }
`;

export const ShareBtn = styled.button`
    background: none; 
    border: 1px solid #FFFFFF;
    border-radius: 35.5px;
    color: white;
    height: 72px;
    width: 200px;
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: black;
        color: white;
    }

    /* &:active {
        background: white;
        color: black;
    } */

    @media (max-width: 768px) {
        height: 64px;
    }
`;