import { string, object, array, number } from 'yup';
import { metadataSchema } from './common-schemas';

const guestSchema = object().shape({
    _id: string(),
    note: string().nullable(true),
    attending: string(),
    fullName: string(),
    email: string(),
    phone: string(),
    user: string(),
    userEvent: string(),
    createdAt: string(),
    updatedAt: string(),
});

export const guestsSchema = object().shape({
    data: array().of(guestSchema).default([]),
    metadata: metadataSchema,
});

export const attendingSchema = object().shape({
    total: number(),
    attending: number(),
});
