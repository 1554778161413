import styled from 'styled-components';

export const EventsGrid = styled.div`
    display: grid;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    grid-template-columns: 3fr 2fr;
    grid-template-areas: "image events";
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "events";
        padding: 40px 20px 0px 20px;
        box-sizing: border-box;
        display: block;
    }
`;

export const ImageContainer = styled.div`
    position: relative;
    color: white;
    background: ${props => `url(${props.bg})`};
    background-size: cover;
    grid-area: image;
`;

export const EventsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 35px 40px 0px 40px;

    overflow: hidden;
    text-align: center;
    color: white;
    box-sizing: border-box;
    height: 100vh;
    background-size: cover;
    grid-area: events;
    overflow-y: auto;
    
    @media (max-width: 768px) {
        padding: 0px;
    }
`;

export const ImageTextContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-family: 'Poppins';
    pointer-events: none;
    font-weight: 700;
    user-select: none;
    bottom: 40px;
    left: 40px;
`;

export const ImageText = styled.div`
    font-family: 'HeroicCondensed';
    font-weight: 400;
    font-size: 39px;
    font-style: italic;
    line-height: 39px;
    white-space: pre-line;
    max-width: 70%;
`;

export const PageHeader = styled.div`
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 34px;
    line-height: 51px;
    padding: 10px 60px;
    color: var(--black);
    pointer-events: none;
    line-break: auto;
    margin-top: 30px;

    @media (max-width: 768px) {
        font-size: 32px;
        padding: 20px;
        margin-top: 0px;
    }
`;

export const PageText = styled.div`
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 21px;
    line-height: 34px;
    padding: 0px 60px 40px 60px;
    color: var(--black);
    line-break: auto;

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 22px;
        padding: 20px 30px 20px 30px;
    }
`;

export const EventsBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 20px 12vh 20px;
    border: 1px solid rgba(0,0,0,0.1);
    font-family: 'Poppins';
    height:100%;
    border-radius: 20px 20px 0px 0px;
    width: 80%;
    align-self: center;
    color: var(--black);
    font-weight: 400;
    font-size: 25px;
    line-height: 16px;
    border-bottom: none;

    @media (max-width: 768px) {
        font-size: 18px;
        overflow-y: auto;
        
    }
`;

export const EventName = styled.div`
    margin: auto;
    cursor: ${props=> props.title? 'default': 'pointer'};
    font-weight: 500;
    padding: 8px 14px;
    border-radius: 50px;
    opacity: ${props => props.disabled ? 0.3 : 1};
    opacity: ${props => props.type==='coming_soon' ? 1 :'opacity' };
    color: ${props => props.selected ? 'var(--red)' : 'var(--black)'};
    color: ${props => props.title ? '#EC5C57' : 'color'};
    border: ${props => props.selected ? '1px solid var(--light-red)' : '1px solid rgba(0,0,0,0)'};
    background: ${props => props.selected ? 'var(--pink)' : 'none'};
    width: fit-content;

    &:hover{
        padding: 8px 14px;
        border-radius:${props=> props.title? 'none':  '50px'};
        background: ${props=> props.title? 'none':'var(--pink)'};
        box-shadow: ${props=> props.title? 'none': '0px 2px 0px rgba(148, 148, 148, 0.25)'};
    }

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;