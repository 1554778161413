import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PageHeader = styled.div`
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 34px;
    line-height: 51px;
    color: var(--black);

    @media (max-width: 768px) {
        font-size: 32px;
        padding: 20px 20px;
    }
`;

export const Text = styled.div`
    font-weight: 500;
    font-size: 21px;
    line-height: 34px;
    margin-top: 19px;
    color: var(--black);

    @media (max-width: 768px) {
        margin-top: 0px;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
        padding: 16px 30px;
    }
`;

export const ImageText = styled.div`
    font-family: 'HeroicCondensed';
    font-weight: 600;
    position: absolute;
    text-align: left;
    max-width: 280px;
    font-size: 60px;
    top: 20px;
    left: 20px;
    font-style: italic;
    line-height: 60px;
    white-space: pre-line;
    text-transform: uppercase;

    @media (max-width: 768px){
        display: none;
    }
`;

export const PopupText = styled.div`
    font-weight: 500;
    font-size: 21px;
    line-height: 34px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const PopupButton = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 169px;
    height: 56px;
    color: var(--black);
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    border: 1px solid #EC5C57;
    box-shadow: 0px 2px 0px rgba(148, 148, 148, 0.25);
    border-radius: 32px;
    margin-top: 40px;

    &:hover {
        background: #EC5C57;
        color: white;
    }

    @media (max-width: 768px) {
        width: 140px;
        height: 46px;
    }
`;

export const SimpleText = styled.div`
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 21px;
    line-height: 34px;
    color: var(--dark);
    margin-top: 20px;
    margin-bottom: 20px;

    @media (max-width: 768px){
        font-size: 16px;
    }
`;

export const WhiteButton = styled.button`
    background: #ffffff;
    box-shadow: 0px 2px 0px rgba(148, 148, 148, 0.25);
    border-radius: 35.5px;
    color: #363636;
    height: 72px;
    width: 200px;
    font-weight: 700;
    font-size: 18px;
    border: none;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: black;
        color: white;
    }

    &:active {
        background: #ec5c57;
    }

    @media (max-width: 768px) {
        height: 64px;
    }
`;

export const WhiteOrangeButton = styled.button`
    background: #FFF4F3;
    box-shadow: 0px 2px 0px rgba(148, 148, 148, 0.25);
    border-radius: 35.5px;
    color: #363636;
    height: 72px;
    width: 200px;
    font-weight: 700;
    font-size: 18px;
    border: 2px solid #EC5C57;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #EC5C57;
        color: white;
    }

    &:active {
        background: #ec5c57;
    }

    @media (max-width: 768px) {
        height: 64px;
    }
`;

export const googleButtonStyles = {
    color: 'rgba(0,0,0,0.54)',
    width: '275px',
    fontFamily: 'Poppins',
    backgroundColor: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
    borderRadius: '10px',
    overflow: 'hidden',
    marginBottom: '20px',
};

export const Tooltip = styled.div`
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  font-weight: bold;
  font-size: 18px;
  width: 120px;
  background-color: var(--neutral-70p);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  top: -34px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;

  &:after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--neutral-70p) transparent transparent transparent;        
  }
`;



// TODO - remove unwanted styles
/* ------------------------------ */

export const StyledInput = styled.input`
    color: ${(props) => props.color || 'var(--neutral-90p)'};
    font-size: 16px;
    padding: 0px 20px;
    height: 44px;
    margin: 8px auto;
    width: calc(100% - 42px);
    border-style: groove;
    border: 1px solid var(--neutral-30p);
    &:disabled {
        border: 1px solid var(--neutral-20p);
        color: var(--neutral-50p);
    }

    &.readOnly:disabled {
        color: var(--neutral-80p);
    }

    &:focus {
        outline: none;
        color: var(--neutral-90p);
    }

    &::-webkit-input-placeholder {
        /* Edge */
        font-family: InterUI-Regular;
        font-size: 16px;
        line-height: 16px;
        color: var(--neutral-50p);
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-family: InterUI-Regular;
        font-size: 16px;
        line-height: 16px;
        color: var(--neutral-50p);
    }

    &::placeholder {
        font-family: InterUI-Regular;
        font-size: 16px;
        line-height: 16px;
        color: var(--neutral-50p);
    }
`;

export const Label = styled.label`
    display: block;
    font-size: 16px;
    align-self: flex-start;
    font-weight: bold;
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 44px;
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;

`;

 export const FlexRowWhere = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
        position: absolute;
        gap:'0.5rem'; 
        bottom: '-0.75rem'; 
        right: '10%' ;
    
    }
    //change to second screen (where)
`;

export const FlexRowAligned = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FlexRowSpaced = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        position: absolute;
        background: white;
        width:500px;
        max-width:90%;
        top:30px;
    
    }
 
    
`;

export const FlexRowCentered = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const FlexRowWrapped = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexColumnCentered = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ErrorMessage = styled.div`
    background: #f2dede;
    border: 1px solid #ebcccc;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    & > .message {
        font-family: InterUI-Regular;
        font-size: 16px;
        color: #ab352d;
        letter-spacing: 0;
        text-align: left;
        line-height: 25px;
    }
`;

export const StyledLink = styled(Link)`
    font-family: InterUI-Medium;
    font-size: 16px;
    color: #0061a8;
    text-decoration: none;
`;

export const BackButton = styled.img`
    cursor: pointer;
    width: 20px;
    height: 18px;
`;

export const ChatBackButton = styled(BackButton)`
    display: none;
    @media(max-width: 768px){
        position: fixed;
        top: 20px; 
        right: 20px;
        display: flex;
    }
`;

export const BurgerButton = styled.img`
    padding: ${(props) => (props.clicked ? '0px' : '4px')};
    width: ${(props) => (props.clicked ? '32px' : '24px')};
    height: ${(props) => (props.clicked ? '30px' : '14px')};
    margin-left: 20px;
`;

export const ProgressBar = styled.div`
    width: 300px;
    display: flex;
    flex-direction: row;
    background-color: rgb(242, 146, 67, 0.1);
    border-radius: 50px;
    height: 5px;
    align-self: center;

    @media (max-width: 768px){
        width: 210px;
    }
`;

export const ProgressFill = styled.div`
    width: ${(props) => (props.fill ? `${props.fill}` : '0px')};
    background: #ec5c57;
    border-radius: 50px;
`;

export const PrimaryButton = styled.button`
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid var(--red);
    box-sizing: border-box;
    /* button-default-shadow */
    box-shadow: 0px 2px 0px rgba(148, 148, 148, 0.25);
    border-radius: 32px;
    height: 100%;
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #61423e;

    &:hover {
        cursor: pointer;
        color: #000000;
        background: #fff4f3;
    }

    &:focus {
        cursor: pointer;
        /* background: var(--red); */
        border: 1px solid var(--red);
    }

    &:active {
       background: var(--red);
       color: #ffffff;
    }

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

export const RoundProfile = styled.img`
    width: ${(props) => (props.width ? props.width : '84px')};
    height: ${(props) => (props.height ? props.height : '84px')};
    border-radius: 100%;
    margin: 4px 8px;

    @media (max-width: 768px) {
        width: ${(props) => (props.width ? props.width : '45px')};
        height: ${(props) => (props.height ? props.height : '45px')};
    }
`;
