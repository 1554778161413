import { string, object, array, boolean, number } from 'yup';
// import { metadataSchema } from './common-schemas';

const objectSchema = object().shape({
    _id: string(),
    total: number(),
    isPricePerHead: boolean(),
    price: number(),
    currency: string(),
    fullName: string(),
    occupation: string(),
    occupationTitle: string(),
});

export const budgetSchema = object().shape({
    list: array().of(objectSchema).default([]),
    totalPrice: number(),
});
