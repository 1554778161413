import React from 'react';

import { NameStyle, NoteStyle, GuestContainer, TabContainer, Circle, ColorNameWrapper, EmailStyle } from './GuestsStyles';

const GuestTab = ({ guest }) => {
    return (
        <TabContainer>
            <GuestContainer>
                <ColorNameWrapper>
                    {guest.attending === 'yes' ? <Circle color={'var(--green)'} /> : guest.attending === 'pending' ? <Circle color={'var(--gray)'} /> : <Circle color={'var(--red)'} />}
                    <NameStyle>{guest.fullName}</NameStyle>
                </ColorNameWrapper>
                <EmailStyle>{guest.email}</EmailStyle>
                <NoteStyle>{guest.phone}</NoteStyle>
                <NoteStyle>{guest.note}</NoteStyle>
            </GuestContainer>
        </TabContainer>
    );
};

export default GuestTab;
