import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FlexColumn,
  FlexRow,
  Tooltip,
  WhiteButton,
} from "../../../common/components/CommonStyledComponent";
import LoaderGradient from "../../../common/components/LoaderGradient";
import {
  loadEvents,
  cleanEvents,
  startVideoPolling,
} from "../../../store/reducers/events/events-reducer";
import {
  MiddleText,
  FlexLayout,
  EventImage,
  FireworksIcon,
  EventTitle,
  EventContainer,
  EventDescription,
  ShareIcon,
  EventVideo,
} from "./ChooseEventStyles";
import EventSwitch from "./EventSwitch";
import fireworkIcon from "../../../assets/icons/fireworks.svg";
import {
  pickEvent,
  completeStep,
  cleanCards,
} from "../../../store/reducers/steps/steps-reducer";
import { useCheckStep } from "../../../hooks/useCheckStep";
import ReactPlayer from "react-player";
import { ShareBtn } from "../../Dashboard/Fantasies/FantasiesStyles";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const loadingTexts = [
  "Plugging in the DJ booth...",
  "Stirring your cocktails...",
  "Trimming the flowers...",
  "Contacting your A team...",
  "Granting wishes...",
  "Making some popcorn...",
  "Generating witty dialog...",
  "Spinning the wheel of fortune...",
  "Dividing by zero...",
  "Winter is coming...",
  "Prepare to be amazed...",
];

const getSeason = (date) => {
  const d = new Date(date);
  const month = d.getMonth();
  let season = "";
  switch (month) {
    case 12:
    case 1:
    case 2:
      season = "Winter";
      break;
    case 3:
    case 4:
    case 5:
      season = "Spring";
      break;
    case 6:
    case 7:
    case 8:
      season = "Summer";
      break;
    case 9:
    case 10:
    case 11:
      season = "Fall";
      break;
    default:
      season = "Amazing";
      break;
  }
  return season;
};

const ChooseEvent = () => {
  const { height, width } = useWindowDimensions();

  const dispatch = useDispatch();
  const { eventPick, guests, date } = useSelector((state) => state.steps);
  const onNextPage = useCheckStep(eventPick);

  const { events, showLoader } = useSelector((state) => state.events);
  const [selectedEventIndex, setSelectedEventIndex] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [poppingTexts, setPoppingTexts] = useState([
    "Lighting up your venue...",
  ]);

  const onShareClick = () => {
    navigator.clipboard.writeText(
      events[selectedEventIndex].videoDetails.videoUrl
    );
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  console.log("EVENTS", events);

  useEffect(() => {
    if (guests.minNumberOfGuest) {
      dispatch(loadEvents()).then((response) => {
        if (!response.error) {
          dispatch(startVideoPolling());
        }
      });
    }

    return () => {
      dispatch(cleanEvents());
      dispatch(cleanCards());
    };
  }, []);

  useEffect(() => {
    let textIndex = 0;
    let intervalId = setInterval(() => {
      setPoppingTexts((prevState) => [...prevState, loadingTexts[textIndex]]);
      textIndex++;
      if (textIndex === loadingTexts.length) {
        clearInterval(intervalId);
      }
    }, 5000);
  }, []);

  const switchEvent = (eventNum) => {
    setSelectedEventIndex(eventNum);
  };

  const onChooseEvent = () => {
    dispatch(
      completeStep({
        stepName: "eventPick",
        data: { event: events[selectedEventIndex] },
      })
    );
    dispatch(pickEvent()).then((response) => {
      if (response.payload.ok === 1) {
        onNextPage();
      }
    });
  };

  return (
    <>
      {showLoader ? (
        <LoaderGradient>
          <MiddleText>
            {poppingTexts.slice(-5).map((text, index) => {
              return <p key={index}>{text}</p>;
            })}
          </MiddleText>
        </LoaderGradient>
      ) : (
        <FlexRow style={{ width: "100%", height: "100%", background: "white" }}>
          <FlexLayout
            style={{ width: "100%", height: "100%", boxSizing: "border-box" }}
          >
            {events[selectedEventIndex]?.videoDetails?.videoUrl ? (
              <EventVideo>
                <ReactPlayer
                  width={"100%"}
                  height={"100%"}
                  controls={true}
                  style={{ background: "black" }}
                  url={events[selectedEventIndex].videoDetails.videoUrl}
                />
              </EventVideo>
            ) : events[selectedEventIndex]?.imageLink ? (
              <EventImage src={events[selectedEventIndex]?.imageLink} alt="/" />
            ) : null}
            {events[selectedEventIndex] ? (
              <EventContainer
                bgColor={events[selectedEventIndex]?.theme.backgroundColor}
              >
                <EventTitle>
                  {events[selectedEventIndex]?.name.toUpperCase()}
                </EventTitle>
                <EventDescription>
                  {events[selectedEventIndex]?.description}
                </EventDescription>
                {events[selectedEventIndex]?.videoDetails?.videoUrl ? (
                  <EventDescription style={{ fontWeight: "500" }}>
                    Check out this amazing video to your left for your{" "}
                    {getSeason(date.date)} Wedding in{" "}
                    {events[selectedEventIndex].location[0].name} for{" "}
                    {guests.minNumberOfGuest} people
                  </EventDescription>
                ) : (
                  <EventDescription style={{ fontWeight: "500" }}>
                    {getSeason(date.date)} Wedding in{" "}
                    {events[selectedEventIndex].location[0].name} for{" "}
                    {guests.minNumberOfGuest} people
                  </EventDescription>
                )}
                <FlexRow
                  style={{
                    gap: "1rem",
                    alignItems: "center",
                    position: "relative",
                    width: "100%",
                    flexWrap:'wrap'
                  }}
                >
                  <WhiteButton
                    style={{
                      minWidth: '150px',
                    }}
                    onClick={() => {
                      onChooseEvent();
                    }}
                  >
                    Make it real
                  </WhiteButton>
                  {events[selectedEventIndex]?.videoDetails?.videoUrl ? (
                    <ShareBtn
                      style={{ marginTop: "0px",minWidth: '150px' }}
                      onClick={onShareClick}
                    >
                      <ShareIcon
                        onClick={onShareClick}
                        style={{
                          width: "18px",
                          height: "18px",
                          margin: "0px 10px 0px 0px",
                          position: "initial",
                        }}
                        fill={"white"}
                        alt="share"
                      />
                      <div>Share</div>
                      <Tooltip show={showTooltip}>Link Copied!</Tooltip>
                    </ShareBtn>
                  ) : null}
                  <EventSwitch
                    numOfEvents={events.length}
                    onEventSwitch={switchEvent}
                  />
                </FlexRow>
              </EventContainer>
            ) : null}
          </FlexLayout>
          <FireworksIcon src={fireworkIcon} alt="/" />
        </FlexRow>
      )}
    </>
  );
};

export default ChooseEvent;
